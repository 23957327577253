/* pulldown wrapper */
.pulldown
{
  display: inline-block;
}

/* select */
.ui-selectmenu-button
{
  position: relative;

  display: inline-flex;
  align-items: center;

  cursor: pointer;

  padding-right: 20px;
}

.ui-selectmenu-button.ui-selectmenu-button
{
  width: auto;
  /* This was width: auto !important; for .ui-selectmenu-button
     but I wanted to get rid of the !important; */
}

.ui-selectmenu-button.ui-state-disabled
{
  cursor: default;
}
.ui-selectmenu-button .ui-icon
{
  order: 2;
  margin-left: 15px;

  display: inline-block;

  color: #ddd;
  transition: color 0.3s;
}

.ui-selectmenu-button .ui-icon::after
{
  margin-top: 3px;

  /* Font Awesome 5 regular */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;

  font-size: 22px;
  color: #AAAAAA;


  content: "\f107";
}
.ui-selectmenu-button.ui-state-hover .ui-icon:after
{
  color: #222;
}
.ui-selectmenu-button.ui-state-focus .ui-icon:after
{
  content: "\f106";
  color: #222;
}

/* MARK ***/
.ui-selectmenu-button .ui-selectmenu-text
{
  order: 1;

  line-height: 20px;

  /* keep the text on a single line and apply ellipsis when needed */
  display: inline-block;
  /*max-width: calc(100% - 26px);*/
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
/***/


.ut-pulldown__items
{
outline: 2px solid #0F0;

}


.ui-selectmenu-menu
{
  display: none;
  position: relative;
  max-width: 340px;

  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

}
.ui-selectmenu-open
{
  display: block;
}
.ui-selectmenu-menu ul
{
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
  background-color: #fff;
  min-width: 165px;
  border: 1px solid #ddd;
  border-radius: 2px;


  font: 16px/24px Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}
.ui-selectmenu-menu ul > li
{
  cursor: pointer;
  color: #5a5e62;
}
.ui-selectmenu-menu ul > li div
{
  padding: 3px 10px;
}

/*
.ui-selectmenu-menu ul > li:hover
{

}
*/
  .ui-selectmenu-menu ul > li.ui-state-hover /* not working anymore? */
, .ui-selectmenu-menu ul > li.ui-state-focus /* not working anymore? */
, .ui-selectmenu-menu ul > li .ui-state-active
, .ui-selectmenu-menu ul > li:hover
{
  background-color: #f0f1f2;
  color: #222;
}
