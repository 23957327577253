/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/*

.ks-twocolumn
>= 750 - Two columns
<  750 - Single column

.ks-twocolumn .wh-toggleexpand--alwaysvisibleintwocolumn
.ks-twocolumn .onlyshow-in-singlecolumn-mode
.ks-twocolumn .onlyshow-in-twocolumn-mode


>= 940 -
<  940 -



.ks--imageheader
Header with only a single image (for the )


.kamersite--needmorewhite
Will visually extend the white background beyond the page content area
(only relevant to >940px)


Lazy loading background-image
<div class="b-lazy" data-src="" />

Lazy loading image
<img class="b-lazy" data-src"" />

*/
/*
html.kamersite #site-mainmenu-mobile
{
  background-color: transparent; / * rgba(255, 255, 255, 0.2); * /
  padding: 0;
  height: 50px;
}
html.kamersite #site-mainmenu-mobile .iconbutton
{
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.55);
}
html.kamersite #site-mainmenu-mobile .iconbutton > .fa
{
  color: #FFFFFF;
}
*/
html.kamersite #site-headers {
  box-shadow: none;
}

.ks--imageheader {
  position: relative;
  background-size: cover;
}

.ksh--header--coloroverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.kamersite--maincontent {
  /* so our content can inherit our height */
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

/* Stretch content (for search and room pages) so the right (white) column
   visually fills the content/viewport space
*/
main.kamersite--fullheightdocument {
  /* so our content can inherit our height */
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

@media only screen and (max-width: 940px -1) {
  .kamersite--needmorewhite {
    display: none;
  }
}
/* in two column mode fill up the space at and beyond the second column with white */
@media only screen and (min-width: 940px) {
  .kamersite--needmorewhite {
    position: absolute;
    left: calc((100% - 940px) / 2 + 660px);
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #FFFFFF;
    z-index: -1;
  }
}
@media only screen and (min-width: 750px) {
  html.kamersite--home .ks--imageheader {
    height: 688px;
  }
  html.kamersite--searchpage .ks--imageheader {
    height: 233px;
  }
  html.kamersite--searchpage .ksh--header--logo {
    position: absolute;
    right: 0px;
    top: 20px;
    height: 24px;
  }
  html.kamersite--searchpage .ksh--header--counter {
    position: absolute;
    left: 0;
    bottom: 20px;
  }
}
/* MOBILE */
@media only screen and (max-width: 749px) {
  html.kamersite--home .ks--imageheader {
    height: auto; /* stretch to fit content */
  }
  html.kamersite--searchpage .ks--imageheader {
    height: 160px;
  }
  html.kamersite--searchpage .ksh--header--logo {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 24px;
  }
  html.kamersite--searchpage .ksh--header--counter {
    position: absolute;
    left: 10px;
    bottom: 10px;
  }
}
/*
KamerSite Two column layout
*/
.ks-twocolumn {
  color: #999999;
}

.ks__column2 a:not(.ctabutton) {
  color: #999999;
  text-decoration: underline;
}

.ks__column1 {
  background-color: transparent;
}

.ks__column2 {
  background-color: #FFFFFF;
}

/* MOBILE */
@media only screen and (min-width: 940px) {
  .ks-twocolumn {
    display: flex;
    flex: 1 0 auto;
  }
  .ks__column1 {
    width: 660px;
  }
  .ks__column1.pad_h.pad_h,
  .ks__column1 .pad_h.pad_h {
    padding-right: 20px;
    padding-left: 0;
  }
  .ks__column1.pad_v.pad_v,
  .ks__column1 .pad_v.pad_v {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .ks__column2 {
    width: 280px;
  }
  .ks__column2.pad_h.pad_h,
  .ks__column2 .pad_h.pad_h {
    padding-left: 40px;
    padding-right: 40px;
  }
  .ks__column2.pad_v.pad_v,
  .ks__column2 .pad_v.pad_v {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .onlyshow-in-singlecolumn-mode {
    display: none !important;
  }
  .ks-twocolumn .wh-toggledelement--open:not(.wh-toggleexpand--alwaysvisibleintwocolumn) {
    overflow: visible;
    height: auto;
  }
  .ks-twocolumn .wh-toggledelement--closed:not(.wh-toggleexpand--alwaysvisibleintwocolumn) {
    overflow: hidden !important;
    height: 0 !important;
    padding: 0 !important;
  }
}
/*
Two column layout downgraded to SINGLE COLUMN
*/
@media only screen and (max-width: 939px) {
  /* Keep flex in mobile for ordering */
  .ks-twocolumn {
    display: flex;
    flex-direction: column;
  }
  .ks__column1 .pad_h {
    padding-left: 20px;
    padding-right: 20px;
    /* padding: 40px 20px; */
  }
  .ks__column2 .pad_h {
    padding-left: 20px;
    padding-right: 20px;
    /* padding: 40px 20px; */
  }
  .ks__columns2 {
    margin-top: 40px;
  }
  /*
  .onlyshow-in-singlecolumn-mode
  {
    display: block;
  }
  */
  .onlyshow-in-twocolumn-mode {
    display: none !important;
  }
  .ks-twocolumn .wh-toggleexpand--alwaysvisibleinsinglecolumn {
    display: block;
    height: auto;
    overflow: visible;
  }
  .ks-twocolumn .wh-toggledelement--open:not(.wh-toggleexpand--alwaysvisibleinsinglecolumn) {
    overflow: visible;
    height: auto;
  }
  .ks-twocolumn .wh-toggledelement--closed:not(.wh-toggleexpand--alwaysvisibleinsinglecolumn) {
    overflow: hidden !important;
    height: 0 !important;
    padding: 0 !important;
  }
}