.associationsbacktocategoriesbar {
  font: 500 17px/60px "Univers Next W02";
  color: #9B9B9B;
  text-align: center;
  text-transform: uppercase;
}

.associationsbacktocategoriesbar a {
  color: inherit;
  text-decoration: none;
}

.associationsbacktocategoriesbar a:hover {
  text-decoration: underline;
}

/*
.associationsbacktocategoriesbar > .site-centered-top
{
  display: flex;
  height: 60px;

  align-items: center;
  justify-content: center;
}
*/
.associationsbacktocategoriesbar .fa {
  margin-right: 10px;
  font-size: 24px;
  position: relative;
  top: 2px;
}

.categoriesoverviewintro {
  padding-top: 50px;
  padding-bottom: 45px;
  background-color: #FFFFFF;
  text-align: center;
}

.associationsoverviewintro {
  padding-top: 50px;
  padding-bottom: 45px;
  background-color: #FFFFFF;
  text-align: center;
}

h1 .category__heading__ctitle {
  color: #F9690D;
  font: 500 66px/62px "Univers Next W02";
}

.associationsoverview {
  padding-top: 35px;
  padding-bottom: 35px;
}

.associationsoverview h2 {
  color: #fd7723;
  margin: 0;
}

.associationsoverview__topbar {
  margin-top: 0;
  margin-bottom: 26px;
}

@media (min-width: 768px) {
  .associationsoverview__topbar {
    display: flex;
    align-items: center;
  }
}
.associationsoverview__topbar .filler {
  flex: 1 0 0;
}

@media (max-width: 767px) {
  .associationsoverview__topbar {
    display: block;
  }
  /*
      .associationsoverview__topbar #filter_buddies
    , .associationsoverview__topbar #filter_buddies-button
    {
      order: -2;
    }
    .associationsoverview__topbar .filler
    {
      order: -1;
    }
  */
}
@media (min-width: 767px) {
  .associationsoverview__topbar select,
  .associationsoverview__topbar .ui-selectmenu-button {
    margin-left: 14px;
  }
}
.buddygrid__item__hover {
  padding: 10px;
  color: #FFFFFF;
}

.buddygrid__item__hover a {
  color: #FFFFFF;
  text-decoration: underline;
}

.assometa__title {
  font: 700 17px/20px "Univers Next W02";
  margin-left: 26px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.assometa__row {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: baseline;
  font: 14px/18px Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.assometa__row + .assometa__row {
  margin-top: 8px;
}

.assometa__row .fa {
  width: 24px;
  flex: none;
}

.assometa__row span {
  flex: 1 0 0;
}

.assometa__row--website::before,
.assometa__row--subtitle::before,
.assometa__row--membercount::before,
.assometa__row--weekdays::before,
.assometa__row--language::before,
.assometa__row--when::before {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  flex: none;
  margin-right: 10px;
}

.assometa__row--website::before {
  content: url("../../web/img/fa5/share.white.svg");
}

.assometa__row--subtitle::before {
  content: url("../../web/img/fa5/volleyball-ball.white.svg");
}

.assometa__row--membercount::before {
  content: url("../../web/img/fa5/child.white.svg");
}

.assometa__row--weekdays::before {
  content: url("../../web/img/fa5/calendar-alt.white.svg");
}

.assometa__row--language::before {
  content: url("../../web/img/fa5/comment.white.svg");
}

.assometa__row--when::before {
  content: url("../../web/img/fa5/door-open.white.svg");
}

.buddygrid--associations .buddygrid__item {
  border: 1px solid #F9690D;
  cursor: pointer;
}

.buddygrid--associations .buddygrid__item__button {
  position: relative;
  font: 500 17px/25px "Univers Next W02";
  letter-spacing: 0.5px;
  text-align: center;
  height: 45px;
}

.buttoncontent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: transform 0.5s, opacity 0.3s;
}

.buddygrid__item__button__content {
  opacity: 1;
  transform: translate3D(0, 0, 0);
}

.buddygrid__item__button__hover {
  opacity: 0;
  transform: translate3D(100%, 0, 0);
}

.buddygrid__item:hover .buddygrid__item__button__content {
  opacity: 0;
  transform: translate3D(-100%, 0, 0);
}

.buddygrid__item:hover .buddygrid__item__button__hover {
  opacity: 1;
  transform: translate3D(0, 0, 0);
}

.buddygrid--associations .buddygrid__item__button__hover {
  font-size: 34px;
}

.buddygrid--associations .buddygrid__item__button__hover .fa {
  font-size: 40px;
  margin-left: 25px;
}

.buddygrid--associations .buddygrid__item:hover .buddygrid__item__button .fa {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-7px);
  }
  60% {
    transform: translateX(-5px);
  }
}
.buddygrid--associations .buddygrid__item--nowalkalong .buddygrid__item__button {
  font: 500 15px/25px "Univers Next W02";
}

.buddygrid__item.buddygrid__item--nowalkalong .buddygrid__item__button {
  background-color: #FDD4BA;
  border-top: 1px solid #F9690D;
  color: #000000;
  text-transform: none;
}

.buddygrid__item--nomatch {
  display: none;
}

.keepmeinformedpopup {
  width: 700px;
  max-width: 90%;
}

.keepmeinformedpopup__intro {
  margin: 20px 0;
}