/*

This is based on PTHU2 < De Woonplaats < WIG < .. (but much improved/cleaned)

Fonts used:
             2020                           2018 ??                        2016
- Headers:   Univers Condensed (all caps) - Univers Condensed (all caps) - Montserrat Bold
- Body text: Lato                         - PT Sans                      - Roboto Regular

*/


/* This imports the default Univers font, which is applied to <html> in rtd.css.
   It shouldn't be necessary to set the font-family; if needed, use font-family:
   inherit; to apply the document's font to an element.

   To select a specific font variant, set the font-weight:
   - Univers Light Condensed        font-weight: 300;
   - Univers Roman (the base font)  font-weight: 400;
   - Univers Regular Condensed      font-weight: 500;
   - Univers Bold                   font-weight: 600;
   - Univers Bold Condensed         font-weight: 700;

   Web Fonts Project: Utwente WS2016 Extended
*/

@import "../../web/fonts/fontawesome/css/all.css";

/*@import "https://fonts.googleapis.com/css?family=Roboto:400,700|Montserrat:400,700";*/
@import url("https://www.utwente.nl/.utdesign/fonts/univers-next.css");

/*@import url('https://fonts.googleapis.com/css?family=PT+Sans');*/
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");



*
{
  box-sizing: border-box;
}


html
{
  font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;

  color: #191919;
  background-color: #FFFFFF;


  --color-error:           #EE0000; /* lightest accessible red, 4.53:1 contrast on white */
  --color-grey-border:     #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-text-small: #767677; /* WCAG 4.53:1 contrastratio */
}

body
{
  background-color: #FFFFFF; /* required for WCAG compliance */
}

/* never have a new chapter flow around an image from a previous chapter */
h1, h2
{
  clear: both;
}





/*
.wh-rtd-editor a
{
  text-decoration: underline;
}
*/


.used_on_dark_background a
{
  color: #FFFFFF;
  /* text-decoration: none; */
}

  a:hover
, .wh-rtd-editor a:hover
, .used_on_dark_background a:hover
{
  text-decoration: underline;
}






/* prevent image in columns or in fluid mobile layout
   to stick outside their content area
*/
img.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

  img.wh-rtd__img--floatleft
, img.wh-rtd__img--floatright
{
  max-width: 50%;
  margin: 0 20px 0 0;
}



/* prevent embeddedobject getting beside a floating image */
  .embeddedobject
, .wh-rtd-embeddedobject
{
  clear: both;
}

/* NOTE: this will give a problem when there are floats in another column
         (outside this element)
         (Unless .rtdcontent get's an overflow: auto/hidden/scroll; or float: left; so it'll make a new context)
         (essecially have some kind of layout context)
*/
  .rtdcontent::after
, .wh-form__richtext::after
{
  display: block;
  content: "";
  clear: both;
}


quote
{
  margin-left: 15%;
  color: #666666;
}


  .wh-rtd-editor:not(.used_on_dark_background) > p
, .wh-rtd-editor:not(.used_on_dark_background) > ul
, .wh-rtd-editor:not(.used_on_dark_background) > ol
, .wh-rtd-editor:not(.used_on_dark_background) > h1
, .wh-rtd-editor:not(.used_on_dark_background) > h2
, .wh-rtd-editor:not(.used_on_dark_background) > h3
, .wh-rtd-editor:not(.used_on_dark_background) > h4
, .wh-rtd-editor:not(.used_on_dark_background) > .wh-rtd__tablewrap /* table */
, .wh-rtd-editor:not(.used_on_dark_background) > .embeddedobject-inflow
{
  background-color: #FFFFFF;
}

.wh-rtd-editor.transparent
{
  background-color: #f7f8f9;
}

  .wh-rtd-editor.transparent.transparent > p
, .wh-rtd-editor.transparent.transparent > ul
, .wh-rtd-editor.transparent.transparent > ol
, .wh-rtd-editor.transparent.transparent > h1
, .wh-rtd-editor.transparent.transparent > h2
, .wh-rtd-editor.transparent.transparent > h3
, .wh-rtd-editor.transparent.transparent > h4
, .wh-rtd-editor.transparent.transparent > .wh-rtd__tablewrap /* table */
, .wh-rtd-editor.transparent.transparent > .embeddedobject-inflow
{
  background-color: transparent;
}




/* we should not use :not, because it makes it more specific,
   while .transparent is the thing that's more specific
*/
.embeddedobject.transparent
{
  background-color: transparent;
}



  .rtdcontent > p
, .rtdcontent > ul
, .rtdcontent > ol
, .rtdcontent > .embeddedobject.transparent

, .wh-form__richtext > p
, .wh-form__richtext > ul
, .wh-form__richtext > ol
, .wh-form__richtext > .embeddedobject.transparent

, .widget
, .buttonwidget
{
  margin-top: 15px;
/*  margin-bottom: 15px;*/
}

.widget--opaque + .widget--opaque
{
  margin-top: 0;
}


/* whitepanel will be definied on .embeddedobject so use the padding there */
.embeddedobject-container > .embeddedobject
{
  padding-top: 15px;
  padding-bottom: 15px;
}

/* no distance needed to the previous element if we are the first element */
/* !! FIXME: use first-of-type with a <rtdcontent> to control the padding */
/*
.document-toplevel.rtdcontent-mainflow:first-child > .embeddedobject:first-child
{
  padding-top: 0;
}
*/

html.tightlayout .embeddedobject-container > .embeddedobject
{
  padding-top: 0;
  padding-bottom: 0;
}



  .rtdcontent > p:last-child
, .rtdcontent > ul:last-child
, .rtdcontent > ol:last-child
, .rtdcontent > h1:last-child
, .rtdcontent > h2:last-child
, .rtdcontent > h3:last-child
, .rtdcontent > h4:last-child
, .wh-form__richtext > p:last-child
, .wh-form__richtext > ul:last-child
, .wh-form__richtext > ol:last-child
, .wh-form__richtext > h1:last-child
, .wh-form__richtext > h2:last-child
, .wh-form__richtext > h3:last-child
, .wh-form__richtext > h4:last-child
{
  margin-bottom: 0;
}

/* sort of collapse-margins because we use padding instead of margins */

  .rtdcontent > .embeddedobject + p
, .wh-form__richtext > .embeddedobject + p
{
  margin-top: 15px; /* top so we can remove if needed in a + selector */
}

  .rtdcontent > h1 + p
, .rtdcontent > h2 + p
, .rtdcontent > h3 + p
, .rtdcontent > h4 + p
, .wh-form__richtext > h1 + p
, .wh-form__richtext > h2 + p
, .wh-form__richtext > h3 + p
, .wh-form__richtext > h4 + p
{
  margin-top: 0;
}



  ul.unordered
, ol.ordered
{
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
  ul.unordered li
, ol.ordered li
{
  margin: 0 0 0 18px; /* try to make the bullet line out with the left margin */
}
/*
  ul.unordered li + li
, ol.ordered li + li
{
  margin-top: 7px;
}
*/
  ul.unordered ul
, ol.ordered ol
{
  padding: 0;
  margin-left: 25px;
}
  ul.unordered ul li:first-child
, ol.ordered ol li:first-child
{
  margin-top: 4px;
}



  .rtdcontent > *:first-child
, .wh-form__richtext > *:first-child
{
  margin-top: 0;
}
.rtdcontent > .pad_v_placeholder + *
{
  margin-top: 0;
}



  .align-left /* used in CTA/columns widgets */
, .buttonwidget.align-left
{
  text-align: left;
}
  .align-right
, .buttonwidget.align-right
{
  text-align: right;
}
  .align-center
, .buttonwidget.align-center
{
  text-align: center;
}
.align-justify
{
  text-align: justify;
}



/* Mobile layout RTD styling
   2020: https://projects.invisionapp.com/d/main#/console/19513142/407841745/inspect

   NOTE: don't add styling based on .site-fullwidth here. <940 the .site-fullwidth concept doesn't exist
*/
@media only screen and (max-width: 767px)
{
  .page-header__slide__title
  {
    font: 700 28px/30px "Univers Next W02";
    text-transform: uppercase;
  }

  h1, .fs_h1
  {
    /*font: bold 23px/30px "Montserrat";*/
    /*font: 500 23px/30px "Univers Next W02";*/
    font: 700 24px/30px "Univers Next W02"; /* UniversNextW04-HeavyCond Regular */
    text-transform: uppercase;

    margin: 30px 0 20px 0;
  }

  .widget__title
  {
    font: 700 22px/28px "Univers Next W02";
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  h2, .fs_h2, .newswidget-title
  {
    /*font: bold 18px/24px "Montserrat";*/
    /*font: 500 18px/24px "Univers Next W02";*/
    font: 700 19px/26px "Univers Next W02"; /* UniversNextW04-HeavyCond Regular */
    text-transform: uppercase;

    margin: 30px 0 15px 0;
  }

  h3, .fs_h3
  {
    /*font: bold 16px/24px "Montserrat";*/
    /*font: 500 16px/24px "Univers Next W02";*/
    font: 700 17px/26px "Univers Next W02"; /* UniversNextW04-HeavyCond Regular */
    text-transform: uppercase;

    margin: 30px 0 10px 0;

    text-transform: uppercase;
  }

  .fs_h1, .fs_h2, .fs_h3 { margin-top: 0; }



  p.normal
  {
    font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }
  p.intro
  {
    font: 300 17px/26px "Univers Next W02";
    text-transform: uppercase;
  }
}



/* Tablet portrait
   2020: https://projects.invisionapp.com/d/main#/console/19513142/407841744/inspect

   iPad 768px width
*/
@media only screen and (min-width: 768px)
{
  .page-header__slide__title
  {
    font: 700 54px/56px "Univers Next W02";
    text-transform: uppercase;
  }

  h1, .fs_h1, .site-fullwidth .newswidget-title
  {
    /* design 1 - font: bold 40px/48px "Montserrat";*/
    /* design 2 - font: 500 40px/48px "Univers Next W02";*/
    font: 700 54px/56px "Univers Next W02"; /* UniversNextW04-HeavyCond Regular */
    text-transform: uppercase;

    margin: 30px 0 20px 0;
  }

  .widget__title /* Missing for Tablet in design */
  {
    /*font: 700 36px/66px "Univers Next W02";*/
    font: 700 36px/45px "Univers Next W02";
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  h2, .fs_h2, .newswidget-title
  {
    /* design 1 - font: bold 22px/24px "Montserrat";*/
    /* design 2 - font: 500 22px/24px "Univers Next W02";*/
    font: 700 26px/28px "Univers Next W02"; /* UniversNextW04-HeavyCond Regular */
    text-transform: uppercase;

    margin: 30px 0 15px 0;
  }

  h3, .fs_h3
  {
    /*font: bold 16px/24px "Montserrat";*/
    /*font: 500 16px/24px "Univers Next W02";*/
    font: 700 23px/26px "Univers Next W02";
    text-transform: uppercase;

    margin: 30px 0 10px 0;
  }

  .fs_h1, .fs_h2, .fs_h3 { margin-top: 0; }


  p.normal
  {
    font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-weight: normal;
    font-size: 17px;
    line-height: 26px;
  }
  p.intro
  {
    font: 300 23px/30px "Univers Next W02";
    text-transform: uppercase;
  }
}





/* Large displays (tablet/desktop) headers
   2020: https://projects.invisionapp.com/d/main#/console/19513142/407841743/inspect
*/
@media only screen and (min-width: 1024px) /* was 940px */
{
  .page-header__slide__title
  {
    font: 700 64px/66px "Univers Next W02";
    text-transform: uppercase;
  }

  h1, .fs_h1, .site-fullwidth .newswidget-title
  {
    /* design 1 - font: bold 40px/48px "Montserrat";*/
    /* design 2 - font: 500 40px/48px "Univers Next W02";*/
    font: 700 64px/66px "Univers Next W02"; /* UniversNextW04-HeavyCond Regular */
    text-transform: uppercase;

    margin: 30px 0 20px 0;
  }

  .widget__title
  {
    /*font: 700 36px/66px "Univers Next W02";*/
    font: 700 36px/45px "Univers Next W02";
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  h2, .fs_h2, .newswidget-title
  {
    /* design 1 - font: bold 22px/24px "Montserrat";*/
    /* design 2 - font: 500 22px/24px "Univers Next W02";*/
    font: 700 26px/28px "Univers Next W02"; /* UniversNextW04-HeavyCond Regular */
    text-transform: uppercase;

    margin: 30px 0 15px 0;
  }

  h3, .fs_h3
  {
    /*font: bold 16px/24px "Montserrat";*/
    /*font: 500 16px/24px "Univers Next W02";*/
    font: 700 23px/26px "Univers Next W02";
    text-transform: uppercase;

    margin: 30px 0 10px 0;
  }

  .fs_h1, .fs_h2, .fs_h3 { margin-top: 0; }


  p.normal
  {
    font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-weight: normal;
    font-size: 17px;
    line-height: 26px;
  }
  p.intro
  {
    font: 300 23px/30px "Univers Next W02";
    text-transform: uppercase;
  }
}


/* two/three column paragraph style
   until Webhare has a break-column option, the browser fully controls what is placed in which column
*/
@media only screen and (min-width: 940px)
{
  p.twocolumn
  {
    columns: 2;
    column-gap: 20px;
  }
  p.threecolumn
  {
    columns: 3;
    column-gap: 20px;
  }
}
