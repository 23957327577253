.sidebar__mainmenu.sidebar__mainmenu {
  margin-left: 4px;
  margin-right: 4px;
}

.sidebar__mainmenu a {
  display: flex;
}

.sidemainmenu__item__toggle {
  order: -1;
  width: 0;
  margin-left: -20px; /* shift to the left */
  margin-right: 20px; /* but restore the position for the anchor text */
  position: relative;
}

.sidemainmenu-level1__itemlink {
  text-transform: uppercase;
}

.sidebar__languages {
  color: #AAAAAA;
  font: 500 12px "Univers Next W02";
}

.sidebar__languages a {
  color: var(--color-theme);
  font: 500 17px "Univers Next W02";
  text-decoration: underline;
  padding: 0 4px;
}

.sidebar__languages span {
  color: var(--color-theme);
  font: 500 17px "Univers Next W02";
  padding: 0 4px;
}

.sidebar__header {
  height: var(--site-mainmenu-height);
}