  .wh-styledinput input[type='date']
, .wh-styledinput input[type='time']
, .wh-styledinput input[type='email']
, .wh-styledinput input[type='tel']
, .wh-styledinput input[type='text']
, .wh-styledinput input[type='password']
, .wh-styledinput input[type='number']
, .wh-styledinput input[type='url']
, .wh-styledinput input[type='search']
, .wh-styledinput textarea
, .wh-styledinput select, .ui-selectmenu-button
, select.styled
{
  padding: 6px 10px;
  height: 38px;

  border: 1px solid var(--color-grey-border);
  border-radius: 2px;

  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance:none;
  font: inherit;

  color: #000000;

  outline: none;
}


  .wh-styledinput input[type='date']:focus
, .wh-styledinput input[type='time']:focus
, .wh-styledinput input[type='email']:focus
, .wh-styledinput input[type='tel']:focus
, .wh-styledinput input[type='text']:focus
, .wh-styledinput input[type='password']:focus
, .wh-styledinput input[type='number']:focus
, .wh-styledinput input[type='url']:focus
, .wh-styledinput input[type='search']:focus
, .wh-styledinput textarea:focus
, .wh-styledinput select:focus, .ui-selectmenu-button:focus
, select.styled:focus
, input.date-day
, input.date-month
, input.date-year
{
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  outline: 2px solid var(--sitethemecolor);
}


  .wh-styledinput input[type='text']:not([disabled]):not([readonly]):hover
, .wh-styledinput input[type='text']:not([disabled]):not([readonly]):focus
, .wh-styledinput input[type='date']:not([disabled]):not([readonly]):focus
, .wh-styledinput input[type='time']:not([disabled]):not([readonly]):focus
, .wh-styledinput input[type='email']:not([disabled]):not([readonly]):hover
, .wh-styledinput input[type='email']:not([disabled]):not([readonly]):focus
, .wh-styledinput input[type='password']:not([disabled]):not([readonly]):hover
, .wh-styledinput input[type='password']:not([disabled]):not([readonly]):focus
, .wh-styledinput input[type='number']:not([disabled]):not([readonly]):hover
, .wh-styledinput input[type='number']:not([disabled]):not([readonly]):focus
, .wh-styledinput input[type='date']:not([disabled]):not([readonly]):hover
, .wh-styledinput input[type='date']:not([disabled]):not([readonly]):focus
, .wh-styledinput input[type='url']:not([disabled]):not([readonly]):hover
, .wh-styledinput input[type='url']:not([disabled]):not([readonly]):focus
, .wh-styledinput textarea:not([disabled]):not([readonly]):hover
, .wh-styledinput textarea:not([disabled]):not([readonly]):focus
, .wh-styledinput select:not([disabled]):not([readonly]):hover
, .wh-styledinput select:not([disabled]):not([readonly]):focus
, .ui-selectmenu-button.ui-state-hover:not(.ui-state-disabled)
, .ui-selectmenu-button.ui-state-focus:not(.ui-state-disabled)
{
  border-color: #666;
}

  .wh-styledinput input[type='text'][disabled], .wh-styledinput input[type='text'][readonly]
  .wh-styledinput input[type='date'][disabled], .wh-styledinput input[type='date'][readonly]
, .wh-styledinput input[type='time'][disabled], .wh-styledinput input[type='time'][readonly]
, .wh-styledinput input[type='email'][disabled], .wh-styledinput input[type='email'][readonly]
, .wh-styledinput input[type='password'][disabled], .wh-styledinput input[type='password'][readonly]
, .wh-styledinput input[type='number'][disabled], .wh-styledinput input[type='number'][readonly]
, .wh-styledinput input[type='date'][disabled], .wh-styledinput input[type='date'][readonly]
, .wh-styledinput input[type='url'][disabled], .wh-styledinput input[type='url'][readonly]
, .wh-styledinput textarea[disabled], .wh-styledinput textarea[readonly]
, .wh-styledinput select[disabled], .wh-styledinput select[readonly]
, .ui-selectmenu-button.ui-state-disabled
{
  background-color: #f0f1f2;
  color: rgba(0,0,0,0.4);
}

  .wh-styledinput input.wh-form__field--error[type='text']
, .wh-styledinput input.wh-form__field--error[type='url']
  .wh-styledinput input.wh-form__field--error[type='date']
  .wh-styledinput input.wh-form__field--error[type='time']
, .wh-styledinput input.wh-form__field--error[type='email']
, .wh-styledinput input.wh-form__field--error[type='password']
, .wh-styledinput input.wh-form__field--error[type='number']
, .wh-styledinput input.wh-form__field--error[type='date']
, .wh-styledinput input.wh-form__field--error[type='search']
, .wh-styledinput textarea.wh-form__field--error
, .wh-styledinput select.wh-form__field--error
, .wh-form__fieldgroup.error .ui-selectmenu-button
{
  border-color: #cd202c;
  color: #cd202c;
}



/* Stretch textual fields
     - when opting-in to justify form content using .wh-form--justify
*/
  .wh-form--justify input[type="text"]:not(.wh-date)
, .wh-form--justify input[type="email"]
, .wh-form--justify input[type="password"]
, .wh-form--justify input[type="number"]
, .wh-form--justify input[type="search"]
, .wh-form--justify input[type="url"]
, .wh-form--justify textarea
, .wh-form--justify .select2-container

, html.page--formwebtool input[type="text"]:not(.wh-date)
, html.page--formwebtool input[type="email"]
, html.page--formwebtool input[type="password"]
, html.page--formwebtool input[type="number"]
, html.page--formwebtool input[type="search"]
, html.page--formwebtool input[type="url"]
, html.page--formwebtool textarea
, html.page--formwebtool .select2-container

{
  flex: 1;
  max-width: 100%;
}



.wh-form__errorlist
{
  padding: 0;
  margin: 5px 0 0;
  color: #cd202c;
  font: inherit;
  list-style-type: none;

  /* force errors to display kind of like 'block' (on their on vertical beginpoint) */
  width: 100%; /* might be safer than flex-basis: 100%; because IE10/11 erroroneously assumes box-sizing: content-box; and will use flex-basis:100%;+PADDING as width */
}

.wh-form__prefix + *
{
  margin-left: 10px;
}
* .wh-form__suffix
{
  margin-left: 10px;
}

.wh-form__error
{
  flex: none;
  width: 100%;
  margin: 5px 0 0;
  color: #cd202c;
}

.wh-form__fields {
  flex-grow: 1;
}

.wh-form__fieldline
{
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
 }

.wh-form__fieldgroup.error .wh-form__fields
{
  padding-bottom: 16px;/* create some space for errors */
  white-space: nowrap;
}



.wh-styledinput .fieldnote
{
  margin: 5px 0 0 135px;
}

.wh-styledinput textarea
{
  resize: vertical;
  height: 132px;
  flex-grow: 1;
}


.wh-styledinput .wh-form__buttongroup
{
  margin-top: 15px;
  text-align: right;
}
.wh-styledinput .wh-form__buttongroup:after
{
  clear: both;
  content: '';
  display: block;
}

  .wh-styledinput .wh-form__buttongroup button.wh-form__button--previous
, .wh-styledinput .wh-form__buttongroup button.wh-form__button--legacyprevious
{
  float: left;
}
.wh-styledinput button.wh-form__button--upload
{
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
}
