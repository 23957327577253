:root {
  --menubar-pulldown-pointer-width: 20px;
  --menubar-pulldown-pointer-height: 38px;
  --sidemainmenu-toggler-width: 22px;
  --sidemainmenu-toggler-margin: 0px;
  --menubar-level1-font: 500 17px "Univers Next W02";
  --menubar-level1-padding-left: 15px;
  --menubar-level1-padding-right: 15px;
  --menubar-bar-item-color: #000000;
  --menubar-bar-item-hover-color: var(--color-theme);
  --menubar-pulldown-border-radius: 0px;
  --menubar-pulldown-padding: 8px 4px 8px 4px;
  --menubar-pulldown-bgcolor: #FFFFFF;
  /*
  --menubar-pulldown-bgcolor:             #FFFF00;
  --menubar-pulldown-border-color:        #FF0000;
  --menubar-pulldown-border-width:        1px;
  */
  --menubar-pulldown-border-color: #FF0000;
  --menubar-pulldown-border-width: 0;
  --menubar-pulldown-drop-shadow: 2px 2px 8px rgba(0,0,0,0.32);
  /*
  --menubar-pulldown-itemhover-bgcolor:   var(--common-linkblock-hover);
  --menubar-pulldown-itemhover-textcolor: #000000;
  --menubar-pulldown-itemhover-textdecoration: underline;
  */
  --menubar-pulldown-itemhover-bgcolor: var(--color-theme);
  --menubar-pulldown-itemhover-textcolor: #FFFFFF;
  --menubar-pulldown-itemhover-textdecoration: none;
  --menubar-pulldown-zindex: 10;
  --menubar-pulldown-minwidth: 250px;
  --menubar-pulldown-maxwidth: 320px;
  --menubar-level1-spacing: 0px;
  --menubar-level2-padding-left: 28px;
  --menubar-level2-padding-right: 15px;
  --menubar-level2-color: #000000;
  --menubar-level2-font: 700 16px/19px "Univers Next W02";
  --menubar-level2-padding-left: 30px;
  --menubar-level2-padding-right: 20px;
  --menubar-level2-padding-top: 9px;
  --menubar-level2-padding-bottom: 8px;
  --menubar-level2-expandicon: var(--fa-chevron-right);
  --menubar-level2-collapseicon: var(--fa-chevron-down);
  --menubar-level2-toggle-font: 15px "Font Awesome 5 Pro";
  --menubar-level3-color: #000000;
  --menubar-level3-font: 500 16px/19px "Univers Next W02";
  --menubar-level3-padding-left: 45px;
  --menubar-level3-padding-right: 20px;
  --menubar-level3-padding-top: 9px;
  --menubar-level3-padding-bottom: 8px;
  --menubar-level3-expandicon: var(--fa-chevron-right);
  --menubar-level3-collapseicon: var(--fa-chevron-down);
  --menubar-level3-toggle-font: 400 15px "Font Awesome 5 Pro"; /* regular */
  --menubar-level4-color: #000000;
  --menubar-level4-font: 500 16px/19px "Univers Next W02";
  --menubar-level4-padding-left: 60px;
  --menubar-level4-padding-right: 20px;
  --menubar-level4-padding-top: 9px;
  --menubar-level4-padding-bottom: 8px;
}

ul.topmenu > li > a {
  text-transform: uppercase;
}

.topmenu__level2 a {
  display: flex;
}

.topmenu__item__toggle.topmenu__item__toggle {
  order: -1;
  width: 0;
  width: 20px;
  text-align: center;
  margin-right: 3px;
  margin-left: -23px; /* width + margin-right - distance-from-left-edge */
}

/*
.topmenu__pulldown
{
  display: block !important;
}
*/
/* Align pulldown with text in menu item */
.topmenu__pulldown > ul {
  margin-left: var(--menubar-level1-padding-left) !important;
}

.topmenu__level2 a {
  text-transform: uppercase;
}

.topmenu__level3 a {
  text-transform: none;
}

/*
.topmenu__pulldown > ul
{
    &::after
  , &::before
  {
    bottom: 100%;
    //left: 50%;

    left: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;

    // filter: drop-shadow(0px 0px 6px rgba(255,0,0,0.15));
  }

  &::after
  {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 12px;
    margin-left: -12px;
  }

  // color edge around arrow
  &::before
  {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: #000;
    border-width: 13px;
    margin-left: -13px;
  }
}
*/
/* Pijl-achtig ding */
.topmenu__pulldown {
  padding-top: 28px; /* create space to place the arrow thingy */
}

/*
.topmenu__pulldown > ul
{
  filter: drop-shadow(var(--menubar-pulldown-drop-shadow));

  &::before
  {
    //outline: 5px solid #0F0;
    content: " ";

    border-width: 20px 20px 0 20px;
    border-style: solid;
    border-color: transparent transparent transparent transparent;
    // border-left: 50px solid #F00;
    border-left: 38px solid #F00;

    width: 0px;
    height: 0;

    position: absolute;
    left: 0;
    bottom: 100%;

    pointer-events: none;
  }
}
*/