dialog:not([open]) {
  display: none;
}

#slidemenu-container:not([open]) {
  display: none;
}

html.sidebar-enabled body {
  position: fixed;
  overscroll-behavior: contain;
}

#slidemenu-container {
  /* for browsers without native <dialog> support */
  position: fixed;
  z-index: 100; /* if we aren't a dialog we aren't on the top-layer so use z-index to get on top */
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  height: 100dvh; /* FIXME: otherwise the dialog gets centered causing part of the top to go out of view */
  width: 100%;
  width: 100dvw;
  margin-left: 0;
  margin-right: auto;
  max-width: 500px;
  max-height: none; /* Chrome applies a max height to <dialog> */
  background-color: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border: none;
  padding: 0; /* <dialog> has a default padding */
}

@media (min-width: 550px) {
  #slidemenu-container {
    width: min(100% - 100px, 400px);
  }
}
/*
dialog:-internal-modal {
    position: fixed;
    top: 0px;
    bottom: 0px;
    max-width: calc((100% - 6px) - 2em);
    max-height: calc((100% - 6px) - 2em);
    user-select: text;
    visibility: visible;
    overflow: auto;
}
*/
.sidebar__header__logo {
  position: relative;
  left: -10px;
  width: 133px;
  height: 44px;
}

.sidemainmenu-level1__item {
  position: relative;
}