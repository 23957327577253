/*
@import "../../shared/config.scss";
@import "../../components/pagegrid/grid-mixins.scss";
*/



  .used_on_dark_background.used_on_dark_background.used_on_dark_background .widgetdl__item__icon
, .used_on_dark_background.used_on_dark_background.used_on_dark_background .widgetdl__item__title
, .used_on_dark_background.used_on_dark_background.used_on_dark_background .widgetdl__item__filesize
{
  color: #FFFFFF;
}

  .used_on_dark_background.tc-yellow-bg-dark.tc-yellow-bg-dark .widgetdl__item__icon
, .used_on_dark_background.tc-yellow-bg-dark.tc-yellow-bg-dark .widgetdl__item__title
, .used_on_dark_background.tc-yellow-bg-dark.tc-yellow-bg-dark .widgetdl__item__filesize
{
  color: #24416A;
}

.widgetdl__item.widgetdl__item:hover
{
  /*background-color: #F5F5F5; $color-lightgrey;*/
  background-color: rgba(0, 0, 0, 0.1);
}

.used_on_dark_background .widgetdl__item.widgetdl__item
{
  border-top: 1px solid rgba(0, 0, 0, 0.4);
}
.used_on_dark_background .widgetdl__item.widgetdl__item.widgetdl__item:last-child
{
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}



.used_on_dark_background .widgetdl__item.widgetdl__item:hover
{
  background-color: rgba(255, 255, 255, 0.1);
}


.used_on_dark_background.tc-yellow-bg-dark .widgetdl__item
{
  border-top: 1px solid #777777;
}
.used_on_dark_background.tc-yellow-bg-dark .widgetdl__item:last-child
{
  border-bottom: 1px solid #777777;
}


.widget-downloadsandlinks
{

}

.widget-downloadsandlinks ul
{
  list-style: none;
  margin: 0;
  padding: 0;
}


.widgetdl__item
{
  display: flex;
  align-items: baseline;

  border-top: 1px solid #C5C5C5;
  padding: 11px;

  position: relative;
}


  .widgetdl__item__icon
, .widgetdl__item__meta
{
  pointer-events: none;
}

.widgetdl__item__anchor
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: auto;
}

.widgetdl__item:last-child
{
  border-bottom: 1px solid #C5C5C5;
}

  .widgetdl__item__icon
, .widgetdl__item__title
, .widgetdl__item__filesize
{
  color: #000000;
}

.widgetdl__item__icon
{
  flex: none;
  font-size: 18px;

  text-align: center;
  width: 20px;
  margin-right: 10px;
}

.widgetdl__item__icon::before
{
  vertical-align: middle;
}

.widgetdl__item__icon.fa-arrow-right
{
  font-size: 13px;
}

.widgetdl__item__icon.fa-external-link-alt
{
  font-size: 16px;
}

.widgetdl__item__meta
{
  /*font: 16px/24px $font-text;*/
  font-size: 16px;
  line-height: 24px;
}

.widgetdl__item__title
{
  font-weight: bold;
}

.widgetdl__item__filesize
{
  margin-left: 10px;
  white-space: nowrap;
}

.widgetdl__item__description
{
  margin-top: 5px;
  /*color: #666666;*/
}
