/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
.widget-ctablocks.widget-ctablocks {
  grid-column: 1/span 31;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
}
.widget-ctablocks.widget-ctablocks.widget--background {
  padding-top: 30px;
  padding-bottom: 30px;
}

.widget-ctablocks__items {
  grid-column: 3/30;
  display: flex;
}

.widget-ctablocks__bullets {
  grid-column: 3/30;
}
@media (max-width: 799px) {
  .widget-ctablocks__bullets {
    margin-top: 15px;
  }
}
@media (min-width: 800px) {
  .widget-ctablocks__bullets {
    margin-top: 30px;
  }
}

.widget-ctablocks__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.widget-ctablocks__item__filler {
  flex: 1 1 0px;
}

.widget-ctablocks__item + .widget-ctablocks__item {
  margin-left: 30px;
}

.widget-ctablocks__item__title {
  flex: none;
  font: 700 30px/36px "Univers Next W02";
  text-transform: uppercase;
}

.widget-ctablocks__item__subtitle {
  flex: none;
  margin-top: 2px;
  font: 500 20px/23px "Univers Next W02";
}

.widget-ctablocks__item__button {
  flex: none;
  margin-top: 20px;
  white-space: nowrap;
}

@media (max-width: 639px) {
  .widget-ctablocks__item {
    flex: 0 0 100%;
  }
  .widget-ctablocks__item {
    scroll-snap-align: start;
  }
}
@media (min-width: 640px) and (max-width: 1023px) {
  .widget-ctablocks__item {
    flex: 0 0 auto;
    width: calc(50% - 15px);
  }
  .widget-ctablocks__item:nth-child(2n+1) {
    scroll-snap-align: start;
  }
}
@media (min-width: 1024px) {
  .widget-ctablocks__item {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 20px);
  }
  .widget-ctablocks__item:nth-child(3n+1) {
    scroll-snap-align: start;
  }
}
@media (max-width: 1022px) {
  .widget-ctablocks__items {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }
  .widget-ctablocks__items::after {
    content: "";
    flex: 0 0 100%;
  }
  .blockswidget__item {
    scroll-margin-left: 30px;
    scroll-snap-margin-left: 30px;
  }
  .widget-ctablocks__items::-webkit-scrollbar {
    display: none;
  }
  .widget-ctablocks__items {
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }
}