.buddy_loginoptions .ctabutton {
  height: 60px;
}

.buddyrequest a {
  color: inherit;
}

.buddyrequest a:hover {
  color: #fd7723;
}

.buddyrequest__assocationinfo {
  margin-top: 20px;
}

.buddyrequest__form {
  margin-top: 40px;
}

.buddyrequest__form .wh-form__buttongroup {
  margin-top: 40px;
}

.buddyrequest__success {
  display: none;
}

.buddyrequest__fail {
  display: none;
}

.buddy_loginoptions {
  margin-top: 80px;
}

.buddy_loginoptions__option {
  margin-top: 30px;
}

.buddy_loginoptions__option h2 {
  margin-top: 0;
}

.buddy_loginoptions__option + .buddy_loginoptions__option {
  margin-top: 60px;
}

.buddy_loginoptions__option .buttonbar {
  margin-top: 15px;
}

[data-wh-form-group-for=agreetoterms] > .wh-form__label {
  order: 5;
  max-width: none;
  width: auto;
  padding-right: 0;
}

[data-wh-form-group-for=agreetoterms] > .wh-form__fields {
  order: -1;
  flex-grow: 0;
}