.ks--imageheader .ui-selectmenu-button
{
  display: flex;
  align-items: center;
}
.ks--imageheader .ui-selectmenu-text
{
  flex: 1;

  text-align: left;

  /* home specific */
  font: 18px Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  color: #80858c;
  padding: 0 20px;
}
.ks--imageheader .ui-selectmenu-button .ui-icon
{
  position: static;

  order: 2;
}

.ksh--header--content
{
  position: relative;
  color: #FFFFFF;
  text-align: center;
}
  .ksh--header--slogan
, .ksh--header--counter
{
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
.ksh--header--logo > img
{
  height: 100%;
}


#ksh--header--submitroom
{
  display: block;
  text-decoration: none;

  font: normal 14px "Univers Next W02";

  height: 36px;
  line-height: 36px;
  border-radius: 5px;

  padding: 0 14px;

  position: absolute;
  right: 0;
  top: 40px;

  background-color: rgba(255, 255, 255, 0.2);

  color: #FFFFFF;
  text-transform: uppercase;
}
#ksh--header--submitroom:hover
{
  background-color: rgba(0, 0, 0, 0.2);

  transition: background-color 0.2s;
}



/* MOBILE */
@media only screen and (max-width: 749px)
{
  html.page-site-home .ks--imageheader
  {
  }
  html.page-site-home #ksh--header--buttonbar
  {
    position: absolute;
    left: 0;
    right: 0;
    top: 1px;
    text-align: center;
  }

  html.page-site-home #ksh--header--submitroom
  {
    display: inline-block;
    position: relative;
    z-index: 10;
    top: 0;
    margin-top: 6px;
  }

  .ksh--header--content
  {
    padding: 69px 20px 64px 20px;
  }

  .ksh--header--slogan
  {
    font: 700 36px/38px "Univers Next W02";
  }

  .ksh--header--slogan
  {
    text-transform: uppercase;
  }

  .ksh--header--logo
  {
    display: none;
  }

  .ksh--header--counter
  {
    font: 500 22px "Univers Next W02";
  }

  .ksh--header--filters
  {
    margin-top: 55px;
  }

    .ksh--header--filters button
  , .ksh--header--filters select
  , .ksh--header--filters .ui-selectmenu-button
  {
    height: 50px;
  }

  .ksh--header--filters .ui-selectmenu-button .ui-icon
  {
    line-height: 28px;
  }

  .ksh--header--filters select
, .ksh--header--filters .ui-selectmenu-button
  {
    margin-bottom: 5px;
  }

  .ksh--header--filters .ctabutton
  {
    width: 100%;
  }
}

/* DESKTOP / TABLET */
@media only screen and (min-width: 750px)
{
  html.kamersite--home .ks--homecontent.pad_h
  {
    padding-left: 0;
    padding-right: 0;
  }

  .ks--imageheader
  {
    padding-top: 50px;
  }

  .ksh--header--content
  {
    height: calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ksh--header--logo
  {
    display: block;
    height: 24px;
  }

  .ksh--header--slogan
  {
    font: 500 80px/72px "Univers Next W02";
    text-transform: uppercase;

    margin-top: 27px;
  }

  .ksh--header--counter
  {
    font: normal 24px "Univers Next W02";
  }

  .ksh--header--filters
  {
    display: flex;

    margin-top: 70px;
  }
    .ksh--header--filters select
  , .ksh--header--filters .ui-selectmenu-button
  {
    flex: 1;
  }

    .ksh--header--filters button
  , .ksh--header--filters select
  , .ksh--header--filters .ui-selectmenu-button
  {
    height: 60px;
  }
/*
  .ksh--header--filters:first-child
  {
    border-top-left-radius: 41px;
    border-bottom-left-radius: 41px;
  }
*/
  .ksh--header--filters .ctabutton
  {
    /*
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    */
    border-radius: 0;
  }
}
