/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
.room__header {
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
  height: 100%;
}

.room__header__houseplate,
.room__header__costsandcontact,
.room__header__slidescount {
  grid-row: 1;
}
@media (max-width: 1023px) {
  .room__header__houseplate,
  .room__header__costsandcontact,
  .room__header__slidescount {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) {
  .room__header__houseplate,
  .room__header__costsandcontact,
  .room__header__slidescount {
    grid-column: 7/span 19;
  }
}

.room__header__houseplate {
  align-self: center;
}

.room__header__slidescount {
  align-self: end;
}

.room__header .room__header__slidescount {
  cursor: pointer;
}

.room__header__houseplate {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 750px) {
  /*
    .room__header__houseplate
    {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  */
  .room__header__housename {
    font: 700 48px "Univers Next W02";
    text-transform: uppercase;
  }
  .room__header__location {
    font: 500 24px "Univers Next W02";
  }
  /*
    .room__header__costs
    {
      position: absolute;
      right: 0;
      bottom: 95px;
    }
  */
  .room__header__rent {
    font: 500 48px "Univers Next W02";
  }
  .room__header__servicecosts {
    font: 300 24px "Univers Next W02";
  }
  /*
      .room__header__costs
    , .room__header__contactbutton
    {
      position: absolute;
      width: 280px;
      right: 0;

      padding-left: 40px;
    }

    .room__header__contactbutton
    {
      bottom: 0;
    }
  */
  .room__header__slidescount {
    /*
        position: absolute;
        left: 0;
        bottom: 0px;
    */
    height: 40px;
    line-height: 40px;
    color: #FFFFFF;
    font: normal 24px Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }
  .room__header__costsandcontact {
    grid-column: 21/span 6;
    align-self: end;
  }
  .room__header__contactbutton.room__header__contactbutton {
    margin-top: 30px;
    width: 100% !important;
    border-radius: 0;
    width: 250px;
  }
}
/* MOBILE */
@media only screen and (max-width: 749px) {
  .room__header__houseplate {
    /*
        position: absolute;
        width: 100%;
        height: calc(100% - 53px); / * vert-align content within the area above the contact button * /

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    */
    justify-self: center;
  }
  .room__header__housename {
    font: 700 35px "Univers Next W02";
    text-transform: uppercase;
  }
  .room__header__location {
    font: 500 24px "Univers Next W02";
  }
  .room__header__costs,
  .room__header__contactbutton.ctabutton {
    height: 53px;
  }
  /*
    .room__header__costs
    {
      position: absolute;
      left: 40px;
      bottom: 0px;
    }
  */
  .room__header__rent {
    font: 500 30px/100% "Univers Next W02";
  }
  .room__header__servicecosts {
    font: 300 15px/100% "Univers Next W02";
  }
  .room__header__contactbutton.ctabutton {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60vw;
    font: 500 18px/24px "Univers Next W02"; /* fs_h2 !!! */
    line-height: 53px;
    white-space: nowrap;
  }
  .room__header__slidescount {
    display: none;
  }
  .room__header__costsandcontact {
    display: contents;
  }
  .room__header__costs {
    grid-column: 3/span 5;
    grid-row: 1;
    align-self: end;
  }
  .room__header__costsandcontact {
    align-self: end;
    justify-self: end;
  }
  .room__header__contactbutton.room__header__contactbutton {
    border-radius: 0;
  }
}