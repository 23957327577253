/*

            Virtual pixels
iPad      - 1024 x 768
onze Asus - 1280 x 800



$sidepadding_column: 16px;
$sidepadding:        32px;
$sidepadding_mobile: 15px;

$panel_padding_v:    35px; / * was 64px before * /

$contentarea_width_default:  980px; / * columns + gaps + sidepaddings * /
$contentarea_width_wide:    1360px;

*/





*
{
  box-sizing: border-box;
}

html
{
  background-color: #f7f8f9;
}

body
{
}

main
{
  display: block; /* IE doesn't know this as block component, so if we don't explicitly say it's block the margin will be ignored */
}

.show_if_logged_in
{
  display: none;
}
.show_if_not_logged_in
{
  display: block;
}



.site-fullwidth > .site-centered
{
  background-color: transparent;
}



/* richcontent at any level in the DOM must be forced to keep
   margins within.
*/
.rtdcontent-notembeddedobject
{
  padding-top: 1px;
  padding-bottom: 1px;
}

/*
- .whitepanel's at toplevel are mostly meant to wrap richdocument content such a <p>, <ol>, <ul>, ...
- .whitepanel at a lower lever is to be ignored, because it content within should have no own background unless explicitly defined.
*/
  .document-toplevel.whitepanel
, .document-toplevel > .whitepanel
{
  background-color: #FFFFFF;
  color: #000000;
}
  .rtdcontent-notembeddedobject.document-toplevel.whitepanel
, .rtdcontent-notembeddedobject.document-toplevel > .whitepanel
{
  overflow: auto; /* keep margins inside */
}

.document-toplevel.rtdcontent-mainflow.whitepanel /* usually has content directly from the richdocument (p/ol/ul/h1/h2/h3/h4) */
{
  padding-top: 32px;
  padding-bottom: 32px;
}



/*.document-toplevel.rtdcontent:not(.embeddedobject-container)*/
.document-toplevel.rtdcontent-notembeddedobject
{
  padding-top: 32px;
}





.site-centered-top
{
  width: 100%;
  max-width: 980px; /* $contentarea_width_default */
}


/* 940 + some spacing because of horizontal scrollbar */
@media only screen and (min-width: 1000px) /* $contentarea_width_default + 20px */
{
/*
    body > .site-centered
  , .document-toplevel.site-centered
  , .document-toplevel > .site-centered
  , .document-toplevel > .site-fullwidth > .site-centered
  , .document-toplevel > .wh-rtd-embeddedobject > .embeddedobject.site-centered
  , .site-centered-top / * content to which we always apply (outside the richdocuments) * /
  , #site-search-form.site-centered
  {
    max-width: 980px; / * $contentarea_width_default * /
    margin-left: auto;
    margin-right: auto;
  }

    main > .embeddedobject.site-fullwidth > .site-centered
  , .carousel-cell-meta.site-centered
  , .site-fullwidth.keep_out_of_borders
  {
    max-width: 980px; / * $contentarea_width_default * /
  }

    .site-centered-wide
  , #site-mainmenu__content
  , #header-top__content
  , #site-pageslideshow
  {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
  }



  / * wrapper for any content which expands into the border * /
  .site-fullwidth
  {
    / * container for 'expand bg into borders' and 'expand content into borders' * /
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  .site-fullwidth.keep_out_of_borders
  {
    / * this element is not allowed to expand into the borders (the submenu needs this space)
       so make it behave like a 'wide' content element
    * /
    margin-left: auto;
    margin-right: auto;
  }
*/
}

@media (max-width: 767px)
{
  .rtdcontent-threecolumn .column
  {
    margin-top: 15px;
  }
}






/*
Used by:
- #site-pageslideshow
- the video widget
*/
.playvideobutton
{
  display: flex;
  align-items: center;
  justify-content: center;


  border: 6px solid #FFFFFF;
  border-radius: 50%;
  text-align: center;

  opacity: 0.75;
  background-color: rgba(0, 0, 0, 0.35);

  color: #FFFFFF;

  cursor: pointer;
}

.playvideobutton:hover
{
  background-color: #FFFFFF;
  opacity: 1;
}
.playvideobutton:hover .fas
{
  color: #000000;
}

@media only screen and (max-width: 939px)
{
  .playvideobutton
  {
    width: 55px;
    height: 55px;
    border-width: 4px;
    padding: 20px;
  }

  .playvideobutton .fas
  {
    font-size: 28px;
    line-height: 26px;
    margin-left: 4px;
  }
}

@media only screen and (min-width: 940px)
{
  .playvideobutton
  {
    width: 80px;
    height: 80px;
    border-width: 6px;
    padding: 11px;
  }

  .playvideobutton .fas
  {
    font-size: 35px;
    line-height: 31px;
    margin-left: 4px;
  }
}

