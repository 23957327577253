/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
.widget-news.widget-news {
  grid-column: 1/span 31;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
  /*
    &.widget--background-lightgrey
    {
      background-color: $color-lightgrey;
      padding-top:      $panels-padding-top;
      padding-bottom:   $panels-padding-bottom;
    }

      &.widget--background-darkblue
    , &.widget--background-orange
    , &.widget--background-red
    {
      p, ol, ul, h1, h2, h3 { color: #FFFFFF; }
      padding-top:      $panels-padding-top;
      padding-bottom:   $panels-padding-bottom;
    }

    &.widget--background-darkblue     { background-color: $color-darkblue; }
    &.widget--background-orange       { background-color: $color-orange; }
    &.widget--background-red          { background-color: $color-red; }
  */
}
.widget-news.widget-news.widget--background {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-top: var(--panels-padding-top);
  padding-bottom: var(--panels-padding-bottom);
}

.newswidget__title {
  grid-column: 3/30;
}

a.newswidget__item:hover {
  text-decoration: none;
}

a.newswidget__item:hover .newswidget__title {
  text-decoration: underline;
}

a.newswidget__showmorelink {
  display: inline-flex;
  margin-top: 15px;
  font: 300 18px/21px "Univers Next W02";
  text-transform: uppercase;
  text-decoration: none;
}

a.newswidget__showmorelink::before {
  content: "\f105";
  margin-right: 15px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
}

a.newswidget__showmorelink:hover {
  text-decoration: underline;
}

.widget__previewimages--slideshow .widget__previewimage {
  opacity: 0;
  transition: opacity 0.25s;
  pointer-events: none;
}
.widget__previewimages--slideshow .widget__previewimage.activeslide {
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 767px) {
  .widget__previewimages--empty {
    display: none;
  }
  .widget__previewimages,
  .newswidget__summary {
    grid-column: 3/30;
  }
  .widget__previewimage {
    display: none;
  }
  .widget__previewimage {
    grid-column: 3/30;
    grid-row: 2;
    display: block;
    padding-top: 66.6666666667%;
    background-size: cover;
  }
  a.newswidget__item {
    display: block;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  a.newswidget__item {
    padding: 12px 0;
  }
  .newswidget__item__date {
    font: 300 16px "Univers Next W02";
    text-transform: uppercase;
  }
  .newswidget__item__title {
    font: 500 19px/22px "Univers Next W02";
    text-transform: uppercase;
  }
  .newswidget__item:first-child .newswidget__item__title {
    font: 500 22px/26px "Univers Next W02";
    text-transform: none;
  }
  .newswidget__item:first-child .newswidget__item__description {
    font: 500 16px/22px Lato;
  }
  .widget-news + .widget-news .newswidget__summary {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .widget__previewimage,
  .newswidget__summary {
    grid-row: 2;
  }
  .widget__previewimage--empty {
    background-color: rgba(0, 0, 0, 0.15);
  }
  .widget__previewimage {
    grid-column: 3/16;
    align-items: start;
  }
  .widget-news .widget__previewimage,
  .widget-news + .widget-news + .widget-news .widget__previewimage,
  .widget-news + .widget-news + .widget-news + .widget-news + .widget-news .widget__previewimage,
  .widget-news + .widget-news + .widget-news + .widget-news + .widget-news + .widget-news + .widget-news .widget__previewimage {
    grid-column: 3/16;
  }
  .widget-news .newswidget__summary,
  .widget-news + .widget-news + .widget-news .newswidget__summary,
  .widget-news + .widget-news + .widget-news + .widget-news + .widget-news .newswidget__summary,
  .widget-news + .widget-news + .widget-news + .widget-news + .widget-news + .widget-news + .widget-news .newswidget__summary {
    grid-column: 17/30;
    text-align: left;
  }
  .widget-news + .widget-news .newswidget__title,
  .widget-news + .widget-news + .widget-news + .widget-news .newswidget__title,
  .widget-news + .widget-news + .widget-news + .widget-news + .widget-news + .widget-news .newswidget__title {
    grid-column: 17/30;
  }
  .widget-news + .widget-news .widget__previewimage,
  .widget-news + .widget-news + .widget-news + .widget-news .widget__previewimage,
  .widget-news + .widget-news + .widget-news + .widget-news + .widget-news + .widget-news .widget__previewimage {
    grid-column: 17/30;
  }
  .widget-news + .widget-news .newswidget__summary,
  .widget-news + .widget-news + .widget-news + .widget-news .newswidget__summary,
  .widget-news + .widget-news + .widget-news + .widget-news + .widget-news + .widget-news .newswidget__summary {
    grid-column: 3/16;
    text-align: right;
  }
  /*
    .widget__previewimages
    {
      display: flex;
    }
    .widget__previewimage
    {
      flex: 0 0 100%;
    }
    .widget__previewimages
    {
      overflow-x: scroll;

      // Carrousel style scrolling for iOS11, SF11+, Chr69+, Android Browser 67
      // Not on Firefox, IE, Edge yet, but this is meant for mobiles...
      scroll-snap-type: x mandatory; // proximity
      -webkit-overflow-scrolling: touch; // without this snappoints don't seem to work correctly on iOS

      // Hide the scrollbar
      overflow: -moz-scrollbars-none; // old Firefox up till 63
      scrollbar-width: none; // Firefox 64+ (dec 2018)
    }
    .widget__previewimages::-webkit-scrollbar
    {
      display: none;
    }
    .widget__previewimage
    {
      scroll-snap-align: start;      // Firefox 68+ (jul 2019), Chrome 69 (sep 2018), SF 11 (sep 2017), iOS 11
    }
  */
  .widget__previewimage {
    display: block;
    padding-top: 66.6666666667%;
    background-size: cover;
  }
  a.newswidget__item {
    display: block;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 15px 0;
  }
  a.newswidget__item:first-child {
    padding-top: 0;
  }
  .newswidget__item__date {
    font: 300 16px "Univers Next W02";
    text-transform: uppercase;
  }
  .newswidget__item__title {
    font: 500 20px/24px "Univers Next W02";
  }
  .newswidget__item:first-child .newswidget__item__title {
    font: 500 36px/42px "Univers Next W02";
  }
  .newswidget__item:first-child .newswidget__item__description {
    font: 500 16px/24px Lato;
  }
}