.sidebar__mainmenu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
}

.sidebar__mainmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button.sidemainmenu__item__link {
  background: none;
  appearance: none;
  border: none;
  width: 100%;
  text-align: left;
}

/*
By default we hide level 3 and 4, but using the toggles they can be expanded.
DON'T use display: none; because that way the items won't influence the width of
the pulldown until opened causing the pulldown to get wider (and reposition if it's centered).
*/
.sidebar__menu--level2,
.sidebar__menu--level3,
.sidebar__menu--level4 {
  height: 0;
  overflow: hidden;
  visibility: hidden; /* prevent tabnavigation within hidden content */
}

.sidemainmenu__item--expand > .sidebar__menu--level2,
.sidemainmenu__item--expand > .sidebar__menu--level3,
.sidemainmenu__item--expand > .sidebar__menu--level4 {
  height: auto;
  overflow: visible;
  visibility: visible;
}

.sidebar__menu--level3 {
  padding-top: 5px;
}

.sidemainmenu-level1__itemlink,
.sidemainmenu-level2__itemlink,
.sidemainmenu-level3__itemlink {
  display: block;
  position: relative;
  cursor: pointer;
}

.sidemainmenu-level1__itemlink:hover,
.sidemainmenu-level2__itemlink:hover,
.sidemainmenu-level3__itemlink:hover,
.sidemainmenu-level4__itemlink:hover {
  background: var(--sidebar-menu-itemhover-background);
  color: var(--sidebar-menu-itemhover-textcolor);
  text-decoration: var(--sidebar-menu-itemhover-textdecoration);
}

.sidemainmenu__item__toggle {
  position: absolute;
  left: 0;
  cursor: pointer;
}

.sidemainmenu__item__toggle:hover::before {
  color: inherit;
}

.sidemainmenu__item__toggle::before {
  content: "";
  width: var(--sidemainmenu-toggler-width);
  flex: none;
  font-size: var(--sidebar-menu-toggle-fontsize);
  color: var(--sidebar-menu-toggle-color);
}

/*
.sidebar__menu--level1 .sidemainmenu__item__toggle { outline: 1px solid #FF0000; }
.sidebar__menu--level2 .sidemainmenu__item__toggle { outline: 1px solid #00FF00; }
*/
.sidebar__menu--level1 .sidemainmenu__item__toggle::before {
  padding-left: 3px;
  font: var(--sidebar-menu-level1-toggleicon-font);
  font-size: var(--sidebar-menu-toggle-fontsize);
  content: var(--sidebar-menu-level1-toggleicon-collapsed-content);
}
.sidebar__menu--level1 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  padding-left: 0;
  content: var(--sidebar-menu-level1-toggleicon-expanded-content);
}

.sidebar__menu--level2 .sidemainmenu__item__toggle::before {
  padding-left: 3px;
  font: var(--sidebar-menu-level2-toggleicon-font);
  font-size: var(--sidebar-menu-toggle-fontsize);
  content: var(--sidebar-menu-level2-toggleicon-collapsed-content);
}
.sidebar__menu--level2 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  padding-left: 0;
  content: var(--sidebar-menu-level2-toggleicon-expanded-content);
}

.sidebar__menu--level3 .sidemainmenu__item__toggle::before {
  padding-left: 3px;
  font: var(--sidebar-menu-level3-toggleicon-font);
  font-size: var(--sidebar-menu-toggle-fontsize);
  content: var(--sidebar-menu-level3-toggleicon-collapsed-content);
}
.sidebar__menu--level3 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  padding-left: 0;
  content: var(--sidebar-menu-level3-toggleicon-expanded-content);
}

.sidemainmenu-level1__itemlink {
  color: var(--sidebar-menu-level1-textcolor);
  font: var(--sidebar-menu-level1-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level1-padding);
}

.sidemainmenu-level2__itemlink {
  color: var(--sidebar-menu-level2-textcolor);
  font: var(--sidebar-menu-level2-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level2-padding);
}

.sidemainmenu-level3__itemlink {
  color: var(--sidebar-menu-level3-textcolor);
  font: var(--sidebar-menu-level3-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level3-padding);
}

.sidemainmenu-level4__itemlink {
  color: var(--sidebar-menu-level4-textcolor);
  font: var(--sidebar-menu-level4-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level4-padding);
}