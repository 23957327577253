
html
{

  --color-error:           #EE0000; /* lightest accessible red, 4.53:1 contrast on white */
  --color-grey-border:     #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-text-small: #767677; /* WCAG 4.53:1 contrastratio */

  --sitethemecolor:       #5078b4; /* FALLBACK */

  --color-theme: var(--sitethemecolor);
  --formcontrols-themecolor: var(--sitethemecolor);

  /************************************************************
   *
   *  Form layout
   */

  --formlayout-label-width: 200px;
  /*--formlayout-label-font:  17px var(--rtd-text-fontfamily); */ /* 21px for 4TU */
  --formlayout-label-font:    var(--rtd-text-font);
  --formlayout-label-spacing: 25px;

  --formlayout-fieldgroup-bgcolor-error: #FDE6E8;

  --formlayout-fieldgroup-padding: 11px 16px;
  --formlayout-fieldgroup-spacing: 0px;

  --formlayout-fieldgroup-error-textcolor: var(--color-salmon-formcontrols-error-border);
  --formlayout-fieldgroup-error-font: var(--formlayout-label-font);

  --formlayout-buttongroup-margin: 20px 0 0 0;


  /************************************************************
   *
   *  Form controls (textual fields)
   */

  /* Normal height for single-line controls */
  --formcontrols-height:             44px;
  --formcontrols-radius:              3px;

  --formcontrols-focus-boxshadow:       0 0 0 3px #540A5130;
  --formcontrols-focus-outline:         2px solid #0000AA;
  --formcontrols-focus-outline-offset:  3px;

  --formcontrols-border-width:          1px;
  --formcontrols-border-width-focus:    2px;

  --formcontrols-border-color:          #333333;
  --formcontrols-border-color-disabled: #BFBFBF;
  --formcontrols-border-color-error:    var(--color-salmon-formcontrols-error-border);
  --formcontrols-border-color-focus:    var(--color-purple-dark);

  --formcontrols-textcolor-error:       #121212; /* we make the area around it red and keep the text black */

  --formcontrols-backgroundcolor-error: #FFFFFF;



  --formcontrol-checkradio-label-color: var(--rtd-text-color);


  /* NOTE: if the font-size is below 16px iOS will zoom into the control when it recieves focus */
  --textcontrols-font:                 19px var(--rtd-text-fontfamily);
  --textcontrols_placeholdercolor:     #767676;
  --textcontrols-bgcolor:              #FFFFFF;
  --textcontrols_textcolor:            #333333;

  --textcontrols_disabled_bgcolor:     #F5F5F5;
 /*--textcontrols_disabled_bordercolor: #989898;*/
  --textcontrols_disabled_placeholder: #767676;
  --textcontrols_disabled_textcolor:   #767676;

  --textcontrols_padleft:         15px;
  --textcontrols_padright:        15px;
  --textcontrols_padleft-mobile:  10px;
  --textcontrols_padright-mobile: 10px;



/* FIXME:  these are still partly in sass */;
  --controls_textcolor_error:        #EE0000; /* lightest red which passes accessibility tests (4.53:1 contrast on white) */


  --controls-icon-color: var(--color-theme);


    /************************************************************
     *
     *  Form controls (default icons)
     */

    --formcontrol-icon-delete-font: 400 18px 'Font Awesome 5 Pro';
    --formcontrol-icon-delete-icon: var(--fa-trash-alt);


    /************************************************************
     *
     *  Form controls (checkbox and radio buttons)
     */

/*
ACOI
      /* Shared checkbox & radiobutton settings */
      --formcontrol-checkradio-size:                24px;
      --formcontrol-checkradio-bordercolor:         var(--color-grey-border);
      --formcontrol-checkradio-borderwidth:         1px;
      --formcontrol-checkradio-borderradius:        1px;

      /* Hover */
      /* Color for subtly showing the checkmark/bullet symbol upon hover */
      --formcontrol-checkradio-hover-color:         #F0F1F2;
      --formcontrol-checkradio-hover-bgcolor:       #FFFFFF;
      --formcontrol-checkradio-hover-bordercolor:   var(--formcontrols-themecolor);

      /* Checked radio button (white background) */
      --formcontrol-checkradio-checked-color:       #FFFFFF;
      --formcontrol-checkradio-checked-bgcolor:     var(--formcontrols-themecolor);
      --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
      --formcontrol-radio-checked-color:            var(--formcontrols-themecolor);


      /************************************************************
       *
       *  Form controls (checkbox)
       */

      --formcontrol-check-font: 400 17px/24px var(--fontawesome);
      --formcontrol-check-content: '\f00c'; /* checkmark in FA5 */
      /*
      --formcontrol-check-content: "\e931"; UT-icon-UT_icon_50_check-16px
      --formcontrol-check-content: "✓"; // checkmark
      --formcontrol-check-content: "✗"; // ballot X
      --formcontrol-check-content: "✕"; // multiplication
      */

      --formcontrol-check-shift:                        0;

      /* Disabled checkbox */
      --formcontrol-check-disabled-bgcolor:             #F2F2F2;
      --formcontrol-check-disabled-bordercolor:         #BBBBBB;
      --formcontrol-check-disabled-iconcolor:           transparent;

      --formcontrol-check-disabled-checked-bgcolor:     #F2F2F2;
      --formcontrol-check-disabled-checked-bordercolor: #BBBBBB;
      --formcontrol-check-disabled-checked-iconcolor:   #BBBBBB;


      /************************************************************
       *
       *  Form controls (radio)
       */

      --formcontrol-radio-shift:                        0px;
      --formcontrol-radio-innersize:                    14px;

      --formcontrol-radio-disabled-bgcolor:             #F2F2F2;
      --formcontrol-radio-disabled-bordercolor:         #BBBBBB;
      --formcontrol-radio-disabled-iconcolor:           transparent;

      --formcontrol-radio-disabled-checked-bordercolor: #BBBBBB;
      --formcontrol-radio-disabled-checked-bgcolor:     #BBBBBB;
      --formcontrol-radio-disabled-checked-iconcolor:   #BBBBBB;

      /* Focus */
      --formcontrol-checkradio-focus-bordercolor:   #FFFFFF; /* create contrast to the box shadow */
      --formcontrol-checkradio-focus-boxshadow:     0 0 2px 3px rgb(44, 108, 214);



      /************************************************************
       *
       *  Form controls (checkbox / iOS switch style
       */

      /*
      4TU
      --component-checkbox-switch-borderwidth:   3px;
      --component-checkbox-switch-track-width:  55px;
      --component-checkbox-switch-track-height: 24px;
      --component-checkbox-switch-track-color:  #A4A4A4;
      --component-checkbox-switch-track-color-disabled: #A4A4A4;
      --component-checkbox-switch-track-color-active: var(--color-orange);
      --component-checkbox-switch-thumb-size:   18px;
      --component-checkbox-switch-thumb-color:  #FFFFFF;
      */

      --component-checkbox-switch-borderwidth:  4px;
      --component-checkbox-switch-track-width:  75px;
      --component-checkbox-switch-track-height: 35px;
      --component-checkbox-switch-track-color:  #A4A4A4;
      --component-checkbox-switch-track-color-disabled: #A4A4A4;
      --component-checkbox-switch-track-color-active: var(--color-purple);
      --component-checkbox-switch-thumb-size:   27px;
      --component-checkbox-switch-thumb-color:  #FFFFFF;



}
