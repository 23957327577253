/*************************************************************************************

Styling important for generic carrousel functionality

*************************************************************************************/

/* FIXME: rename to viewport?? */
.carrousel__viewport
{
  -webkit-tap-highlight-color: transparent; /* iOS Safari */

  width: 100%;
  overflow: hidden;
  position: relative;

  cursor: -webkit-grab; /* Safari < 9 */
  cursor: grab;

  /* prevent selection.
     We cannot use preventDefault because touch devices must be able to
     pick up the touch events to initiate a vertical scroll.
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* when the carrousel is foccused it has keyboard navigation */
.carrousel__viewport:focus
{
  outline: 0;
}

.carrousel.previewmode .carrousel__viewport
{
  white-space: nowrap;

  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.carrousel.livemode .carrousel__viewport
{
  overflow: hidden;
}

.carrousel.livemode .carrousel__cell
{
  position: absolute;
  left: 0;
  top: 0;
  -webkit-backface-visibility: hidden;
}

.carrousel.previewmode .carrousel__cell
{
  display: inline-block;
  vertical-align: top;

  white-space: normal; /* counteract white-space: nowrap; for previewmode css in backend */
}

.carrousel.previewmode .carrousel__cell + .carrousel__cell
{
  margin-left: 15px;
}



/*************************************************************************************

WS2016 specific styling

*************************************************************************************/

.carrouselwidget
{
  background-color: #EEEEEE;
  width: 100%;
  -webkit-tap-highlight-color: transparent; /* iOS Safari */
}

.carrouselwidget-title
{
  color: #000000;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.carrouselwidget--small
{
  padding: 20px 0;
}
.carrouselwidget--small .carrousel__previous
{
  position: absolute;
  right: 81px;
  bottom: 20px;
}
.carrouselwidget--small .carrousel__next
{
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.carrouselwidget--large
{
  padding: 50px 0;
}
.carrouselwidget--large .carrousel__previous
{
  position: absolute;
  right: 101px;
  bottom: 20px;
}
.carrouselwidget--large .carrousel__next
{
  position: absolute;
  right: 40px;
  bottom: 20px;
}

@media (max-width: 750px)
{
    .carrouselwidget .carrousel__previous
  , .carrouselwidget .carrousel__next
  {
    display: none;
  }
}
@media (min-width: 751px)
{
  .carrouselwidget--small
  {
    min-height: 339px; /* so the prev/next buttons can never overlay the images */
  }
  .carrouselwidget--large
  {
    min-height: 598px;
  }
}




  .carrouselwidget .carrousel__previous
, .carrouselwidget .carrousel__next
{
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #1f2329;

  text-align: center;
  line-height: 67px;

  transition: background-color 0.50s;

  cursor: pointer;

  -webkit-transform: translate3D(0,0,0);
  -webkit-backface-visibility: hidden;
}
  .carrouselwidget .carrousel__previous > svg
, .carrouselwidget .carrousel__next > svg
{
  fill: #FFFFFF;
  width: 18px;
  height: 18px;
}
  .carrouselwidget .carrousel__previous:hover
, .carrouselwidget .carrousel__next:hover
{
  background-color: #555555;

  transition: background-color 0.25s;
}





.carrouselwidget .carrousel__cell
{
  max-width: calc(100vw - 67px);
}

.carrousel__cell .carrousel__cell-image
{
  position: relative; /* to position the title within */

  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.carrousel__cell-title
{
  position: absolute;
  left: 0px;
  bottom: 0px;
  max-width: 100%;

  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;

  padding: 0 20px 18px 20px;
}

.carrousel__cell-description
{
  max-width: 100%;
  padding-top: 18px;
}

.carrousel__cell-description > p
{
  padding-left: 20px;
  padding-right: 20px;
}

.carrousel__cell-description p:first-child
{
  margin-top: 0;
}

.carrouselwidget .carrousel__cell .carrousel__cell-description
{
  opacity: 0;

  transition: opacity 0.25s;
}
.carrouselwidget .carrousel__cell--aligned .carrousel__cell-description
{
  opacity: 1;

  transition: opacity 0.25s;
}

.carrousel__cell-playbutton
{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;

  width: 40px;
  height: 40px;

  background-color: #FFFFFF;
  line-height: 56px;
  text-align: center;

  transform: scale(1);
  transition: transform 0.25s, opacity 0.25s;
}
.carrousel__cell-playbutton > svg
{
  color: #aaacae;
  width: 26px;
  height: 26px;
}
.carrousel__cell-playbutton:hover > svg
{
  color: #444444;
}

.carrousel__cell .videocontainer
{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;

  /* make a kind of theater (a place to focus on the video,
     rather than having the video sticking/blending to the page
  padding: 10px 80px;
  */
  background-color: #000000;
}


.carrousel__cell .video-close
{
  position: absolute;
  right: 25px;
  top: 25px;

  width: 18px;
  height: 18px;

  background-image: url("../web/img/close-white.svg");
  background-size: cover;

  cursor: pointer;
}

  .carrousel__cell .videocontainer
, .carrousel__cell .video-close
{
  visibility: hidden;
  z-index: -1;
  pointer-events: none;

  opacity: 0;
}
  .carrousel__cell.active .videocontainer
, .carrousel__cell.active:hover .video-close
{
  transition: opacity 1.50s;

  visibility: visible;
  z-index: 0;
  pointer-events: auto;

  opacity: 1;
}
.carrousel__cell.active:hover .video-close
{
  transition: opacity 0.5s;
}

.carrousel__cell.active .carrousel__cell-playbutton
{
  transform: scale(0);
  opacity: 0;
}
