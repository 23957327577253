.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldfilename {
  padding-right: 36px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  color: white;
  padding: 3px 6px 3px 3px;
  font-size: 70%;
  background-color: transparent;
  height: 100%;
  align-items: center;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  color: #4A4A4A;
  border-radius: 50%;
  font-size: 20px;
  font: var(--fontawesome-solid);
  content: "\f057";
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex-grow: 100;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: 15px;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(26, 125, 215, 0.6) 16.67%, transparent 16.67%, transparent 50%, rgba(26, 125, 215, 0.6) 50%, rgba(26, 125, 215, 0.6) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}
.wh-form.wh-styledinput .wh-form__imgedit {
  width: 150px;
  height: 200px;
  padding: 0;
  flex: none;
  border: 1px solid var(--formcontrols-border-color);
}
.wh-form.wh-styledinput .wh-form__imgedit:hover::after {
  color: var(--color-theme);
}
.wh-form.wh-styledinput .wh-form__imgedit::after {
  display: flex;
  opacity: 1; /* override the neutral styling */
  font: var(--fontawesome-solid);
  content: "\f382"; /* cloud */
  font-size: 33px;
  align-items: center;
  justify-content: center;
  background-image: none;
  padding-bottom: 40px;
  color: #4A4A4A;
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditdelete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditdelete::before {
  width: 20px;
  height: 20px;
  color: #4A4A4A;
  border-radius: 50%;
  background-color: #fff;
  font: var(--fontawesome-solid);
  content: "\f057";
  font-size: 20px;
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditholder::before {
  display: block;
  position: absolute;
  text-align: center;
  font-size: 15px;
  color: var(--rtd-text-color);
  bottom: 0;
  left: 0;
  padding: 20px;
  content: "Drop or upload an image";
}
.wh-form.wh-styledinput .wh-form__imgedit--hasimage::after,
.wh-form.wh-styledinput .wh-form__imgedit--hasimage .wh-form__imgeditholder::before {
  display: none;
}
.wh-form.wh-styledinput .wh-form__imgedit[data-wh-form-disabled]::after {
  opacity: 0.2;
}
.wh-form.wh-styledinput .wh-form__imgedit[data-wh-form-disabled] .wh-form__imgeditholder {
  display: none;
}
.wh-form.wh-styledinput .wh-form__imgeditholder::after {
  opacity: 1;
  z-index: 1;
  display: inline-block;
  position: absolute;
  display: none;
  width: 80px;
  top: 50%;
  left: 50%;
  height: 80px;
  font-size: 80px;
  line-height: 80px;
  font: var(--fontawesome-solid);
  content: "\f1ce"; /* cloud */
  color: #4A4A4A;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgedit::after,
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}