#site-crumbpath {
  display: flex;
  align-items: center;
  position: relative;
  left: -7px;
  font: 14px Lato;
  margin-bottom: 25px;
}

#site-crumbpath a,
#site-crumbpath span {
  display: inline-block;
  vertical-align: middle;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 7px;
  padding-right: 7px;
  color: #6A6A6A;
  font-size: inherit;
  text-decoration: none;
}

#site-crumbpath a:last-child,
#site-crumbpath span:last-child {
  color: #6A6A6A;
}