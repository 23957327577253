/*
tc-orange-darkhover



Normal  / Hover   / Down    / CTAButton / CTAButton-hover
RED: #f04664 / #fa516d / #e13859 / #8a1a33   / #bb2b49
*/
html, body {
  scroll-behavior: smooth;
}

@media (min-width: 940px) {
  html.buddy--home .rtdcontent-mainflow > h1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.sitecontainer--homecta {
  position: relative;
  padding-top: 50px;
  padding-bottom: 80px;
  background-color: #808080;
  color: #FFFFFF;
  text-align: center;
  font: 300 16px/32px "Univers Next W02";
  letter-spacing: 0.5px;
}

@media (min-width: 768px) {
  .sitecontainer--homecta {
    min-height: calc(100vh - 155px);
  }
}
[name=categories] {
  position: relative;
  top: -125px;
}

.sitecontainer--homecta {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../web/img/buddy-home-background.jpg");
  background-size: cover;
}

@media (max-width: 767px) {
  .abovefoldarrow {
    display: none;
  }
}
@media (min-width: 768px) {
  .abovefoldarrow {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -33px;
    bottom: 20px;
    z-index: 100;
    font-size: 75px;
    color: #FFFFFF;
    animation: floating-arrow 1.6s infinite ease-in-out 0s;
    /*
    animation: bounce_to_bottom 3s infinite;
    */
  }
  .abovefoldarrow .fa {
    line-height: 28px;
  }
  /*
  @keyframes bounce_to_bottom {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-7px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  */
}
@keyframes floating-arrow {
  from {
    transform: translateY(0);
  }
  65% {
    transform: translateY(11px);
  }
  to {
    transform: translateY(0);
  }
}
@media (max-width: 970px) {
  .buddyoptions__item,
  .buddygrid {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.sitecontainer--homecta h1 {
  margin-top: 0;
  font-size: 54px;
  line-height: 65px;
}

@media (min-width: 570px) {
  .sitecontainer--homecta .mobilebreak {
    display: none;
  }
}
@media (max-width: 939px) {
  .sitecontainer--homecta h1 {
    font-size: 32px;
    line-height: 40px;
  }
}
.sitecontainer--homecta .buddyoptions {
  margin-top: 45px;
}

.sitecontainer--categoriesheader {
  background-color: #FFFFFF;
  padding-top: 40px;
  padding-bottom: 25px;
  text-align: center;
}

.sitecontainer--categoriesheader h1 {
  margin: 0 0 10px 0;
  color: #F9690D;
  font: 500 66px/75px "Univers Next W02";
}

@media (max-width: 768px) {
  .sitecontainer--categoriesheader h1 {
    font-size: 32px;
    line-height: 33px;
  }
}
.sitecontainer-categoriesoverview {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #F7F8F9;
}

@media (min-width: 768px) {
  .buddyoptions {
    display: flex;
  }
  .buddyoptions__item + .buddyoptions__item {
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .buddyoptions__item + .buddyoptions__item {
    margin-top: 30px;
  }
}
.buddyoptions__item {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.3333333333% - 10px);
  min-width: 0;
}

a.buddyoptions__item {
  text-decoration: none;
}

.buddyoptions__item__image {
  padding-top: 55%;
  background-color: #F0F0F0;
  background-size: cover;
}

.buddyoptions__item__text {
  position: relative;
  color: #000000;
  font: 500 32px/38px "Univers Next W02";
  padding: 38px 20px 52px 20px;
  text-align: center;
  text-transform: uppercase;
  flex: 1 0 0;
  background-color: #FFFFFF;
}

.buddyoptions__item:first-child .buddyoptions__item__text::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: inline-block;
  /* Font Awesome 5 regular */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  /*
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
  font-size: 40px;
  display: block;
  content: "\f107";
  animation: floating-arrow 1.6s infinite ease-in-out 0s;
}

.buddygrid--categories .buddygrid__item__button .fa {
  font-size: 26px;
  margin-left: 15px;
}

@media (min-width: 768px) {
  .buddygrid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .associationsoverview__topbar {
    padding-left: 0;
    padding-right: 0;
  }
  .buddyoptions__item {
    transition: transform 0.25s;
  }
  .buddyoptions:hover .buddyoptions__item {
    transform: scale(0.9);
  }
  .buddyoptions:hover .buddyoptions__item:hover {
    transform: scale(1.1);
  }
}
@media (max-width: 767px) {
  .buddygrid__item {
    display: block;
  }
  .buddygrid__item + .buddygrid__item {
    margin-top: 30px;
  }
}
.buddygrid::after {
  content: "";
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.3333333333% - 10px);
}

a.buddygrid__item {
  text-decoration: none;
}

@media (min-width: 768px) and (max-width: 899px) {
  .buddygrid__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px);
    min-width: 0;
    margin-bottom: 15px;
  }
}
@media (min-width: 900px) {
  .buddygrid__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.3333333333% - 10px);
    min-width: 0;
    margin-bottom: 15px;
  }
}
.buddygrid__item__main {
  position: relative;
}

.buddygrid__item__image {
  position: relative;
  padding-top: 55%;
  background-color: #F0F0F0;
}

.buddygrid__item__image .theimage {
  background-size: cover;
}

.buddygrid__item__image--fit > .theimage {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-size: container;
  background-repeat: no-repeat;
  background-position: center center;
}

.buddygrid__item__image--fit-noborder > .theimage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: container;
  background-repeat: no-repeat;
  background-position: center center;
}

.buddygrid__item__image--fill > .theimage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
}

.buddygrid--categories .buddygrid__item__text {
  background-color: #FFFFFF;
  color: #000000;
  font: 500 26px/30px "Univers Next W02";
  text-align: center;
  text-transform: uppercase;
  padding: 30px 15px 60px 15px;
}

.buddygrid__item__text .categorytitle {
  font: 500 32px/38px "Univers Next W02";
}

.buddygrid__item__hover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(24, 24, 24, 0.69);
  pointer-events: none;
  opacity: 0;
}

.buddygrid__item:hover .buddygrid__item__hover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(24, 24, 24, 0.69);
  pointer-events: none;
  opacity: 1;
}

.buddygrid__item__hover__content {
  transition: transform 0.5s, opacity 0.5s;
  transform: scale(0);
  transform-origin: 50% 50%;
}

.buddygrid--categories .buddygrid__item__hover__content {
  text-transform: uppercase;
}

.buddygrid__item:hover .buddygrid__item__hover__content {
  transform: scale(1);
}

.buddygrid--categories .buddygrid__item__hover__content {
  text-align: center;
  padding-top: 50px;
  font: 500 22px/30px "Univers Next W02";
}

.buddygrid--categories .buddygrid__item__hover__content .buddycount {
  font: 500 33px/35px "Univers Next W02";
}

.buddyoptions__item__button {
  letter-spacing: 0.5px;
  background-color: #F9690D;
  color: #FFFFFF;
  height: 65px;
  line-height: 65px;
  text-transform: uppercase;
  font: 500 22px/25px "Univers Next W02";
  flex: none;
  flex: none;
}

.buddyoptions__item:hover .buddyoptions__item__button {
  background-color: #E85800;
}

.buddyoptions .buddyoptions__item__button,
.buddygrid--categories .buddygrid__item__button {
  background-color: #F9690D;
  color: #FFFFFF;
  height: 65px;
  line-height: 65px;
  text-transform: uppercase;
  font: 500 22px/25px "Univers Next W02";
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: none;
}

.buddygrid--associations .buddygrid__item__button {
  background-color: #F9690D;
  color: #FFFFFF;
  height: 65px;
  line-height: 65px;
  text-transform: uppercase;
  font: 500 22px/25px "Univers Next W02";
  flex: none;
  overflow: hidden;
}
.buddygrid--associations .buddygrid__item__button > div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.buddygrid__item {
  position: relative;
}

.buddygrid__item__link {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #F00;
}

.buddygrid__item__contents {
  position: relative;
  pointer-events: none;
}

.buddygrid__item__contents a {
  pointer-events: auto;
}