/*

Purple

Normal  / Hover   / Down    / CTAButton / CTAButton-hover
#824b96 / #8c5f96 / #783796 / #4c195f   / #652c7e

*/


  .sitetheme--purple
, .theme--purple
{
  --themecolor:       #824B96;
  --themecolor-dark:  #4C195F; /* also CTAButton color */
  --themecolor-light: #8c5f96; /* hover */
  /* rgba(130, 75, 150, 0.4); */
}

.sitetheme--purple
{
  --sitethemecolor:       #824B96;
  --sitethemecolor-dark:  #4C195F; /* also CTAButton color */
  --sitethemecolor-light: #8c5f96; /* hover */
  /* rgba(130, 75, 150, 0.4); */
}

  .rtdcontent.tc-purple:not(.used_on_dark_background) > p a
, .rtdcontent.tc-purple:not(.used_on_dark_background) > ul a
, .rtdcontent.tc-purple:not(.used_on_dark_background) > ol a
, .rtdcontent.tc-purple:not(.used_on_dark_background) > h1
, .rtdcontent.tc-purple:not(.used_on_dark_background) > h2
, .rtdcontent.tc-purple:not(.used_on_dark_background) > h3
, .rtdcontent.tc-purple:not(.used_on_dark_background) > h4

, .tc-purple .wh-form__richtext > p a
, .tc-purple .wh-form__richtext > ul a
, .tc-purple .wh-form__richtext > ol a
, .tc-purple h1
, .tc-purple h2
, .tc-purple h3
, .tc-purple h4

, .wh-rtd-editor.tc-purple:not(.used_on_dark_background) > p a
, .wh-rtd-editor.tc-purple:not(.used_on_dark_background) > ul a
, .wh-rtd-editor.tc-purple:not(.used_on_dark_background) > ol a
, .wh-rtd-editor.tc-purple:not(.used_on_dark_background) > h1
, .wh-rtd-editor.tc-purple:not(.used_on_dark_background) > h2
, .wh-rtd-editor.tc-purple:not(.used_on_dark_background) > h3
, .wh-rtd-editor.tc-purple:not(.used_on_dark_background) > h4

, .tc-purple-fg-dark.tc-purple-fg-dark
, .tc-purple-fg-dark-ahover a:hover
, .themeclass.tc-purple-apply a

, html.tc-purple .wh-form__grouptitle
{
  color: #824b96;
}




  .tc-purple-bg-dark
, .wh-rtd-editor.tc-purple-bg-dark
{
  background-color: #824b96;
  color: #FFFFFF;
}

.tc-purple-bc-dark
{
  border-color: #824b96;
}

/* allow the dark anchor to work on itself and two levels deeper */
  .tc-purple-ac-dark
, a.tc-purple-ac-dark
, .tc-purple-ac-dark > a
, .tc-purple-ac-dark > * > a
{
  color: #824b96;
}
  .tc-purple-ac-dark:hover
, a.tc-purple-ac-dark:hover
, .tc-purple-ac-dark > a:hover
, .tc-purple-ac-dark > * > a:hover
{
  color: #8c5f96;
}
  .tc-purple-ac-dark:active
, a.tc-purple-ac-dark:active
, .tc-purple-ac-dark > a:active
, .tc-purple-ac-dark > * > a:active
{
  color: #783796;
}

/* buttons (on white) */
  .tc-purple-darkhover
, /*.document-toplevel*/.tc-purple > .embeddedobject-inflow .tc--darkhover /* use 'pagetheme' on buttons at toplevel */
, .blockswidget-item-meta .blockswidget-item-link.tc-purple-darkhover
{
  background-color: #824b96;
  color: #FFFFFF;
  transition: background-color 0.15s;
}
  .tc-purple-darkhover:hover
, .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-purple-darkhover
{
  background-color: #8c5f96;
}
.tc-purple-darkhover:active
{
  background-color: #783796;
}
.tc-purple-darkhover:focus
{
  outline: 0;
  box-shadow: 1.5px 1.5px 3px rgba(0,0,0,0.4);
}


/* buttons widget (on a dark background) */
  .used_on_dark_background .buttonwidget .tc-purple-darkhover
, .tc-purple-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover
, .used_on_dark_background .blockswidget-metaunder .blockswidget-item-link.tc-purple-darkhover
{
  background-color: #4c195f;
  color: #FFFFFF;
}
  .used_on_dark_background .buttonwidget .tc-purple-darkhover:hover
, .tc-purple-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover:hover
, .used_on_dark_background .blockswidget-metaunder .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-purple-darkhover
{
  background-color: #652c7e;
}




/*
  --themecolor:       #824B96;
  --themecolor-dark:  #4C195F; / * also CTAButton color * /
  --themecolor-light: #8c5f96; / * hover * /
*/

/* Solid button styles */
  .ctabutton--autostyle.tc-purple               /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-purple                   /* explicitely solid */
, html.tc-purple .ctabutton--autostyle.tc-auto  /* widget inheriting the color from the page themecolor */
, html.tc-purple .ctabutton--solid.tc-auto      /* widget inheriting the color from the page themecolor */
, html.tc-purple .wh-form__button               /* Form webtool */
, .widget-blocks.theme--purple .blockswidget__item__button
{
  background-color: #824B96;
  /*background-color: var(--themecolor);*/
  color: #FFFFFF;
}

  .ctabutton--autostyle.tc-purple:hover /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-purple:hover /* at top level (not in color background) default to solid */
, html.tc-purple .ctabutton--autostyle.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, html.tc-purple .ctabutton--solid.tc-auto:hover /* widget inheriting the color from the page themecolor */
, html.tc-purple .wh-form__button:hover          /* Form webtool */
, .widget-blocks.theme--purple .blockswidget__item:hover .blockswidget__item__button
{
  background-color: #4C195F;
  /*background-color: var(--themecolor-dark);*/
}


/* Colored outline button styles */
  .ctabutton--outline.tc-purple
, html.tc-purple .ctabutton--outline.tc-auto  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-purple /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-purple /* without a contentblock with backgroundcolor and explicit color selection */
{
  background-color: #FFFFFF;
  color: #824B96;
  /*color: var(--themecolor);*/

  border: 2px solid #824B96;
  /*border: 2px solid var(--themecolor);*/
}

  .ctabutton--outline.tc-purple:hover
, html.tc-purple .ctabutton--outline.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-purple:hover /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-purple:hover /* without a contentblock with backgroundcolor and explicit color selection */
{
  /*background-color: var(--themecolor);*/
  background-color: #824B96;
  color: #FFFFFF;
}
