.ksh--header--filters.wh-styledinput
{
  max-width: none;
}
.ksh--header--filters .ui-selectmenu-button
{
  width: auto !important;
  border: 0;
}
.ksh--header--filters .ui-selectmenu-button + select + .ui-selectmenu-button
{
  border-left: 1px solid #D0D0D0;
}
#ks__searchfilters.wh-styledinput
{
  max-width: none;
}
.ui-selectmenu-button.ui-selectmenu-button
{
  border-radius: 0;
}

/* override the inherit, which get's the white meant for the background of it's parent..
   however the select has a white background.
*/
.blockswidget-tagfilter.styled
{
  color: #000000;
}
