/*

Orange

Normal  / Hover   / Down    / CTAButton / CTAButton-hover
#fd7723 / #fd7723 / ???     / ???       / ???
          (N/A)

*/

  .sitetheme--orange
, .theme--orange
{
  --themecolor:       #FD7723;
  --themecolor-dark:  #7E3B11; /* also CTAButton color */
  --themecolor-light: #FFFF00; /* FIXME !! */
  /* rgba(253, 119, 35, 0.4); */
}

.sitetheme--orange
{
  --sitethemecolor:       #FD7723;
  --sitethemecolor-dark:  #7E3B11; /* also CTAButton color */
  --sitethemecolor-light: #FFFF00; /* FIXME !! */
  /* rgba(253, 119, 35, 0.4); */
}


  .rtdcontent.tc-orange > p a
, .rtdcontent.tc-orange > ul a
, .rtdcontent.tc-orange > ol a
, .rtdcontent.tc-orange > h1
, .rtdcontent.tc-orange > h2
, .rtdcontent.tc-orange > h3
, .rtdcontent.tc-orange > h4

, .tc-orange .wh-form__richtext > p a
, .tc-orange .wh-form__richtext > ul a
, .tc-orange .wh-form__richtext > ol a
, .tc-orange h1
, .tc-orange h2
, .tc-orange h3
, .tc-orange h4

, .wh-rtd-editor.tc-orange > p a
, .wh-rtd-editor.tc-orange > ul a
, .wh-rtd-editor.tc-orange > ol a
, .wh-rtd-editor.tc-orange > h1
, .wh-rtd-editor.tc-orange > h2
, .wh-rtd-editor.tc-orange > h3
, .wh-rtd-editor.tc-orange > h4

, .tc-orange-fg-dark.tc-orange-fg-dark
, .tc-orange-fg-dark-ahover a:hover
, .themeclass.tc-orange-apply a

, html.tc-orange .wh-form__grouptitle
{
  color: #fd7723;
}




  .tc-orange-bg-dark
, .wh-rtd-editor.tc-orange-bg-dark
{
  background-color: #fd7723;
  color: #FFFFFF;
}

.tc-orange-bc-dark
{
  border-color: #fd7723;
}

/* allow the dark anchor to work on itself and two levels deeper */
  .tc-orange-ac-dark /* still used?? */
, a.tc-orange-ac-dark
, .tc-orange-ac-dark > a
, .tc-orange-ac-dark > * > a
{
  color: #fd7723;
}
  .tc-orange-ac-dark:hover
, a.tc-orange-ac-dark:hover
, .tc-orange-ac-dark > a:hover
, .tc-orange-ac-dark > * > a:hover
{
  color: #fd7723; /* NOT DEFINED! */
}
  .tc-orange-ac-dark:active
, a.tc-orange-ac-dark:active
, .tc-orange-ac-dark > a:active
, .tc-orange-ac-dark > * > a:active
{
  color: #fd7723; /* NOT DEFINED */
}

/* buttons (on white) */
  .tc-orange-darkhover
, /*.document-toplevel*/.tc-orange > .embeddedobject-inflow .tc--darkhover /* use 'pagetheme' on buttons at toplevel */
, .blockswidget-item-meta .blockswidget-item-link.tc-orange-darkhover
{
  background-color: #fd7723;
  color: #FFFFFF !important;
  transition: background-color 0.15s;
}
  .tc-orange-darkhover:hover
, .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-orange-darkhover
{
  background-color: #fd7723; /* NOT DEFINED */
}
.tc-orange-darkhover:active
{
  background-color: #fd7723; /* NOT DEFINED */
}
.tc-orange-darkhover:focus
{
  outline: 0;
  box-shadow: 1.5px 1.5px 3px rgba(0,0,0,0.4);
}


/* buttons widget (on a dark background) */
  .used_on_dark_background .buttonwidget .tc-orange-darkhover
, .tc-orange-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover
, .used_on_dark_background .blockswidget-metaunder .blockswidget-item-link.tc-orange-darkhover
{
  background-color: #fd7723; /* NOT DEFINED */
  color: #FFFFFF;
}
  .used_on_dark_background .buttonwidget .tc-orange-darkhover:hover
, .tc-orange-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover:hover
, .used_on_dark_background .blockswidget-metaunder .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-orange-darkhover
{
  background-color: #fd7723; /* NOT DEFINED */
}




/*
  --themecolor:       #FD7723;
  --themecolor-dark:  #7E3B11; / * also CTAButton color * /
  --themecolor-light: #FFFF00; / * FIXME !! * /
*/

/* Solid button styles */
  .ctabutton--autostyle.tc-orange               /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-orange                   /* explicitely solid */
, html.tc-orange .ctabutton--autostyle.tc-auto  /* widget inheriting the color from the page themecolor */
, html.tc-orange .ctabutton--solid.tc-auto      /* widget inheriting the color from the page themecolor */
, html.tc-orange .wh-form__button               /* Form webtool */
, .widget-blocks.theme--orange .blockswidget__item__button
{
  background-color: #FD7723;
  /*background-color: var(--themecolor);*/
  color: #FFFFFF;
}

  .ctabutton--autostyle.tc-orange:hover /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-orange:hover /* at top level (not in color background) default to solid */
, html.tc-orange .ctabutton--autostyle.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, html.tc-orange .ctabutton--solid.tc-auto:hover /* widget inheriting the color from the page themecolor */
, html.tc-orange .wh-form__button:hover          /* Form webtool */
, .widget-blocks.theme--orange .blockswidget__item:hover .blockswidget__item__button
{
  background-color: #7E3B11;
  /*background-color: var(--themecolor-dark);*/
}


/* Colored outline button styles */
  .ctabutton--outline.tc-orange
, html.tc-orange .ctabutton--outline.tc-auto  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-orange /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-orange /* without a contentblock with backgroundcolor and explicit color selection */
{
  background-color: #FFFFFF;
  color: #FD7723;
  /*color: var(--themecolor);*/

  border: 2px solid #FD7723;
  /*border: 2px solid var(--themecolor);*/
}

  .ctabutton--outline.tc-orange:hover
, html.tc-orange .ctabutton--outline.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-orange:hover /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-orange:hover /* without a contentblock with backgroundcolor and explicit color selection */
{
  /*background-color: var(--themecolor);*/
  background-color: #FD7723;
  color: #FFFFFF;
}
