/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/*

This CSS file must NOT be loaded into the richdocument editor


html--onlyfullscreenelement



Breakpoints

For content area and menu's

  -  < 940 + 300                      (max 1279) -> mobile/tablet menu (hamburger icon)
  - >= 940 + 300 + 80(vscroll+margin) (min 1280) -> submenu hanging at the right + content at left

For content within the contentarea

  - => 940
  - <  940 - "mobile layout" (no columns and smaller fonts)

Footer

  - <  940 - "mobile layout"

$minwidth-show-desktopmenubar: 1024px; // was 1280px on old design


*/
:root {
  --panels-padding-top: 30px;
  --panels-padding-bottom: 30px;
}

@media (max-width: 800px) {
  :root {
    --panels-padding-top: 25px;
    --panels-padding-bottom: 25px;
  }
}
html, body, p {
  padding: 0;
  margin: 0;
}

html {
  overflow-y: scroll;
}

/* Make room for the cookie bar */
html.utwente--cookiebarvisible #site-headers {
  position: relative !important;
}

html.utwente--cookiebarvisible #site-headers-placeholder {
  display: none !important;
}

/*
IMPORTANT: DON'T use overflow: hidden; on <body>. This will kill the sticky behaviour of site-top and site-mainmenu on iOS
*/
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* be a positioning container so #slidemenu-container
     can stretch to the full page size.
     (and it can act as clipping container to prevent the menu from
     stretching the <body> when it's animated outside the page)
  */
  position: relative;
}

/* On mobile browsers we never want to scroll.
   Prevent the user from accidently scrolling horizontally.

   (Useually when there is a horizontal scrollbar it's a styling bug or content in which
   a textline cannot be wrapped.)
*/
@media only screen and (max-width: 939px) {
  body {
    /* !! don't use overflow: hidden; because this disables position: sticky; on iOS */
    width: 100%;
  }
}
#site-crumbpath {
  margin-top: 25px;
}

/* main can follow

   #site-headers-placeholder
   #site-pageheader
   #site-crumbpath

*/
main {
  flex: 1;
}

html.tightlayout main {
  margin-top: 0;
}

html.tightlayout #site-crumbpath {
  margin-bottom: 30px;
}

html.verytightlayout main {
  padding-bottom: 0;
}

.site-centered-top,
.site-centered {
  max-width: 980px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
}

main {
  position: relative; /* contain the white bg */
}

button {
  background-image: none; /* wh-rtd */
}

button.iconbutton {
  background-color: transparent;
  padding: 0;
  color: inherit;
  min-width: 0;
  min-width: -moz-fit-content; /* otherwise FF will use width: 0; when the button is in a flex container with elements which have flex: 1; */
}

html.state_searchmode #site-search-panel {
  z-index: 1;
}

#site-searchmodaloverlay {
  z-index: 0;
}

html wh-outputtools {
  position: fixed;
  left: 0;
  top: 0;
  font: 12px/20px Helvetica, Arial;
  height: 30px;
  opacity: 1;
}

html wh-outputtool.wh-outputtool {
  height: auto;
}