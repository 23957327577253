.blockswidget--withfilters .blockswidget__header
{
  align-items: end;
  margin-bottom: 30px;
}

.blockswidget__filterstrip
{
  flex: 1 1 auto;

  display: flex;
  justify-content: end;
  column-gap: 20px;

  flex-wrap: wrap;
  row-gap: 20px;
}

.blockswidget__filterstrip__item
{
  flex: 1 1 auto;
  min-width: min-content;
}

.blockswidget__filterstrip__item label
{
  display: block;
  cursor: pointer;
  font-size: 14px;
  color: #444444;
  padding-left: 9px;
}

.blockswidget__filterstrip__item select
{
  min-width: 100%;
}



.blockswidget__filterstrip__querywrapper
{
  display: flex;
  align-items: center;

  position: relative;
}

.blockswidget__filterstrip__query.blockswidget__filterstrip__query.blockswidget__filterstrip__query
{
  padding-right: 40px;
}

.blockswidget__filterstrip__querybutton
{
  position: absolute;
  right: 4px;

  width: 30px;
  text-align: center;

  color: var(--color-grey-border);
}

.blockswidget__filterstrip__querybutton::before
{
  font: var(--fontawesome-regular);
  content: var(--fa-search);
  font-size: 17px;
}

  .blockswidget__filterstrip__querybutton:focus
, .blockswidget__filterstrip__querybutton:hover
{
  color:            #000000;
  background-color: #F0F0F0;
  border-radius:    3px;
}

/*
ADDME: custom styled clear button

.blockswidget__filterstrip__query::-webkit-search-cancel-button
{
  outline: 1px solid #F00;
  -webkit-appearance: none;
  background: url("../../web/img/close-white.svg") no-repeat center center;

  width: 20px;
  height: 20px;
}
*/



.blockswidget__filterstrip__item--tagcategories
{
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
.blockswidget__filterstrip__item--tagcategories .blockswidget__filterstrip__item
{
  min-width: 200px;
}



  .blockswidget__filterstrip__item select
, .blockswidget__filterstrip__item .ui-selectmenu-button.ui-selectmenu-button
, .blockswidget__filterstrip__item input.blockswidget__filterstrip__query
{
  width:  100%;
  height: 38px;
  cursor: pointer;
}


/* old filterstrip */
.blockswidget-filterstrip
{
  margin-left: auto;
}
