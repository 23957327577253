/*

SPC-Menubar 2022

V1.1
- CSS vars for pulldown-pointer
- "thingy" now properly working and using pulldown background color
- "arrow" can now use the border color and width given to the pulldown

TODO: fix or verify working of "connected" and "below"

*/
/*
- Pointer "thingy"
  Triangle with one sided slope.
  --menubar-pulldown-pointer-width:  20px;
  --menubar-pulldown-pointer-height: 38px;

- Pointer "arrow"
  Triangle arrow pointing to the menu item
*/
ul.topmenu {
  display: flex;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 2px 0;
}

.topmenu,
.topmenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.topmenu > li + li {
  margin-left: var(--menubar-level1-spacing);
}

.topmenu a {
  /*
  // Keep all items single-line, use ellipsis if needed
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  font: 500 17px "Univers Next W02";
  text-transform: uppercase;
  */
}

.topmenu > li:first-child {
  margin-left: calc(-1 * var(--menubar-level1-padding-left));
}

ul.topmenu > li > button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  margin: 0;
}

ul.topmenu > li > a,
ul.topmenu > li > span,
ul.topmenu > li > button {
  display: flex;
  align-items: center;
  /*
    // !! Geldlessen
    margin-top: 10px; // we cannot use flex + align-items on our parent <li> (it would change positioning of the submenu <ul>)
    height: calc(100% - 20px);
  */
  white-space: nowrap;
  color: var(--menubar-bar-item-color);
  text-decoration: none;
  font: var(--menubar-level1-font);
  padding-left: var(--menubar-level1-padding-left);
  padding-right: var(--menubar-level1-padding-right);
  /*
    // white-space: nowrap;

    width: max-content; // stretch our container if needed
    max-width: 100%; //$menubar-pulldown-maxwidth;

    overflow: hidden;
    text-overflow: ellipsis;

    text-align: center;

    letter-spacing: 0.25px; // to make the non-bold text almost the same width as the bold (hover) version
  */
  /*
    // Make the whole height an clickable area + allow us to have the
    // bottom border (for hover) on the bottom of the menu bar
    height: 100%;
    display: flex;
    flex-direction: column; // the :after must be below the visible title
    justify-content: center;
  */
}

ul.topmenu > li:hover > a,
ul.topmenu > li:hover > span {
  color: var(--menubar-bar-item-hover-color);
}

ul.topmenu > li:focus > a,
ul.topmenu > li:hover > a {
  z-index: 1;
}

ul.topmenu.topmenu > li > a:focus {
  position: relative;
  z-index: 1; /* ensure the focus outline isn't overlapped by the next item */
}

.topmenu__pulldown {
  position: absolute;
  filter: drop-shadow(var(--menubar-pulldown-drop-shadow));
  /*
    @if ($menubar-pulldown-pointer == "arrow" or $menubar-pulldown-pointer == "thingy")
    {
        padding-top: 27px; // Student Union
        bottom: 0px;
        //left: 0;

        @if ($menubar-pulldown-center)
        {
          transform: translate(-50%, 100%);
        }
        @else
        {
          transform: translate(0, 100%);
        }
    }

    @if ($menubar-pulldown-pointer == "connected")
    {
    }
  */
  display: none;
  /*
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
  */
}

ul.topmenu > li:hover > .topmenu__pulldown {
  display: block;
  /*
  pointer-events: auto;
  opacity: 1;
  */
}

.topmenu__pulldown > ul {
  position: relative;
  padding: var(--menubar-pulldown-padding);
  border-radius: var(--menubar-pulldown-border-radius);
  width: max-content;
  min-width: var(--menubar-pulldown-minwidth);
  max-width: var(--menubar-pulldown-maxwidth);
}

.topmenu__pulldown > ul {
  background-color: var(--menubar-pulldown-bgcolor);
  border: var(--menubar-pulldown-border-width) solid var(--menubar-pulldown-border-color);
}

.topmenu__pulldown > ul::before {
  border-left-color: var(--menubar-pulldown-bgcolor);
}

.topmenu__pulldown > ul::before {
  content: " ";
  border-width: var(--menubar-pulldown-pointer-width) var(--menubar-pulldown-pointer-width) 0 var(--menubar-pulldown-pointer-width);
  border-style: solid;
  border-color: transparent transparent transparent transparent;
  border-left: var(--menubar-pulldown-pointer-height) solid var(--menubar-pulldown-bgcolor);
  width: 0px;
  height: 0;
  position: absolute;
  left: 0;
  bottom: 100%;
  pointer-events: none;
}

/*
ul.topmenu > li > a::after
{
  display: block;
  content: attr(data-title) ".";
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;

  letter-spacing: 0px;
}

// FIXME: media hover:hover ?
ul.topmenu > li > a:hover
{
  font-weight: bold;
  letter-spacing: 0px;
  // border-bottom: 1px solid $color-theme-cta;
}
*/
ul.topmenu__level2 > li > a,
ul.topmenu__level2 > li > span,
ul.topmenu__level3 > li > a,
ul.topmenu__level3 > li > span,
ul.topmenu__level4 > li > a,
ul.topmenu__level4 > li > span {
  display: flex;
  align-items: center;
}

ul.topmenu__level2 > li > a,
ul.topmenu__level2 > li > span {
  color: var(--menubar-level2-color);
  font: var(--menubar-level2-font);
  text-decoration: none;
  padding-left: var(--menubar-level2-padding-left);
  padding-right: var(--menubar-level2-padding-right);
  padding-top: var(--menubar-level2-padding-top);
  padding-bottom: var(--menubar-level2-padding-bottom);
}

ul.topmenu__level3 > li > a,
ul.topmenu__level3 > li > span {
  color: var(--menubar-level3-color);
  font: var(--menubar-level3-font);
  text-decoration: none;
  padding-left: var(--menubar-level3-padding-left);
  padding-right: var(--menubar-level3-padding-right);
  padding-top: var(--menubar-level3-padding-top);
  padding-bottom: var(--menubar-level3-padding-bottom);
}

ul.topmenu__level4 > li > a,
ul.topmenu__level4 > li > span {
  color: var(--menubar-level4-color);
  font: var(--menubar-level4-font);
  text-decoration: none;
  padding-left: var(--menubar-level4-padding-left);
  padding-right: var(--menubar-level4-padding-right);
  padding-top: var(--menubar-level4-padding-top);
  padding-bottom: var(--menubar-level4-padding-bottom);
}

ul.topmenu__level2 > li > a:hover,
ul.topmenu__level2 > li > span:hover,
ul.topmenu__level3 > li > a:hover,
ul.topmenu__level3 > li > span:hover,
ul.topmenu__level4 > li > a:hover,
ul.topmenu__level4 > li > span:hover {
  text-decoration: none;
  background-color: var(--menubar-pulldown-itemhover-bgcolor);
  color: var(--menubar-pulldown-itemhover-textcolor);
  text-decoration: var(--menubar-pulldown-itemhover-textdecoration);
}

ul.topmenu__level2 > li .topmenu__item__toggle::before {
  flex: none;
  font: var(--menubar-level2-toggle-font);
  content: var(--menubar-level2-expandicon);
}
ul.topmenu__level2 > li.topmenu__item--expand > a .topmenu__item__toggle::before {
  content: var(--menubar-level2-collapseicon);
  padding-left: 0;
}

ul.topmenu__level3 > li .topmenu__item__toggle::before {
  flex: none;
  font: var(--menubar-level3-toggle-font);
  content: var(--menubar-level3-expandicon);
}
ul.topmenu__level3 > li.topmenu__item--expand > a .topmenu__item__toggle::before {
  content: var(--menubar-level3-collapseicon);
  padding-left: 0;
}

.topmenu__item__toggle {
  margin-left: auto;
  width: var(--sidemainmenu-toggler-width);
  text-align: right;
  margin-right: -7px;
  padding-right: 7px;
  margin-top: -4px;
  padding-top: 4px;
  margin-bottom: -4px;
  padding-bottom: 4px;
}

.topmenu__level3 {
  height: 0;
  overflow: hidden;
}

.topmenu__item--expand > .topmenu__level3 {
  height: auto;
  overflow: auto;
}

.topmenu__level4 {
  height: 0;
  overflow: hidden;
}

.topmenu__item--expand > .topmenu__level4 {
  height: auto;
  overflow: auto;
}