/*

Light-Blue

Normal  / Hover   / Down    / CTAButton / CTAButton-hover
#5078b4 / #5b84c1 / #3f6ba6 / #24416a   / #33598b
#3a75b9 / #...... / #...... / #87BBE5   / #......


volgens doc - #87BBE5 (CTAbutton??)
volgens PSD - #88bce4 (CTAButton)
              #3e404c (button bottom)
              #dfebf7 (button top)
              #3a75b9 (tekst...?)

apply = will apply to sublevels too (instead of very shallow)

*/


  .sitetheme--lightblue
, .theme--lightblue
{
  --themecolor:       #3a75b9;
  --themecolor-dark:  #24416A; /* FIXME */
  --themecolor-light: #3a75b9; /* FIXME */
  /* background-color: rgba(63, 107, 166, 0.4); */
}

.sitetheme--lightblue
{
  --sitethemecolor:       #3a75b9;
  --sitethemecolor-dark:  #24416A; /* FIXME */
  --sitethemecolor-light: #3a75b9; /* FIXME */
  /* background-color: rgba(63, 107, 166, 0.4); */
}


/* we very specifically target <a> because otherwise we
   would also target <a>'s within embeddedobjects (which might have another colorschema).
   We need color inheritance, but CSS (except for CSS variables) works by targetting on rule.
   (we can inherit color, but not have a second seperate color for anchors... unless we could use CSS variables)
*/
  .rtdcontent.tc-lightblue > p a
, .rtdcontent.tc-lightblue > ul a
, .rtdcontent.tc-lightblue > ol a
, .rtdcontent.tc-lightblue > h1
, .rtdcontent.tc-lightblue > h2
, .rtdcontent.tc-lightblue > h3
, .rtdcontent.tc-lightblue > h4

, .tc-lightblue .wh-form__richtext > p a
, .tc-lightblue .wh-form__richtext > ul a
, .tc-lightblue .wh-form__richtext > ol a
, .tc-lightblue h1
, .tc-lightblue h2
, .tc-lightblue h3
, .tc-lightblue h4

, .wh-rtd-editor.tc-lightblue > p a
, .wh-rtd-editor.tc-lightblue > ul a
, .wh-rtd-editor.tc-lightblue > ol a
, .wh-rtd-editor.tc-lightblue > h1
, .wh-rtd-editor.tc-lightblue > h2
, .wh-rtd-editor.tc-lightblue > h3
, .wh-rtd-editor.tc-lightblue > h4

, .tc-lightblue-fg-dark.tc-lightblue-fg-dark
, .tc-lightblue-fg-dark-ahover a:hover

/* FIXME: replace .rtdcontent and .wh-rtd-editor with a single class */
, .themeclass.tc-lightblue-apply a

, html.tc-lightblue .wh-form__grouptitle
{
 color: #3a75b9;
}




  .tc-lightblue-bg-dark
, .wh-rtd-editor.tc-lightblue-bg-dark
{
  background-color: #88bce4;
  color: #FFFFFF;
}


/* may 2018 redesign */
.tc-lightblue-bg-dark
{
  /*background: linear-gradient(145deg,  #87bce6 0%,#1e79e6 50%,#1e79e6 100%); */
  background: linear-gradient(190deg, #87bce6 0%, #3a74ba 50%, #3a74ba 100%);
}



.tc-lightblue-bc-dark
{
  border-color: #3a75b9;
}

/* allow the dark anchor to work on itself and two levels deeper */
  .tc-lightblue-ac-dark /* no inheritance for specific items on which it's used (meant for buttons) */
,  a.tc-lightblue-ac-dark
, .tc-lightblue-ac-dark > a
, .tc-lightblue-ac-dark > * > a
{
  color: #3a75b9;
}
/* NOT IMPLEMENTED FOR LIGHTBLUE */
  .tc-lightblue-ac-dark:hover
, a.tc-lightblue-ac-dark:hover
, .tc-lightblue-ac-dark > a:hover
, .tc-lightblue-ac-dark > * > a:hover
{
  color: #5b84c1;
}
/* NOT IMPLEMENTED FOR LIGHTBLUE */
  .tc-lightblue-ac-dark:active
, a.tc-lightblue-ac-dark:active
, .tc-lightblue-ac-dark > a:active
, .tc-lightblue-ac-dark > * > a:active
{
  color: #3f6ba6;
}

/* buttons (on white) */
  .tc-lightblue-darkhover
, /*.document-toplevel*/.tc-lightblue > .embeddedobject-inflow .tc--darkhover /* use 'pagetheme' on buttons at toplevel */
, .blockswidget-item-meta .blockswidget-item-link.tc-lightblue-darkhover
{
  background-color: #88bce4;
  color: #FFFFFF;

/*  border-bottom: 1px solid #3e404c; - ONLY IN THIS COLORSCHEMA */

  transition: background-color 0.15s;
}
/* NOT IMPLEMENTED FOR LIGHTBLUE */
  .tc-lightblue-darkhover:hover
, .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-lightblue-darkhover
{
  background-color: #5b84c1;
}
/* NOT IMPLEMENTED FOR LIGHTBLUE */
.tc-lightblue-darkhover:active
{
  background-color: #3f6ba6;
}
.tc-lightblue-darkhover:focus
{
  outline: 0;
  box-shadow: 1.5px 1.5px 3px rgba(0,0,0,0.4);
}

/* buttons widget (on a dark background) */
  .used_on_dark_background .buttonwidget .tc-lightblue-darkhover
, .tc-lightblue-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover
, .used_on_dark_background .blockswidget-metaunder .blockswidget-item-link.tc-lightblue-darkhover
{
  background-color: #24416a;
  color: #FFFFFF;
}
/* NOT IMPLEMENTED FOR LIGHTBLUE */
  .used_on_dark_background .buttonwidget .tc-lightblue-darkhover:hover
, .tc-lightblue-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover:hover
, .used_on_dark_background .blockswidget-metaunder .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-lightblue-darkhover
{
  background-color: #33598b;
}
/* hover and focus the same as on white bg ?? */




/* Solid button styles */
  .ctabutton--autostyle.tc-lightblue               /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-lightblue                   /* explicitely solid */
, html.tc-lightblue .ctabutton--autostyle.tc-auto  /* widget inheriting the color from the page themecolor */
, html.tc-lightblue .ctabutton--solid.tc-auto      /* widget inheriting the color from the page themecolor */
, html.tc-lightblue .wh-form__button               /* Form webtool */
, .widget-blocks.theme--lightblue .blockswidget__item__button
{
  background-color: #3a75b9;
  /*background-color: var(--themecolor);*/
  color: #FFFFFF;
}

  .ctabutton--autostyle.tc-lightblue:hover /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-lightblue:hover /* at top level (not in color background) default to solid */
, html.tc-lightblue .ctabutton--autostyle.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, html.tc-lightblue .ctabutton--solid.tc-auto:hover /* widget inheriting the color from the page themecolor */
, html.tc-lightblue .wh-form__button:hover
, .widget-blocks.theme--lightblue .blockswidget__item:hover .blockswidget__item__button
{
  background-color: #24416A;
  /*background-color: var(--themecolor-dark);*/
}


/* Colored outline button styles */
  .ctabutton--outline.tc-lightblue
, html.tc-lightblue .ctabutton--outline.tc-auto  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-lightblue /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-lightblue /* without a contentblock with backgroundcolor and explicit color selection */
{
  background-color: #FFFFFF;
  color: #3a75b9;
  /*color: var(--themecolor);*/

  border: 2px solid #3a75b9;
  /*border: 2px solid var(--themecolor);*/
}

  .ctabutton--outline.tc-lightblue:hover
, html.tc-lightblue .ctabutton--outline.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-lightblue:hover /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-lightblue:hover /* without a contentblock with backgroundcolor and explicit color selection */
{
  /*background-color: var(--themecolor);*/
  background-color: #3a75b9;
  color: #FFFFFF;
}
