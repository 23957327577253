.site-page-topbar
{
  margin-top: 0;
  margin-bottom: 0;
}

a.site-goback
{
  display: inline-block;
  height: 55px;

  font: 16px "Univers Next W02";
  line-height: 55px;

  margin-top: 0;
  margin-bottom: 0;
  color: #444444;

  text-decoration: none;
  text-transform: uppercase;
}
.site-goback .fa
{
  text-align: center;
}


.neo-daterangeoptions
{
  text-align: center;
  text-transform: uppercase;

  font: 14.5px "Univers Next W02";

  margin-top: 0;
  margin-bottom: 0;
}
.neo-daterangeoptions a
{
  color: #BBBBBB;
  text-decoration: none;

  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}
.neo-daterangeoptions a.selected
{
  color: #555555;
}
/* .content allows us to have the a be a large clickable area,
   while visually looking compact
*/
.neo-daterangeoptions a .content
{
  padding-top: 22px;
  padding-bottom: 12px;
}
.neo-daterangeoptions a.selected .content
{
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-color: inherit;
}


.neoitems
{
  /* vertical */
  display: flex;
  flex-direction: column;
}

.neoitem
{
  /* horizontal */
  display: flex;

  padding-top: 14px;
  padding-bottom: 14px;

  min-height: 220px;

  border-top: 1px solid #e2e3e6;
}

.neoitem:last-child
{
  padding-bottom: 0;
}

.neoitem__image
{
  width: 235px; /*25%;*/
  background-position: center center;
  background-size: cover;

  margin-right: 30px;
}

a.neoitem
{
  display: block;
  text-decoration: none;
  width: 160px;
  min-height: 106px;
  background-size: cover;

  background-color: #979ca2; /* placeholder color */
}

.neoitem__meta
{
  flex: 1;

  /* vertical */
  display: flex;
  flex-direction: column;
}

.neoitem__title
{
  font: 24px "Univers Next W02";
  text-decoration: none;
}

.neoitem__dateandloc
{
  color: #888888;
  margin-bottom: 5px;
  font-size: 15px;
}

/* 1240 */
@media only screen and (max-width: 640px)
{
  .neoitem__image
  {
    width: 50%;
  }

  .neoitem__description
  {
    display: none;
  }

  .neoitem__title
  {
    font: 18px "Univers Next W02";
  }
}
a.neoitem-readmore
{
  text-transform: uppercase;
  text-decoration: none;
}
