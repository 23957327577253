button
{
  border: 0;
  background-color: transparent;
  font: inherit;
  cursor: pointer;
}

  button.ctabutton
, a.ctabutton
, .wh-form__button
, a.wh-form__button /* for specificy to override a:hover from rtd.css */
, .wh-styledinput--bwbutton button
{
  display: inline-flex;
  align-items: center;

  min-height: 60px;
  padding: 7px 30px;
  border-radius: 41px;

  font: 500 18px/22px "Univers Next W02";
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  outline-offset: 3px;
}

.ctabutton__text
{
  flex-grow: 1;
}

a.ctabutton:hover
{
  text-decoration: none;
}

.ctabutton
{
  background-color: #DDDDDD; /* placeholder. use [themecolor]ac-dark */
}


  .ctabutton::after
, .ctabutton--forward::after /* more explicit version */
, .wh-form__button--next::after
, .wh-form__button--submit::after
{
  content: "\f105";
  margin-left: 25px;

  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  /*$fa-angle-right:   "\f105";*/
}

.wh-form .wh-form__button--previous::before
{
  content: "\f104";
  margin-right: 25px;

  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  /*$fa-angle-right:   "\f105";*/
}




@media (max-width: 640px)
{
    button.ctabutton
  , a.ctabutton
  , .wh-form__button
  , .wh-styledinput--bwbutton button
  {
    min-height: 38px;
    padding: 4px 15px;
    border-radius: 41px;

    font: 500 16px/18px "Univers Next W02";

  }
}




/*
White outline button is used when:
- style "auto" + color "auto" within any color background
- any button with the same explicit color as the background color of the contentblock it's in
*/
  .used_on_dark_background.used_on_dark_background .ctabutton--autostyle.tc-auto
, .used_on_dark_background.used_on_dark_background .ctabutton--outline.tc-auto
, .used_on_dark_background.tc-blue-bg-dark         .ctabutton.ctabutton.tc-blue
, .used_on_dark_background.tc-gray-bg-dark         .ctabutton.ctabutton.tc-gray
, .used_on_dark_background.tc-green-bg-dark        .ctabutton.ctabutton.tc-green
, .used_on_dark_background.tc-lightblue-bg-dark    .ctabutton.ctabutton.tc-lightblue
, .used_on_dark_background.tc-orange-bg-dark       .ctabutton.ctabutton.tc-orange
, .used_on_dark_background.tc-purple-bg-dark       .ctabutton.ctabutton.tc-purple
, .used_on_dark_background.tc-red-bg-dark          .ctabutton.ctabutton.tc-red
, .used_on_dark_background.tc-yellow-bg-dark       .ctabutton.ctabutton.tc-yellow
{
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  background-color: transparent;
}

  .used_on_dark_background.used_on_dark_background .ctabutton--autostyle.tc-auto:hover
, .used_on_dark_background.used_on_dark_background .ctabutton--outline.tc-auto:hover
, .used_on_dark_background.tc-blue-bg-dark         .ctabutton.ctabutton.tc-blue:hover
, .used_on_dark_background.tc-gray-bg-dark         .ctabutton.ctabutton.tc-gray:hover
, .used_on_dark_background.tc-green-bg-dark        .ctabutton.ctabutton.tc-green:hover
, .used_on_dark_background.tc-lightblue-bg-dark    .ctabutton.ctabutton.tc-lightblue:hover
, .used_on_dark_background.tc-orange-bg-dark       .ctabutton.ctabutton.tc-orange:hover
, .used_on_dark_background.tc-purple-bg-dark       .ctabutton.ctabutton.tc-purple:hover
, .used_on_dark_background.tc-red-bg-dark          .ctabutton.ctabutton.tc-red:hover
, .used_on_dark_background.tc-yellow-bg-dark       .ctabutton.ctabutton.tc-yellow:hover
{
  background-color: rgba(0, 0, 0, 0.2);
}
