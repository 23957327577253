.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.su-dialog {
  background: white;
  padding: 20px;
  min-width: 450px;
}
.su-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.su-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.su-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.su-dialog .wh-form__fieldgroup {
  flex-direction: column;
}
.su-dialog .wh-form__fields {
  width: 100%;
}
.su-dialog input {
  width: 100%;
}
.su-dialog .wh-form__label {
  overflow: visible;
  width: 100%;
  max-width: 100%;
  padding-right: 0;
  padding-bottom: 5px;
}

.wh-form__button, .wh-styledinput--bwbutton button {
  color: #FFFFFF;
}
html.kamersite .wh-form__button, html.kamersite .wh-styledinput--bwbutton button {
  background-color: #fd7723;
}

.wh-form__richtext {
  width: 100%;
}

.wh-form__rtd {
  width: 100%;
  min-height: 200px;
}
.wh-form__rtd.wh-rtd__editor {
  border-color: #cbcbcb;
}
.wh-form__rtd.wh-rtd__editor:hover, .wh-form__rtd.wh-rtd__editor:focus {
  border-color: #666;
}
.wh-form__rtd .wh-rtd__toolbarstyle {
  max-height: 100%;
  padding: 0 5px;
}

.wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.wh-form__uploadfield input {
  width: 100%;
}
.wh-form__uploadfield .wh-form__uploadfieldinputholder {
  flex: 1 1 auto;
}
.wh-form__uploadfield button {
  white-space: nowrap;
  min-height: 38px;
  margin-left: 10px;
  line-height: 26px;
  padding: 6px 20px;
  flex: 0 1 150px;
  justify-content: center;
}

.wh-form--uploading .wh-form__imgeditholder:before {
  animation: fa-spin 2s infinite linear;
  content: "\f1ce";
  width: 100px;
  height: 100px;
  transform-origin: 50px 12px;
}

.wh-form__fieldgroup--imgedit > .wh-form__label:empty {
  display: none;
}
.wh-form__fieldgroup--imgedit .wh-form__imgeditdelete {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  opacity: 0.3;
  cursor: pointer;
  transition: opacity 0.2s;
}
.wh-form__fieldgroup--imgedit .wh-form__imgeditdelete:hover {
  opacity: 0.8;
}
.wh-form__fieldgroup--imgedit .wh-form__imgeditdelete:before {
  display: inline-block;
  content: "\f1f8";
  font-family: "FontAwesome";
  font-size: 20px;
  color: #000;
}

.wh-styledinput-neo {
  /* css styled native pulldown */
}
.wh-styledinput-neo .wh-form__pulldown-styled {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  font: inherit;
  color: #4a4a4a;
  height: 40px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #cbcbcb;
  transition: border-color 0.2s;
}
.wh-styledinput-neo .wh-form__pulldown-styled:hover {
  border-color: #aaa;
}
.wh-styledinput-neo .wh-form__pulldown-styled .arrow {
  font-size: 24px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -12px;
  color: #9b9b9b;
}
.wh-styledinput-neo .wh-form__pulldown-styled:after {
  display: block;
  content: "";
  width: 20px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 40px;
  top: 5px;
  bottom: 5px;
  pointer-events: none;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.wh-styledinput-neo .wh-form__pulldown-styled select {
  position: relative;
  z-index: 1; /* On top of arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0 none;
  height: 40px;
  width: 100%;
  font: inherit;
  color: inherit;
  padding: 5px 40px 5px 15px;
  outline: none;
}
.wh-styledinput-neo .wh-form__pulldown-styled select:disabled {
  opacity: 0.4;
}
.wh-styledinput-neo .wh-form__pulldown-styled select:disabled + .arrow {
  opacity: 0.4;
}

.wh-form.ut-neoform {
  /* IE11 workaround for centering text inside button */
}
.wh-form.ut-neoform.wh-form--submitting:before {
  content: "";
  display: block;
  position: fixed;
  font-family: "FontAwesome";
  color: #fff;
  font-size: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
.wh-form.ut-neoform.wh-form--submitting:after {
  content: "\f1ce";
  display: block;
  position: fixed;
  font-family: "FontAwesome";
  color: #fff;
  font-size: 100px;
  top: 50%;
  left: 50%;
  z-index: 1000;
  opacity: 0.8;
  animation: fa-spin 2s infinite linear;
}
.wh-form.ut-neoform textarea {
  width: 100%;
}
.wh-form.ut-neoform .wh-form__buttongroup .wh-form__button:after {
  content: "";
  font-size: 0;
  min-height: inherit;
  display: inline-block;
}
.wh-form.ut-neoform .wh-form__fieldgroup--checkboxgroup {
  margin-top: 15px;
}
.wh-form.ut-neoform .wh-rtd__toolbarstyle {
  display: none;
}
.wh-form.ut-neoform .wh-form__fieldgroup--radiogroup[data-wh-form-group-for="schedule publishdate"] > label {
  display: none;
}
.wh-form.ut-neoform input[type=text] {
  width: 100%;
}

.ut-neoform-showblurrybg > .wh-form__label {
  display: none;
}

.wh-form__fieldgroup--richtext:not(.wh-form__fieldgroup--hidden) {
  display: block !important;
}

.wh-styledinput label {
  cursor: pointer;
}

.wh-styledinput input[type=radio] + label ~ input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label ~ input[type=checkbox] + label {
  margin-left: 25px;
}

.wh-styledinput .wh-form__subfieldlabel + input,
.wh-styledinput .wh-form__subfieldlabel + input + div {
  margin-left: 10px;
}

.wh-form__questiongroup + .wh-form__questiongroup {
  padding-top: 10px;
}

.wh-form__fieldgroup {
  position: relative;
}
.wh-form__fieldgroup .wh-anchor {
  scroll-margin-top: 70px;
}

#roomsubmissionform .wh-form__fieldgroup .wh-anchor {
  scroll-margin-top: 120px;
}

.wh-form__fieldgroup + .wh-form__fieldgroup {
  margin-top: 10px;
}

.wh-form__radiooption,
.wh-form__checkboxoption {
  margin-bottom: 10px;
}

.wh-form__optiondata {
  margin-left: 10px;
}

/* some space between grouped checkboxes/radioubuttons or any other
   controls grouped in .wh-form__fields or .wh-form__optiondata
*/
.wh-form__fieldline + .wh-form__fieldline {
  margin-top: 5px;
}

@media (min-width: 506px) {
  .wh-form__label {
    width: 30%;
    max-width: 250px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 505px) {
  .wh-form__fieldgroup {
    flex-direction: column;
  }
  .wh-form__fields {
    width: 100%;
  }
  .wh-form__fieldgroup + .wh-form__fieldgroup {
    margin-top: 20px;
  }
}
.wh-styledinput .wh-form__fieldgroup.wh-form__fieldgroup--required .wh-form__label:after {
  content: "*";
}