/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
.neopage .page__contentarea.page__contentarea {
  padding-top: 0;
}

.neoitem-header--withimage.neoitem-header--withimage.neoitem-header--withimage {
  grid-column: 1/span 31;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
  margin-top: 0 !important;
  position: relative;
  height: 421px;
  background-color: #777;
}

@media (max-width: 1023px) {
  .neoitem-header__meta {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) {
  .neoitem-header__meta {
    grid-column: 7/span 19;
  }
}

a.neoitem-header--withimage {
  text-decoration: none;
}

.neoitem-header--withimage {
  background-size: cover;
}

.neoitem-header--withimage--noimage {
  height: auto;
}

.neoitem-header--withimage--noimage .neoitem-header--withimage__meta {
  position: relative;
}

.neoitem-header__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.neoitem-header__shade {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+100,000000+100&0+0,1+100 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@media only screen and (max-width: 939px) {
  .neoitem__header {
    background-image: none !important;
    height: 60vw;
  }
}
.neoitem-header__meta {
  grid-row: 1;
  align-self: end;
  z-index: 1;
  position: relative;
  padding-bottom: 10px;
}
@media (max-width: 1023px) {
  .neoitem-header__meta {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) {
  .neoitem-header__meta {
    grid-column: 7/span 19;
  }
}

.neoitem-header__title {
  margin: 0;
}

.neoitem-dateandloc + .neoitem-header__title {
  margin-top: 8px;
}

.neoitem-header--withimage .neoitem-header__title,
.neoitem-header--withimage .neoitem-header__dateandloc {
  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35);
}

/*.neoitem-header--withimage*/
.neoitem-header__dateandloc {
  font: 500 16px/18px "Univers Next W02", Arial;
  text-transform: uppercase;
}

.neo-topbar {
  margin-top: 15px;
  display: flex;
}

a.neo-topbar__overviewlink {
  display: flex;
  align-items: center;
  color: var(--themecolor);
  font: 700 14px/16px "Univers Next W02", Arial;
  text-decoration: none;
  margin-left: auto;
}

a.neo-topbar__overviewlink:hover {
  text-decoration: underline;
}

.neo-tags {
  margin-right: 30px;
}

a.neo-topbar__overviewlink::after {
  text-decoration: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f105";
  font-size: 16px;
  display: flex;
  width: 31px;
  height: 31px;
  border: 1px solid var(--themecolor);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.neo-event-note {
  margin-bottom: 20px;
  font: 500 14px/17px "Univers Next W02", Arial;
  text-transform: uppercase;
}

.neo-event__meta {
  display: flex;
  align-items: start;
  margin-top: 50px;
  border-top: 1px solid var(--themecolor);
  padding-top: 15px;
}

.neo-event__meta__buttons {
  display: flex;
  align-items: start;
}

.neo-event__meta__buttons > * + * {
  margin-left: 22px;
}

.neoitem-addtocalendar-wrapper {
  position: relative;
}

.neoitem-addtocalendar {
  position: relative;
  z-index: 10000;
}

.neoitem-addtocalendar-wrapper .addeventatc_dropdown {
  position: absolute;
  left: 1px !important;
  top: 30px !important;
  padding-top: 30px;
  padding-bottom: 5px;
  border: 1px solid var(--themecolor);
  border-radius: 0 0 8px 8px;
  width: 100%;
}
.neoitem-addtocalendar-wrapper .addeventatc_dropdown > *:hover {
  color: var(--themecolor);
}

.neoitem-addtocalendar .far {
  margin-left: 20px;
  margin-top: -4px;
}

.neo-event__meta__fields {
  flex: 1 1 0;
}

.neo-event__meta__buttons {
  flex: 1 1 none;
}

.neo-event__meta__fields dl {
  margin: 0;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: baseline;
}

.neo-event__meta__fields dt {
  font: 500 16px "Univers Next W02", Arial;
  text-transform: uppercase;
  color: var(--themecolor);
  padding-right: 30px;
}

.neo-event__meta__fields dd {
  font: 17px/26px Lato;
  padding: 0;
  margin: 0;
}

.neo-event-downloads {
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
}
.neo-event-downloads li {
  display: block;
  position: relative;
}
.neo-event-downloads li > svg {
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
}
.neo-event-downloads a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  font: 500 16px/24px "Univers Next W02", Arial;
  text-transform: uppercase;
}
.neo-event-downloads a:hover {
  text-decoration: underline;
}

.neo-backlink {
  text-transform: uppercase;
  white-space: nowrap;
  padding-bottom: 20px;
  font: 500 22px/26px "Univers Next W02", Arial;
}
.neo-backlink a {
  color: inherit;
  text-decoration: none;
}
.neo-backlink a .fa {
  position: relative;
  top: 1px;
  font-size: 28px;
  padding-right: 10px;
}