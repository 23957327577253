/* dialog */
.dialog__modal
{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog__modal:before
{
  display: inline-block;
  content: '';
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.dialog__body
{
  text-align: left;
  display: inline-block;
  white-space: normal;
  background-color: #fff;
  padding: 15px 15px 20px;
  border: 1px solid #999;
  border-radius: 2px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
  min-width: 200px;
  max-width: 450px;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  transition: transform 0.5s;
}
.dialog__body .close
{
  font-size: 22px;
  line-height: 22px;
  display: inline-block;
  position: absolute;
  opacity: 0.4;
  right: 3px;
  top: 1px;
  cursor: pointer;
  transition: opacity 0.3s;
}
.dialog__body .close:hover
{
  opacity: 1;
}
.dialog__body .title
{
  font-size: 18px;
  font-family: inherit;
  font-weight: 700;
  color: #222222;
  text-transform: uppercase;
  margin: 0 0 20px;
  padding-right: 35px;/* space for close btn */
}
.dialog__content p
{
  margin-top: 15px;
}
.dialog__modal .buttonbar
{
  margin-top: 15px;
  text-align: center;
}
