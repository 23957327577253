html
{
  --fontawesome:         'Font Awesome 5 Pro';
  --fontawesome-light:   300 14px 'Font Awesome 5 Pro';
  --fontawesome-regular: 400 14px 'Font Awesome 5 Pro';
  --fontawesome-solid:   900 14px 'Font Awesome 5 Pro';
  --fontawesome-brands:  14px 'Font Awesome 5 Brands';


  --fa-file-pdf: --"\f1c1";

  /* Social networks */
  --fa-twitch: "\f1e8";
  --fa-twitter: "\f099";
  --fa-twitter-square: "\f081";
  --fa-facebook: "\f09a";
  --fa-facebook-f: "\f39e";
  --fa-facebook-messenger: "\f39f";
  --fa-facebook-square: "\f082";
  --fa-linkedin: "\f08c";
  --fa-linkedin-in: "\f0e1";
  --fa-instagram: "\f16d";
  --fa-whatsapp: "\f232";
  --fa-youtube:   "\f167";


  --fa-exclamation-triangle: "\f071";


  --fa-play: "\f04b";
  /*--fa-play-circle: "f144";*/


  --fa-search: "\f002";

  --fa-minus: "\f068";
  --fa-plus:  "\f067";

  --fa-external-link: "\f08e";
  --fa-external-link-alt: "\f35d";


  --fa-arrow-left:    "\f060";
  --fa-arrow-right:   "\f061";
  --fa-arrow-down:    "\f063";

  --fa-arrow-from-left: "\f343";
  --fa-arrow-to-right: "\f340";

  /* Angle */
  --fa-angle-left:    "\f104";
  --fa-angle-right:   "\f105";
  --fa-angle-up:      "\f106";
  --fa-angle-down:    "\f107";

  --fa-calendar:      "\f133";
  --fa-calendar-alt:  "\f073";
  --fa-calendar-day:  "\f783";

  --fa-check:         "\f00c";

  --fa-clock:         "\f017";

  --fa-arrow-circle-down: "\f0ab";

  /* Chevron */
  --fa-chevron-left:  "\f053";
  --fa-chevron-right: "\f054";
  --fa-chevron-up:    "\f077";
  --fa-chevron-down:  "\f078";

  --fa-chevron-double-left: "\f323";
  --fa-chevron-double-right: "\f324";

  --fa-cross:         "\f654";

  /* Circle */
  --fa-arrow-circle-left:  "\f0a8";
  --fa-arrow-circle-right: "\f0a9";
  --fa-info-circle: "\f05a";
  --fa-plus-circle: "\f055";
  --fa-minus-circle: "\f056";
  --fa-times-circle: "\f057";

  --fa-arrow-alt-from-left: "\f347";

  --fa-times:         "\f00d";

  --fa-user:  "\f007";

  --fa-power-off: "\f011";

  --fa-trash-alt: "\f2ed";

  --fa-pencil: "\f040";

  --fa-browser: "\f37e";

  /* --fa-project-diagram: "\f542"; */
  --fa-flask: "\f0c3";

  --fa-envelope: "\f0e0";
  --fa-print: "\f02f";

  --fa-long-arrow-right: "\f178";

  --fa-caret-down: "\f0d7";
  --fa-caret-up:   "\f0d8";
}
