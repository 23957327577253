/*

!!! This file is used for the backend RTD, so it cannot use SASS (.scss)

*/


  .photoalbumwidget__item--placeholder
, .neoitem__image--placeholder
{
  background-color: #EEEEEE;
}


.anchorwidget_preview
{
  font-size: 20px;
  color: #000000;
  padding: 0 20px;
  text-align: center;
}

.anchorwidget
{
  display: block;

  /* offset to make sure anchor's within the page
     scroll a little further so the indended startposition
     doesn't start under the fixed header
  */
  position: relative;
  top: -75px;

  height: 0;
  margin: 0;
  padding: 0;

  /* border: 1px solid rgba(255, 0, 0, 0.25); */
}




.widget-blocks
{
  background-color: #F3F3F1;
}

.blockswidget__itemswrapper
{
  position: relative;
}

.blockswidget__noresults
{
  display: none;

  /*font: var(--rtd-h2);*/
  font: 700 19px/26px "Univers Next W02"; /* UniversNextW04-HeavyCond Regular */
  text-transform: uppercase;
}
.widget-blocks--noresults .blockswidget__noresults
{
  display: block;
}


a.blockswidget__activationlayer
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}




.blockswidget.dealswidget
{

}

.blockswidget-filterstrip
{
  text-align: right;
  margin-bottom: 20px;
}




.blockswidget__item
{
  background-color: #FFFFFF;
  width: 100%;
  opacity: 1;
  touch-action: manipulation;
}




@media (max-width: 500px)
{
  .blockswidget__item + .blockswidget__item
  {
    margin-top: 20px;
  }
}



@media (max-width: 500px)
{
  .blockswidget__item
  {
    flex: 0 0 100%; /* only used by carrousel mode for mobile */
  }

  .widget-blocks--grid .blockswidget__item.hide
  {
    display: none;
  }
}


@media (min-width: 501px)
{
  .blockswidget__items
  {
    display: flex;
    flex-flow: row wrap;

    margin-left: -30px;
  }

  .widget-blocks--grid .blockswidget__item
  {
    margin-bottom: 20px;
    margin-left: 30px;
  }
}


/*
Most iPad's currently have 768px on the small side
*/
@media only screen and (min-width: 501px) and (max-width: 799px)
{
    .blockswidget__item
  /*, .widget-ctablocks__item*/
  {
    flex: 0 0 auto;
    width: calc(100% / 2 - 30px);
  }
/*
  .widget-blocks--carrousel .blockswidget__item
  {
    width: calc(100% / 2 - 30px / 2);
  }
*/
  .blockswidget__item
  {
    margin-bottom: 20px;
  }

  .blockswidget__items
  {
    margin-bottom: -20px; /* eat margin from last row of blocks */
  }
}

@media only screen and (min-width: 800px)
{
    .blockswidget__item
  /*, .widget-ctablocks__item*/
  {
    flex: 0 0 auto;
    width: calc(100% / 3 - 30px);
  }
/*
  .widget-blocks--carrousel .blockswidget__item
  {
    width: calc(100% / 3 - 30px / 3 * 2);
  }
*/
  .blockswidget__item
  {
    margin-bottom: 20px;
  }

  .blockswidget__items
  {
    margin-bottom: -20px; /* eat margin from last row of blocks */
  }
}




.widget-blocks--grid .blockswidget__item
{
  transform: scale(1);
  transition: opacity 0.5s, transform 0.5s;
}

.widget-blocks--grid .blockswidget__item.hide
{
  order: 1;

  transform: scale(0);
  opacity: 0;
}

.widget-blocks--grid.widget-blocks--autoshrink .blockswidget__item.hide
{
  display: none;
}





.blockswidget__item--requirelogin .block__content
{
  pointer-events: none;
}

.blockswidget__item-locked
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.45);
  text-align: center;

  display: flex;
  flex-direction: column;

  justify-content: center;
}
.blockswidget__item-locked > .far
{
  font-size: 170px;
  color: #FFFFFF;
}

html.wh-wrdauth--isloggedin .blockswidget__item-locked
{
  display: none;
}
html.wh-wrdauth--isloggedin .blockswidget__item--requirelogin .block__content
{
  pointer-events: auto;
}
html.wh-wrdauth--isloggedin .show_if_logged_in
{
  display: block;
}



.blockswidget__filterstrip__tagfilterlabel
{
  margin-right: 20px;
  font: 700 20px/24px "Univers Next W02";;
}



.blockswidget__item
{
  display: grid;
  grid-template-rows: min-content auto min-content;
}

.blockswidget__item__imagewrapper
{
  grid-column: 1;
  grid-row: 1;

  position: relative;
  overflow: hidden; /* we will shrink-wrap our image, but cut off the overflow causes by the transform: scale; */
}

.blockswidget__item__imagewrapper::after
{
  content: "";

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;

  /*opacity: 0.5;*/
  background: linear-gradient(180deg, rgba(84,83,80,0) 0%, rgba(0,0,0,0.5) 100%);
}

.blockswidget__item__image
{
  display: block; /* in case it's an <a> */
  background-size: cover;

  transform: scale(1); /* intent to scale */
  transition: transform 0.15s;
}

.blockswidget__item:hover .blockswidget__item__image
{
  transform: scale(1.1);
}




.blockswidget__item__title
{
  grid-column: 1;
  grid-row: 1;
  align-self: end;

  z-index: 1;
  position: relative; /* Needed for IE & Edge */
  padding: 15px;

  text-align: center;
  /*transform: translateY(-100%);*/

  font: 700 30px/34px "Univers Next W02";
  text-transform: uppercase;

  color: #FFFFFF;

  /* Prevent long words in a title from stretching the grid cell (and therefore image + imagewrapper).
     NOTE: The ellipsis works in Chrome, Safari, Firefox.. But not in Edge and IE.
  */
  overflow: hidden;
  text-overflow: ellipsis;
}

.blockswidget__item__subtitle
{
  font: 700 15px/17px "Univers Next W02";
  text-transform: none;
}

.blockswidget__item__bottom
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blockswidget__item__description
{
  flex: 1 0 auto;

  padding: 25px 30px 25px 30px;
  text-align: center;

  margin-bottom: auto;
}

.blockswidget__item__description.rtdcontent > *
{
  font: 15px/26px Lato;
}

.blockswidget__item__button
{
  grid-column: 1;
  grid-row: 3;

  flex: none;

  height: 64px;
  font: 500 20px/23px "Univers Next W02";

  text-decoration: none;
  display: flex;
  align-items: center; /* vertical align */
  justify-content: center; /* horizontal align */

  transition: background-color 0.15s;

  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.blockswidget__item__button::before
{
  content: "\f105";

  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;

  margin-right: 13px;
}

.dealswidget .blockswidget__item__button::before
{
  display: none;
}



/* layout of the label inside .blockswidget-item-link */

  .dealswidget .price_old
, .dealswidget .price_discounted
, .dealswidget .price
{
  font: 28px/70px "Univers Next W02";
}
.dealswidget .price_p1
{
  vertical-align: bottom;
}
.dealswidget .price_p2
{
  vertical-align: top;
  font-size: 60%;
  line-height: 62px;
  padding-left: 4px;
}
.dealswidget .price_old
{
  text-decoration: line-through;
  opacity: 0.7;
}
.dealswidget .price_old + .price_discounted
{
  margin-left: 20px;
}

/*
.dealswidget-item-code
{
  background: transparent;
  display: block;
  position: absolute;
  left: 0;
  bottom: 70px;
  width: 100%;
  text-align: center;

  text-decoration: none;

  height: 70px;
  font: 24px/70px "Univers Next W02";

  white-space: nowrap;
}

.dealswidget-item-reqcode
{
  color: inherit;
  display: none;
}

.dealswidget-item-reqcode:hover
{
  color: #ffffff;
}

.dealswidget-item-usercode
{
  display: none;
}
*/
a.ctabutton.ctabutton--codegen-code
{
  cursor: text;
}







/*
.embeddedobject-inflow
{
}
.embeddedobject-inflow:first-child
{
  padding-top: 0;
}
.document-toplevel > .embeddedobject-inflow
{
  background-color: #FFFFFF;
}
*/
.linkswidget .rtdcontent + .linkswidget-items
{
  margin-top: 10px;
}

  .downloadwidget-item a
, .linkswidget-item a
{
  color: inherit;
}

  .downloadwidget-item-description
, .linkswidget-item-description
{

}

  .downloadwidget-item
, .linkswidget-item
{
  display: block;
  text-decoration: none;
  color: inherit;
}
  .downloadwidget-item + .downloadwidget-item
, .linkswidget-item + .linkswidget-item
{
  margin: 15px 0;
}
  .downloadwidget-item:last-child
, .linkswidget-item:last-child
{
  margin-bottom: 0;
}

  .downloadwidget-item-description
, .downloadwidget-item-dllink
  .linkswidget-item-description
, .linkswidget-item-dllink
{
  margin-top: 5px;
}
  .downloadwidget-item-dllink .far
, .linkswidget-item-dllink .far
{
  display: inline-block;
  font-size: 18px;
  margin-right: 5px;
}


.imagewidget
{
  padding: 0;
}

.imagewidget .imagewidget-imagecontainer
{
  position: relative;
  overflow: hidden;
}

.imagewidget--parallax .imagewidget-placeholder
{
  margin-bottom: -200px;
}

.imagewidget .imagewidget-image
{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
}

/*
Most of the aspectratio's are to keep a photo in a specific shape.
The following aspectratio's however are to keep the widget in a specific height.
*/
.imagewidget.aspectratio-bar_flat .imagewidget-imagecontainer
{
  max-height: 400px;
}
.imagewidget.aspectratio-bar_high .imagewidget-imagecontainer
{
  max-height: 600px;
}


.imagewidget.imagewidget-imagecontainer
{
  overflow: hidden;
}




.photoalbumwidget
{
  position: relative;
  height: 400px;
}

.photoalbumwidget__item
{
  position: absolute;
  background-size: cover;
/*
november 2016 - disabled by Marcel's request
  opacity: 0.6;
*/
  transition: opacity 0.25s;

  cursor: pointer;
}

.photoalbumwidget__item:hover
{
  opacity: 1;
}

.photoalbumwidget.layout1 .place0
{
  top: 0;
  right: 0;
  width: calc(16.666% - 8px);
  height: calc(33.333% - 16px);
}
.photoalbumwidget.layout1 .place1
{
  top: calc(33.333% + 8px);
  right: 0;
  width: calc(16.666% - 8px);
  height: calc(33.333% - 16px);
}

.photoalbumwidget.layout1 .place2
{
  bottom: 0;
  right: 0;
  width: calc(16.666% - 8px);
  height: calc(33.333% - 16px);
}

.photoalbumwidget.layout1 .place3
{
  top: 0;
  right: calc(16.666% + 8px);
  width: calc(33.333% - 16px);
  height: calc(66.666% - 8px);
}

.photoalbumwidget.layout1 .photoalbum-more-indicator
{
  text-align: center;

  display: flex;
  flex-direction: column;

  justify-content: center;

  position: absolute;
  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  font-size: 35px;

  pointer-events: none;
}

.photoalbumwidget.layout1 .place4
{
  bottom: 0;
  right: calc(16.666% + 8px);

  width: calc(16.666% - 16px);
  height: calc(33.333% - 16px);
}

.photoalbumwidget.layout1 .place5
{
  bottom: 0;
  right: calc(33.333% + 8px);

  width: calc(16.666% - 16px);
  height: calc(33.333% - 16px);
}

.photoalbumwidget.layout1 .place6
{
  top: 0;
  bottom: 0;
  right: calc(50% + 8px);
  width: calc(50% - 8px);
  height: 100%;
}


/*
Activeer als Fabian akkoord is

.newswidget
{
  padding-top: 10px;
  padding-bottom: 10px;
}
*/

@media only screen and (min-width: 768px)
{
  .newswidget
  {
    display: flex;
  }

  .newswidget-overview
  {
    display: flex;
    flex-direction: column;

    width: 50%;
    max-width: 470px; /* keep right edge in line with the content */
  }
}




.newswidget-overview .flex-filler
{
  min-height: 20px;
}

.newswidget-title
{
  margin: 0 0 20px 0 !important;
  text-transform: uppercase;
}
.newswidget-items
{
  flex: 1;
}
.newswidget-options
{
  text-align: right;
}

a.newswidget-item
{
  display: block;
  text-decoration: none;
  color: inherit;

  padding: 10px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}
.newswidget-item:first-child
{
  padding-top: 0;
}

.newswidget-item-title
{
  font: 500 17px/18px "Univers Next W02";
  text-decoration: none;
}

  .downloadwidget-item-title
, .linkswidget-item-title
{
  font: 500 17px/18px "Univers Next W02";
  margin-bottom: 5px;
}



.newswidget-item-date
{
  font: 300 14px "Univers Next W02";
  margin-bottom: 2px;
  letter-spacing: 0.5px;
  /*color: #FFFFFF;*/
}

/* NOTE: be more specific than .rtdcontent a */
a.newswidget-showmorelink
{
  font: 500 15px "Univers Next W02";
  color: inherit;
  margin-top: 20px;
  text-transform: uppercase;
  text-decoration: none;
}
.site-fullwidth a.newswidget-showmorelink
{
  font: 500 18px "Univers Next W02";
}

/* since .site-fullwidth only has an effect on the size when we go beyond 940px
   only use larger fonts with 940px+
*/
@media only screen and (min-width: 940px)
{
  .site-fullwidth .newswidget-title
  {
    margin: 0 0 40px 0 !important;
    text-transform: uppercase;
  }

  .site-fullwidth .newswidget-item
  {
    padding: 15px 0;
  }

  .site-fullwidth .newswidget-item-title
  {
    font: 500 24px/26px "Univers Next W02";
  }

  .site-fullwidth .newswidget-item-date
  {
    font: 300 14px "Univers Next W02";
    letter-spacing: 0.5px;
  }
}







.videowidget
{
  position: relative;
  z-index: 0;
}

.videowidget-content
{
  position: relative;
  width:    100%; /* for inheriting an explicit width so the padding-top % on .video_minsize works */
}

.widget-video--narrow
{
  display: flex;
}

.widget-video--narrow .videowidget-content
{
  flex: 0 0 calc(70% - 15px);
}

.widget-video--narrow .videowidget-description
{
  flex: 0 0 calc(30% - 15px);
}



/* force aspect ratio */
.video_minsize
{
  padding-top: 56.25%; /* 16:9 aspect ratio */;
}




.videowidget .videowidget-video
{
  background-size: cover;
  background-position: 50% 50%;
}

.videowidget .videocontainer iframe
{
  /* block is important so no extra line-height room is created below the iframe.
     (This would making the iframe larger than our padding-top aspect ratio trick reserves for us
  */
  display: block;
  z-index: 1; /* over the .video_minsize container, so the user can interact with the video */
}

@media only screen and (min-width: 940px)
{
  .videowidget-content
  {
    display: flex;
  }

    .videowidget-description
  , .videowidget .videowidget-video
  {
    flex: 1;
  }
}

.videowidget-description
{
  margin-left: 30px;
}

/* A little bit of padding for a 'normal layout' + specific themecolor video widget
   In case of no themecolor or wide layout the default '.rtdcontent-mainflow > *' selector will be triggered to use
   the default padding.
*/
.videowidget-description > *
{
  padding-left: 15px;
  padding-right: 15px;
}

.videowidget-description:empty
{
  display: none;
}

.videowidget .videowidget-video
{
  position: relative;
}

.videowidget .videowidget-video > img
{
  display: block;
  width: 100%;
}

.videowidget .videocontainer
{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  pointer-events: none;
}
.videowidget.active .videocontainer
{
  z-index: 3;
  pointer-events: auto;
}

.videowidget .playvideobutton
{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
}




.ks--homecontent .fs_h2
{
  text-align: center;

  /* spacings like: .document-toplevel.rtdcontent-mainflow .rtdcontent-singlecolum */
  padding-top: 32px;
  margin-bottom: 32px;
}
a.ksh__location
{
  text-decoration: none !important; /* important for RTD editor */
}
.ksh__location__meta
{
  text-align: center;
  padding: 27px 30px 30px 30px;
}

.location__image
{
  background-size: cover;
}

.location__title
{
  font: 500 30px "Univers Next W02";
  text-transform: uppercase;
  color: #333333;
}
.location__roomscount
{
  font: normal 14px Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}


/* only user left is Kamersite Locations widget */
.sugrid
{
  display: flex;
  flex-flow: row wrap; /* flex-wrap: wrap */

  margin-left: -15px;
}

/* 940, */
.sugrid__item
{
  background-color: #FFFFFF;

  width: 100%;
  margin-bottom: 20px;

  margin-left: 15px;

  /*
  transform: scale(1);
  opacity: 1;

  transition: opacity 0.5s, transform 0.5s;
  */
}
@media only screen and (min-width: 640px)
{
  #ksh__locations.sugrid
  {
    margin-left: -15px;
    margin-right: -15px;
  }

  .sugrid__item
  {
    width: calc(100% * (1/2) - 15px);
    margin-bottom: 20px;
  }
  #ksh__locations.sugrid .sugrid__item
  {
    width: calc(100% * (1/2) - 20px);
  }

}

@media only screen and (min-width: 800px)
{
  .sugrid__item
  {
    width: calc(100% * (1/3) - 15px);
    margin-bottom: 20px;
  }
  #ksh__locations.sugrid .sugrid__item
  {
    width: calc(100% * (1/3) - 20px);
  }
}
