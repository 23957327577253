.room__header
{
  color: #FFFFFF;
}



#site-pageheader > .room__header
{
  pointer-events: none;
}
  #site-pageheader > .room__header a
, #site-pageheader > .room__header .ctabutton
, #site-pageheader > .room__header .room__header__slidescount
{
  pointer-events: auto;
}



/*
.room__header
{
  / * make this a container to inherit our 100% height from * /
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.room__header > .site-centered-top
{
  position: relative;
  height: 100%;
}
*/





.room__overview
{
}

.roomspecvalue
{
  color: #000000;
}

@media only screen and (min-width: 750px)
{
  .room__overview__specsheet
  {
    display: flex;
  }
  .column.room__overview__specs__misc
  {
    white-space: nowrap;
    flex: 1;
  }
  .column.room__overview__specs__facilities
  {
    flex: 2;
    margin-left: 40px;
  }
}



.description__fallbackwarning
{
  padding: 5px 8px;
  background-color: #FFFFEE;
  margin-top: -10px;
  margin-bottom: 10px;
}

.room__overview__description
{
}

/* right column */
@media only screen and (max-width: 939px)
{
  /* on small screens we want to prevent
     touch and scrollwheel events being eaten
     by Google Maps
  */
  #room__map
  {
    pointer-events: none;
  }
}

.ks__column2.ks__column2.ks__column2.ks__column2 /* temp FIX 2020 */
{
  padding-right: 0;
}


.ks__column2.ks__column2 h2
{
  padding: 0;
  margin: 30px 0 5px 0;
}
.ks__column2 .ctabutton
{
  margin-top: 50px;
  text-decoration: none;
  width: 100%;
}


.room__share
{
  margin-top: 25px;
}
.room__share .sharebutton
{
  border-radius: 5px;
  color: #FFFFFF;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;

  background-color: #252525;

  font: 500 14px "Univers Next W02";

  cursor: pointer;
}
  .room__share .sharebutton:active
, html.allowhover .room__share .sharebutton:hover
{
  background-color: #3A3A3A;
}
.room__share .sharebutton > .fab
{
  position: relative;
/*  width: 37px;*/
  height: 40px;

  border-right: 1px solid #4C4C4C;
  color: #FFFFFF;
  vertical-align: middle;
  margin-right: 12px;

  font-size: 23px;
  text-align: center;
  line-height: 40px;
}
.room__share .sharebutton > .fab:before
{
  display: inline-block;
  border-right: 1px solid #0C0C0C;
  width: 37px;
  height: 40px;
  line-height: 40px;
}


.room__share .sharebutton + .sharebutton
{
  margin-top: 10px;
}



#room__page .videowidget
{
  margin-top: 40px;
}




.room__page__navigation
{
  display: none;
  margin-top: 40px;
}
.room__page__navigation.show
{
  display: flex;
  align-items: center;
}

.room__page__count
{
  margin-left: 15px;
  margin-right: 15px;
}

  .room__page__previous
, .room__page__next
{
  display: block;
  flex: 1;

  position: relative;

  font: 14px "Univers Next W02";
  background-color: #FFFFFF;

  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  text-decoration: none;

  text-align: center;

  color: #D1D1D1;
}
  .room__page__previous.enabled
, .room__page__next.enabled
{
  color: #000000;
}

  .room__page__previous > .far
, .room__page__next > .far
{
  height: 60px;
  line-height: 60px;
  font-size: 20px;
}

.room__page__previous > .far
{
  position: absolute;
  left: 20px;
}
.room__page__next > .far
{
  position: absolute;
  right: 20px;
}

.room__page__next
{
  margin-left: 5px;
}





#room__page .su-googlemap
{
  position: relative;
  /* using the padding-top trick for aspect ratio seems to confuse Google Maps */

  background-color: #AAAAAA;
  margin-top: 40px;
}

@media only screen and (max-width: 939px)
{
  #room__page .su-googlemap
  {
    height: 400px;
  }
}
@media only screen and (min-width: 940px)
{
  #room__page .su-googlemap
  {
    height: 200px;
  }
}



.room__manage__page
{
  display: none;
}
.room__manage__page.active
{
  display: flex;
  align-items: center;
}

.room__manage__description
{
  flex-grow: 1;
}
.room__manage__buttons
{
  flex-shrink: 0;
}


span.ctabutton
{
  display: inline-block;
  background-color: #fd7723;
  color: #FFFFFF;
  padding: 5px 15px;
  cursor: pointer;
}


.room__manage__options .room__manage__button + .room__manage__button
{
  margin-left: 30px;
}



.room__manage
{
  background-color: #FFFF66;
  padding: 15px 0;

}
 .room__manage__button
, a.room__manage__button
{
  color: #fd7723;
  cursor: pointer;
  text-decoration: none;
}

.room__manage__options
{
}
.room__manage__edit
{

}
.room__manage__remove
{

}
.room__manage__prolong
{

}
