/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
.page__contentarea,
.wh-form--webtoolform {
  display: grid;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
  grid-auto-flow: row;
}

@media (max-width: 1023px) {
  .page__contentarea--formwebtool > .page-header__title,
  .page__contentarea--formwebtool > *,
  .page__contentarea--autoalign > * {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) and (max-width: 1023px) {
  .page__contentarea--formwebtool > .page-header__title,
  .page__contentarea--formwebtool > *,
  .page__contentarea--autoalign > * {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .page__contentarea--formwebtool > .page-header__title,
  .page__contentarea--formwebtool > *,
  .page__contentarea--autoalign > * {
    grid-column: 7/span 19;
  }
}
.page__contentarea--formwebtool > .wh-form {
  grid-column: 1/span 31;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
}

/* Support grid layout for formwebtool documents

   - Don't use a wrapper, becauce it will override the specificy and therefore overriden the layout of all widgets!
   - However in the future we may need to change the widgets CSS (to have a higher specificy) so we can support other .wh-form usage too

   - In the future we may be able to use :where(.page__contentarea > .wh-form), because the selectors in :where have 0 specificy

*/
@media (max-width: 1023px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) and (max-width: 1023px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: 7/span 19;
  }
}

.wh-form--webtoolform,
.wh-form--webtoolform .wh-form__page,
.wh-form--webtoolform .wh-form__fieldgroup--richtext {
  grid-column: 1/span 31;
}

.wh-form--webtoolform .wh-form__richtext {
  width: 100%;
}

.wh-form--webtoolform,
.wh-form--webtoolform .wh-form__page--visible,
.wh-form--webtoolform .wh-form__richtext {
  display: grid;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
  grid-auto-flow: row;
}

main {
  display: block;
}

@media (max-width: 1024px) {
  html.pageheader--inflow .deeplinks-wrapper {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1025px) {
  html.pageheader--inflow .page__contentarea {
    padding-top: 50px;
  }
}

@media (max-width: 1024px) {
  html.pageheader--block .deeplinks-wrapper {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1025px) {
  html.pageheader--block .page__contentarea--rtddoc > :first-child {
    margin-top: 50px;
  }
  html.pageheader--block .page__contentarea--rtddoc > .deeplinks-wrapper > .deeplinks {
    /*
    NOTES:
    - don't use margin-top or padding-top on .deeplinks_wrapper, it'll stretch the first row creating a white space
    - by applied padding-top (or margin-top) to the deeplinks (which are taken out of flow) we can create a distance and still allow the first widget in the RTD to stick to the header
    */
    padding-top: 50px;
  }
}
html.pageheader--block .page__contentarea:not(.page__contentarea--rtddoc) {
  padding-top: 50px;
}
html.pageheader--block.page--eventsoverview .page__contentarea, html.pageheader--block.page--newsoverview .page__contentarea, html.pageheader--block.page--resourcesoverview .page__contentarea {
  padding-top: 0;
}

.page__contentarea .page__balloon {
  grid-column: 1/span 31;
  height: 80px;
}
@media (max-width: 500px) {
  .page__contentarea .page__balloon {
    height: 30px;
  }
}

.widget--opaque + .page__balloon {
  display: none;
}

body > .page__balloon {
  flex: 1 0 auto;
  height: 80px;
}
@media (max-width: 500px) {
  body > .page__balloon {
    height: 30px;
  }
}

.page__contentarea.headerisopaque > .widget--opaque:first-child,
.page--withfilterpanel > .widget--opaque:first-child,
html.pageheader--block .page__contentarea .widget--opaque:first-child {
  margin-top: 0;
}

html.pageheader--inflow .page__contentarea > :first-child,
html.pageheader--block .page__contentarea > :first-child {
  margin-top: 30px;
}

.page__contentarea .widget--opaque:last-child {
  margin-bottom: 0;
}

.widget--opaque + .widget--opaque {
  margin-top: 0;
}