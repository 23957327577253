/*@import "../shared/config.scss";*/
html {
  --sidebar-menu-itemhover-background: var(--color-theme);
  --sidebar-menu-itemhover-textcolor: #FFFFFF;
  --sidebar-menu-itemhover-textdecoration: none;
  --sidemainmenu-padding-top: 0; /* we have our header align to the side header instead */
  --sidemainmenu-padding-right: 17px;
  --sidemainmenu-padding-bottom: 17px;
  --sidemainmenu-padding-left: 17px;
  --sidebar-content-indent: 30px;
  --sidebar-closebutton-color: var(--color-purple);
  /*--sidebar-closebutton-size:  24px;*/
  --sidebar-closebutton-size: 20px;
  --sidemainmenu-toggler-width: 22px;
  --sidemainmenu-toggler-margin: 20px;
  --sidebar-menu-toggle-color: #000000;
  --sidebar-menu-toggle-color-hover: #FFFFFF;
  --sidebar-menu-toggle-fontsize: 15px;
  /* Togglers level 1 */
  --sidebar-menu-level1-toggleicon-font: var(--fontawesome-regular);
  --sidebar-menu-level1-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level1-toggleicon-expanded-content: var(--fa-chevron-down);
  /* Togglers level 2 */
  --sidebar-menu-level2-toggleicon-font: var(--fontawesome-regular);
  --sidebar-menu-level2-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level2-toggleicon-expanded-content: var(--fa-chevron-down);
  /* Togglers level 3 */
  --sidebar-menu-level3-toggleicon-font: var(--fontawesome-regular);
  --sidebar-menu-level3-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level3-toggleicon-expanded-content: var(--fa-chevron-down);
  /*
  --sidebar-secondarylinks-color:    #000000;
  --sidebar-secondarylinks-font:     16px var(--rtd-heading-fontfamily);
  */
  --sidebar-secondarylinks-color: var(--color-theme);
  --sidebar-secondarylinks-font: 15px var(--rtd-text-fontfamily);
  --sidebar-menu-level1-font: 700 16px/19px "Univers Next W02";
  --sidebar-menu-level1-padding: 9px 20px 8px calc( var(--sidemainmenu-padding-right) + 15px + 12px - 4px);
  --sidebar-menu-level1-expandedbgcolor: transparent;
  --sidebar-menu-level1-textcolor: #000000;
  --sidebar-menu-level2-font: 500 16px/19px "Univers Next W02";
  --sidebar-menu-level2-padding: 9px 20px 8px 57px; /*calc(var(--sidemainmenu-padding-right) + 35px);*/
  --sidebar-menu-level2-expandedbgcolor: transparent;
  --sidebar-menu-level2-textcolor: #000000;
  --sidebar-menu-level3-font: 500 16px/19px "Univers Next W02";
  --sidebar-menu-level3-padding: 9px 20px 8px 93px;
  --sidebar-menu-level3-textcolor: #000000;
  --sidebar-menu-level4-font: 500 16px/19px "Univers Next W02";
  --sidebar-menu-level4-padding: 9px 20px 8px 129px;
  --sidebar-menu-level4-textcolor: #000000;
}