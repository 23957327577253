html {
  --sidemainmenu-padding-bottom: ;
}

#slidemenu {
  padding-bottom: var(--sidemainmenu-padding-bottom);
  --sidebar-groups-spacing: 25px;
}

.sidebar__header {
  display: flex;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
  padding-top: var(--sidemainmenu-padding-top);
}

.sidebar__header__close {
  margin-left: auto;
  flex: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  margin-right: -15px; /* keep right side aligned */
  color: #757575;
  cursor: pointer;
  margin-left: var(--sidemainmenu-padding-left);
}

.sidebar__header__close.sidebar__header__close:hover {
  color: #000000;
}

.sidebar__header__close::before {
  width: auto; /* override WIG <button> styling */
  height: auto; /* override WIG <button> styling */
  font: var(--fontawesome-light);
  content: var(--fa-times);
  font-size: var(--sidebar-closebutton-size);
  color: var(--sidebar-closebutton-color);
  margin-right: 12px;
}

/*
.sidebar hr
{
  margin-top:    var(--sidebar-groups-spacing);
  margin-bottom: var(--sidebar-groups-spacing);

  margin-left:   calc( var(--sidemainmenu-padding-left) + var(--sidebar-content-indent) );

  border: 0;
  border-top: 1px solid #D7D7D7;
}

.sidebar .sidebar__header + hr
{
  margin-top: 0;
}
*/
.sidebar__sociallinks {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: auto;
}

.sidebar__sociallinks a.fab {
  font-size: 25px;
  cursor: pointer;
  transform: scale(1);
  color: var(--color-theme);
  text-decoration: none;
  transition: transform 0.15s, text-shadow 0.45s;
}

.sidebar__sociallinks .fab + .fab {
  margin-left: 11px;
}

.sidebar__sociallinks .fab:hover {
  transform: scale(1.3);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}

.sidebar__relatedwebsites {
  margin-top: 35px;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__relatedwebsites a {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--sidebar-menu-level1-textcolor);
  font: var(--sidebar-menu-level1-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level1-padding);
}

.sidebar__relatedwebsites a::before {
  position: absolute;
  left: 0;
  font: var(--fontawesome-solid);
  content: var(--fa-external-link-alt);
  font-size: 13px;
}

.sidebar__relatedwebsites a.iscurrent::before {
  content: var(--fa-chevron-right);
}

.sidebar__relatedwebsites a:hover {
  text-decoration: underline;
}

.sidebar__secondarylinks {
  margin-left: calc(var(--sidemainmenu-padding-left) + var(--sidebar-content-indent));
  margin-right: var(--sidemainmenu-padding-right);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__copyright,
.sidebar__secondarylinks a {
  color: var(--sidebar-secondarylinks-color);
  font: var(--sidebar-secondarylinks-font);
  text-decoration: none;
  padding-top: 3px;
  padding-bottom: 3px;
}

.sidebar__secondarylinks a:hover {
  text-decoration: underline;
}

.sidebar__secondarylinks a + a {
  margin-top: 5px;
}

.sidebar > .sidebar__languages {
  margin-top: var(--sidebar-groups-spacing);
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
}

.sidebar__languages {
  display: flex;
  align-items: center;
  color: var(--rtd-text-color);
  font: 16px var(--rtd-heading-fontfamily);
}

.sidebar__languages a,
.sidebar__languages span {
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.sidebar__languages span.selected {
  text-decoration: none;
}