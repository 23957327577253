/*

Gray

Normal  / Hover   / Down    / CTAButton / CTAButton-hover
#3c3f4c / #474b5a / #2b2e38 / #010007   / #171922

*/

  .sitetheme--gray
, .theme--gray
{
  --themecolor:       #3c3f4c;
  --themecolor-dark:  #2b2e38;
  --themecolor-light: #474b5a; /* #{$themecolor-blue-light}; */
  /*--themecolor-transparent: rgba(60, 63, 76, 0.4);*/
}

.sitetheme--gray
{
  --sitethemecolor:       #3c3f4c;
  --sitethemecolor-dark:  #2b2e38;
  --sitethemecolor-light: #474b5a; /* #{$themecolor-blue-light}; */
}


  .rtdcontent.tc-gray > p a
, .rtdcontent.tc-gray > ul a
, .rtdcontent.tc-gray > ol a
, .rtdcontent.tc-gray > h1
, .rtdcontent.tc-gray > h2
, .rtdcontent.tc-gray > h3
, .rtdcontent.tc-gray > h4

, .tc-gray .wh-form__richtext > p a
, .tc-gray .wh-form__richtext > ul a
, .tc-gray .wh-form__richtext > ol a
, .tc-gray h1
, .tc-gray h2
, .tc-gray h3
, .tc-gray h4

, .wh-rtd-editor.tc-gray > p a
, .wh-rtd-editor.tc-gray > ul a
, .wh-rtd-editor.tc-gray > ol a
, .wh-rtd-editor.tc-gray > h1
, .wh-rtd-editor.tc-gray > h2
, .wh-rtd-editor.tc-gray > h3
, .wh-rtd-editor.tc-gray > h4

, .tc-gray-fg-dark.tc-gray-fg-dark
, .tc-gray-fg-dark-ahover a:hover
, .themeclass.tc-gray-apply a

, html.tc-gray .wh-form__grouptitle
{
 color: #3c3f4c;
}




  .tc-gray-bg-dark
, .wh-rtd-editor.tc-gray-bg-dark
{
  background-color: #3c3f4c;
  color: #FFFFFF;
}

.tc-gray-bc-dark
{
  border-color: #3c3f4c;
}

/* allow the dark anchor to work on itself and two levels deeper */
  .tc-gray-ac-dark
, a.tc-gray-ac-dark
, .tc-gray-ac-dark > a
, .tc-gray-ac-dark > * > a
{
  color: #3c3f4c;
}
  .tc-gray-ac-dark:hover
, a.tc-gray-ac-dark:hover
, .tc-gray-ac-dark > a:hover
, .tc-gray-ac-dark > * > a:hover
{
  color: #474b5a;
}
  .tc-gray-ac-dark:active
, a.tc-gray-ac-dark:active
, .tc-gray-ac-dark > a:active
, .tc-gray-ac-dark > * > a:active
{
  color: #2b2e38;
}

/* buttons (on white) */
  .tc-gray-darkhover
, /*.document-toplevel*/.tc-gray > .embeddedobject-inflow .tc--darkhover /* use 'pagetheme' on buttons at toplevel */
, .blockswidget-item-meta .blockswidget-item-link.tc-gray-darkhover
{
  background-color: #3c3f4c;
  color: #FFFFFF;
  transition: background-color 0.15s;
}
  .tc-gray-darkhover:hover
, .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-gray-darkhover
{
  background-color: #474b5a;
}
.tc-gray-darkhover:active
{
  background-color: #2b2e38;
}
.tc-gray-darkhover:focus
{
  outline: 0;
  box-shadow: 1.5px 1.5px 3px rgba(0,0,0,0.4);
}

/* buttons widget (on a dark background) */
  .used_on_dark_background .buttonwidget .tc-gray-darkhover
, .tc-gray-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover
, .used_on_dark_background .blockswidget-metaunder .blockswidget-item-link.tc-gray-darkhover
{
  background-color: #010007;
  color: #FFFFFF;
}
  .used_on_dark_background .buttonwidget .tc-gray-darkhover:hover
, .tc-gray-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover:hover
, .used_on_dark_background .blockswidget-metaunder .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-gray-darkhover
{
/*  background-color: #171922; - uit design? te donker */
  background-color: #252525;
}
/* hover and focus the same as on white bg ?? */




/*
  --themecolor:       #3c3f4c;
  --themecolor-dark:  #2b2e38;
  --themecolor-light: #474b5a; / * #{$themecolor-blue-light}; * /
*/

/* Solid button styles */
  .ctabutton--autostyle.tc-gray                 /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-gray                     /* explicitely solid */
, html.tc-gray .ctabutton--autostyle.tc-auto    /* widget inheriting the color from the page themecolor */
, html.tc-gray .ctabutton--solid.tc-auto        /* widget inheriting the color from the page themecolor */
, html.tc-gray .wh-form__button                 /* Form webtool */
, .widget-blocks.theme--gray .blockswidget__item__button
{
  background-color: #3c3f4c;
  /*background-color: var(--themecolor);*/
  color: #FFFFFF;
}

  .ctabutton--autostyle.tc-gray:hover /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-gray:hover /* at top level (not in color background) default to solid */
, html.tc-gray .ctabutton--autostyle.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, html.tc-gray .ctabutton--solid.tc-auto:hover /* widget inheriting the color from the page themecolor */
, html.tc-gray .wh-form__button:hover          /* Form webtool */
, .widget-blocks.theme--gray .blockswidget__item:hover .blockswidget__item__button
{
  background-color: #2b2e38;
  /*background-color: var(--themecolor-dark);*/
}


/* Colored outline button styles */
  .ctabutton--outline.tc-gray
, html.tc-gray .ctabutton--outline.tc-auto  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-gray /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-gray /* without a contentblock with backgroundcolor and explicit color selection */
{
  background-color: #FFFFFF;
  color: #3c3f4c;
  /*color: var(--themecolor);*/

  border: 2px solid #3c3f4c;
  /*border: 2px solid var(--themecolor);*/
}

  .ctabutton--outline.tc-gray:hover
, html.tc-gray .ctabutton--outline.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-gray:hover /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-gray:hover /* without a contentblock with backgroundcolor and explicit color selection */
{
  /*background-color: var(--themecolor);*/
  background-color: #3c3f4c;
  color: #FFFFFF;
}
