/* specific for roomsubmission form */

.roomsubmissionform
{
  padding: 15px;
  background-color: #fff;
}
.roomsubmissionform > form
{
  margin: 0 auto;
  opacity: 0.2;
}
.roomsubmissionform > form.wh-form--enabled
{
  opacity: 1;
}

.rpc__overlay
{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  color: rgba(255,255,255,0.8);
}

  #roomsubmission-house_rooms
, #roomsubmission-house_males
, #roomsubmission-house_females
{
  /*
  This fails in Safari
  width: 100px;
  flex: 0;
  */
  flex: 1;
  max-width: 100px;
}

.wh-form__fieldgroup--imagelist
{
  flex-direction: column;
}




/************************************************

Tabs

************************************************/

.wh-form__tabs
{
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  height: 50px;
  text-align: center;
}
.wh-form__tabs > li
{
  display: inline-block;
  text-transform: uppercase;
  color: #555;
  font: normal 14.5px/15px "Univers Next W02", Arial;
  padding: 22px 0 12px;
  margin: 0 20px;
  cursor: pointer;
  position: relative;
  border-bottom: 3px solid transparent;
}
.wh-form__tabs > li.disabled
{
  cursor: default;
  color: #ccc;
}
.wh-form__tabs > li.active
{
  border-color: #5078b4;
}
form.wh-form--enabled .wh-form__tabs > li:not(.disabled):hover
{
  border-color: #5078b4;
}


/************************************************

Pages

************************************************/

.wh-form .wh-page
{
  display: none;
}
.wh-form .wh-page.active
{
  display: block;
}


/************************************************

Images list

************************************************/

.wh-form .imagelist
{
  margin-top: 10px;
  width: 100%;
  max-width: 590px;
}
.wh-form .imagelist > .image
{
  margin-top: 10px;
  display: block;
  min-height: 40px;
  position: relative;
}
.wh-form .imagelist .delete
{
  display: none;
}
.wh-form .imagelist .image__info
{
  position: absolute;
  top: 5px;
  left: 0;
  padding-left: 110px;
}
.wh-form .imagelist .fa-trash-o
{
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
.wh-form .imagelist .image > img
{
  width: 100px;
  height: auto;
}


/************************************************

Overview

************************************************/

.wh-form .formdataoverview table
{
  border-collapse: collapse;
  padding: 0;
  margin: 0 0 25px 0;
}
  .wh-form .formdataoverview th
, .wh-form .formdataoverview td
{
  vertical-align: top;
  text-align: left;
  padding: 3px 10px 0 3px;
}
.wh-form .formdataoverview .title
{
  font-size: 18px;
  padding-top: 25px;
  padding-bottom: 5px;
}
.wh-form .formdataoverview .title::after
{
  display: block;
  content: "";
  margin-top: 10px;
  border-bottom: 1px solid #000000;
}

/* FIXME: only meant for image lists ?? - add a BEM class for this purpose */
.wh-form .formdataoverview img
{
  width: 100px;
  height: auto;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;

  vertical-align: top;
}
