/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
.widget--layout-wide {
  grid-column: 3/30;
}

@media (max-width: 1023px) {
  .widget--layout-default {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) {
  .widget--layout-default {
    grid-column: 7/span 19;
  }
}

.widget--bgwrapper.widget--bgwrapper {
  grid-column: 1/span 31;
}

.footer {
  display: grid;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
  position: relative;
  color: #FFFFFF;
}

.footer::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  grid-column: 1/span 31;
  grid-row: 1/span 4;
  background: url("../web/img/footer-dandelion.png") no-repeat top left, linear-gradient(190deg, #87bce6 0%, #3a74ba 50%, #3a74ba 100%);
}

.footer__newsletter,
.footer__content {
  position: relative;
  z-index: 1;
  grid-column: 1/span 31;
}

.footer__newsletter {
  grid-row: 1;
  background-color: rgba(36, 65, 106, 0.29);
  padding: 15px 0;
  color: #FFFFFF;
  display: grid;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
}

.footer__newsletter__content {
  grid-row: 2;
}
@media (max-width: 1023px) {
  .footer__newsletter__content {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) {
  .footer__newsletter__content {
    grid-column: 7/span 19;
  }
}

.footer__content {
  padding-top: 50px;
  grid-row: 2;
  z-index: 1;
  grid-column: 1/span 31;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
}

.footer__logoimage--floating {
  grid-column: 22/span 7;
  grid-row: 1;
  width: 100%;
}

.footer__logoimage--sticktoedge {
  grid-column: 23/span 9;
  grid-row: 1/span 2;
  width: 100%;
  max-width: 410px;
  align-self: end;
  justify-self: end;
}

@media (min-width: 1024px) {
  .footer__subscribeform__intro {
    font: 500 18px/24px "Univers Next W02";
  }
  .footer-column1 {
    grid-column: 7/span 7;
    padding-bottom: 60px;
  }
  .footer-column2 {
    grid-column: 15/span 7;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .footer__subscribeform__intro {
    font: 500 16px/22px "Univers Next W02";
  }
  .footer-column2 {
    margin-top: 30px;
    grid-column: 7/span 11;
    grid-row: 2;
    padding-bottom: 60px;
  }
  .footer__logoimage--floating {
    grid-column: 20/span 9;
    grid-row: 1;
    width: 100%;
  }
  .footer__logoimage--sticktoedge {
    grid-column: 18/span 13;
    grid-row: 1/span 2;
    width: 100%;
    max-width: 410px;
    align-self: end;
    justify-self: end;
  }
}
@media (max-width: 1023px) {
  .footer__subscribeform__intro {
    font: 500 16px/22px "Univers Next W02";
  }
  .footer-column1,
  .footer-column2 {
    grid-column: 3/span 11;
  }
  .footer-column2 {
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .footer__logoimage--floating {
    grid-column: 20/span 9;
    grid-row: 1;
    width: 100%;
  }
  .footer__logoimage--sticktoedge {
    grid-column: 18/span 13;
    grid-row: 1/span 2;
    width: 100%;
    max-width: 410px;
    align-self: end;
    justify-self: end;
  }
}
@media (max-width: 640px) {
  .footer__subscribeform__intro {
    font: 500 16px/22px "Univers Next W02";
  }
  .footer-column1,
  .footer-column2 {
    grid-column: 3/30;
  }
  .footer__logoimage--floating {
    grid-row: 3;
    grid-column: 3/30;
    width: 100%;
  }
  .footer__logoimage--sticktoedge {
    grid-row: 3;
    grid-column: 1/span 31;
    width: 100%;
    max-width: 410px;
    align-self: end;
    justify-self: end;
  }
}
.footer__newsletter__subscribe__heading {
  font: 700 24px/28px "Univers Next W02";
}

.footer__subscribeform__email {
  position: relative;
  display: inline-flex;
  border: 1px solid #FFFFFF;
  border-radius: 41px;
  opacity: 0.81;
  padding: 1px;
}

.footer__subscribeform__email:focus-within {
  opacity: 1;
  outline: 0;
  border: 2px solid #FFFFFF;
  padding: 0px;
}

/* Duplicate specifically for IE (don't merge) */
.footer__subscribeform__email.focus-within {
  opacity: 1;
  outline: 0;
  border: 2px solid #FFFFFF;
  padding: 0px;
}

.footer__subscribeform__email {
  width: 305px;
}

.footer__subscribeform__email input {
  border: 0;
  background: transparent !important;
  color: #FFFFFF;
  font-size: 16px; /* Minimum 16px so iOS won't zoom into the input */
  height: 47px;
  flex: 1 1 0px;
  width: 100%;
  outline: 0;
  padding-left: 17px;
}
.footer__subscribeform__email input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFFFFF;
  opacity: 0.56; /* Firefox */
}

.footer__subscribeform__email button {
  flex: none;
  outline: 0;
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 20px;
}

.footer__subscribeform__email input:-webkit-autofill {
  background-color: transparent !important;
}

.footer__subscribeform__email__button {
  font-size: 22px;
  color: #FFFFFF;
}

@media (min-width: 641px) {
  .footer__newsletter__subscribe {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .footer__subscribeform__email {
    margin-left: 20px;
  }
}
@media (max-width: 640px) {
  .footer__subscribeform {
    margin-top: 15px;
  }
}
.footer__subscribeform {
  margin-left: auto;
}

.footer__newsletter__subscribedmessage {
  display: none;
}

.footer--subscribed .footer__newsletter__subscribe {
  display: none;
}

.footer--subscribed .footer__newsletter__subscribedmessage {
  display: block;
}

#footer__columns {
  display: flex;
}

p.footerheading {
  font: 700 20px "Univers Next W02";
  text-transform: uppercase;
  margin-bottom: 0;
}

p.footerheading + * {
  margin-top: 5px;
}

.footer__content .rtdcontent p,
.footer__content .rtdcontent ol,
.footer__content .rtdcontent ul {
  font-size: 16px;
  line-height: 24px;
}
.footer__content .rtdcontent a {
  color: #FFFFFF;
  text-decoration: none;
}
.footer__content .rtdcontent a:hover {
  text-decoration: underline;
}

#footer-sociallinks {
  margin-top: 20px;
}

#footer-sociallinks {
  display: flex;
}

#footer-sociallinks a {
  text-decoration: none;
}

#footer-sociallinks .fab {
  font-size: 25px;
  cursor: pointer;
  transform: scale(1);
  color: #FFFFFF;
  transition: transform 0.15s, text-shadow 0.45s;
}

#footer-sociallinks .fab + .fab {
  margin-left: 11px;
}

#footer-sociallinks .fab:hover {
  transform: scale(1.3);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}

.footer-column--logo {
  flex: 1 0 0px;
  background-color: rgba(255, 255, 0, 0.2);
}

.footer__subscribeform__email input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.footer__subscribeform__email input:-webkit-autofill + button {
  background: rgba(255, 255, 255, 0.09);
}

@-webkit-keyframes autofill {
  to {
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.09);
  }
}