.wh-styledinput-toggle input[type="checkbox"]
{
  border: 1px solid #f00;
  display: none;
}

.wh-styledinput-toggle input[type="checkbox"] + label
{
  display: inline-block;

  position: relative;
  width: 67px;
  height: 30px;
  border-radius: 15px;
  background-color: #e0e4e8;

  cursor: pointer;

  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.wh-styledinput-toggle input[type="checkbox"] + label::before
{
  content: "";

  position: absolute;
  left: 8px;
  top: 6px;

  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fd7723;

  transition: left 0.2s;
}

.wh-styledinput-toggle input[type="checkbox"]:checked + label::before
{
  left: calc(100% - 18px - 8px);
}
