/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
/*

Used in SU (2020), 4TU (2020), inGenious, JZOJP, TUE

If Internet Explorer 11 wouldn't need to be supported we could:
- use grid + grid-gap + autoplacement (IE doesn't support grid gap, but autoplacement could be fakes using nth-child and setting a column+row or using a polyfill script)
- use flex and CSS vars for columns (can we use them in media queries?)

In the future we could also use flex + gap (FF63+ (oct 2018), Chrome ?)


NOTES:
- We use width instead of flex-basis because IE doesn't support calc() in flex-basis

*/
.neo__filterblock__filters {
  grid-column: 1/span 31;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: #F3F3F1;
}

.neo__filterblock__filters__content {
  grid-column: 3/30;
}

@media (min-width: 1000px) {
  .neo__filterblock__filters__content {
    grid-column: 3/30;
  }
  .neo-listfilters {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 18px;
    column-gap: 30px;
    row-gap: 18px;
    grid-auto-flow: column row;
  }
  .filtergroup__title {
    text-transform: uppercase;
    font: 500 20px "Univers Next W02", Arial;
    flex: none;
    width: 100%;
    max-width: 100%;
  }
  .neo__filterblock__tagsheading {
    grid-column: 1/span 3;
    grid-row: 1;
  }
  .neo__filterblock__sector {
    grid-column: 1;
    grid-row: 2;
  }
  .neo__filterblock__activity {
    grid-column: 2;
    grid-row: 2;
  }
  .neo__filterblock__channel {
    grid-column: 3;
    grid-row: 2;
  }
  .neo__filterblock__periodheading {
    grid-column: 1/span 3;
    grid-row: 3;
  }
  .neo__filterblock__startdate {
    grid-column: 1;
    grid-row: 4;
    width: calc(50% - 12px);
  }
  .neo__filterblock__enddate {
    grid-column: 1;
    grid-row: 4;
    width: calc(50% - 12px);
    justify-self: end;
  }
  .neo__filterblock__archive {
    grid-column: 3;
    grid-row: 4;
    justify-self: end;
    align-self: center;
  }
}
@media (max-width: 768px) {
  .neo-listfilters {
    display: flex;
    flex-wrap: wrap;
  }
  .neo__filterblock__tagsheading,
  .neo__filterblock__periodheading,
  .neo-listfilters > * {
    width: 100%;
  }
  .neo-listfilters > * + * {
    margin-top: 15px;
  }
  .neo__filterblock__startdate {
    width: calc(50% - 12px);
  }
  .neo__filterblock__enddate {
    margin-left: auto;
    width: calc(50% - 12px);
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .neo-listfilters {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 18px;
    column-gap: 30px;
    row-gap: 18px;
    grid-auto-flow: column row;
  }
  .filtergroup__title {
    text-transform: uppercase;
    font: 500 20px "Univers Next W02", Arial;
    flex: none;
    width: 100%;
    max-width: 100%;
  }
  .neo__filterblock__tagsheading {
    grid-column: 1;
    grid-row: 1;
  }
  .neo__filterblock__sector {
    grid-column: 1;
    grid-row: 2;
  }
  .neo__filterblock__activity {
    grid-column: 1;
    grid-row: 3;
  }
  .neo__filterblock__channel {
    grid-column: 1;
    grid-row: 4;
  }
  .neo__filterblock__periodheading {
    grid-column: 2;
    grid-row: 1;
  }
  .neo__filterblock__startdate {
    grid-column: 2;
    grid-row: 2;
    width: calc(50% - 12px);
  }
  .neo__filterblock__enddate {
    grid-column: 2;
    grid-row: 2;
    width: calc(50% - 12px);
    justify-self: end;
  }
  .neo__filterblock__archive {
    grid-column: 2;
    grid-row: 3;
    align-self: center;
  }
}
.wh-styledinput-neo {
  font-size: 16px;
}
.wh-styledinput-neo .checkboxgroup {
  padding-top: 10px;
}
.wh-styledinput-neo input[type=search],
.wh-styledinput-neo input[type=text],
.wh-styledinput-neo input[type=date] {
  border: 1px solid #cbcbcb;
  border-radius: 0;
  height: 40px;
  line-height: 22px;
  font: inherit;
  padding: 5px 15px;
  background-color: #fff;
  outline: none;
  width: 100%;
  transition: border-color 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.wh-styledinput-neo input[type=search]:hover, .wh-styledinput-neo input[type=search]:focus,
.wh-styledinput-neo input[type=text]:hover,
.wh-styledinput-neo input[type=text]:focus,
.wh-styledinput-neo input[type=date]:hover,
.wh-styledinput-neo input[type=date]:focus {
  border-color: #aaa;
}
.wh-styledinput-neo input[type=checkbox] {
  display: none;
}
.wh-styledinput-neo input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  line-height: 22px;
}
.wh-styledinput-neo input[type=checkbox] + label:before {
  content: "";
  height: 22px;
  width: 22px;
  border: 1px solid #cbcbcb;
  background-color: #fff;
  border-radius: 3px;
  display: inline-block;
  outline: none;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 10px;
}
.wh-styledinput-neo input[type=checkbox]:hover + label:after, .wh-styledinput-neo input[type=checkbox]:checked + label:after {
  display: inline-block;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00c";
  font-size: 20px;
  position: absolute;
  left: 3px;
  top: -3px;
}
.wh-styledinput-neo input[type=checkbox]:hover + label:after {
  opacity: 0.2;
}
.wh-styledinput-neo input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.wh-styledinput-neo input[type=checkbox][disabled] + label {
  opacity: 0.5;
  cursor: default;
}
.wh-styledinput-neo .filtergroup-date {
  width: calc(50% - 7px);
}
@media (max-width: 767px) {
  .wh-styledinput-neo .filtergroup-date {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .wh-styledinput-neo .filtergroup-date > .styleddate {
    max-width: 160px;
    margin-right: 10px;
  }
}
.wh-styledinput-neo .styleddate {
  position: relative;
}
.wh-styledinput-neo .styleddate:before {
  display: inline-block;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f073";
  font-size: 20px;
  position: absolute;
  left: 15px;
  top: 10px;
  pointer-events: none;
}
.wh-styledinput-neo .styleddate input {
  padding-left: 50px;
}

.neo-listfilters__reset {
  /*
    position: absolute;
    right: 0;
    top: 100%;
  */
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
}

.filters-active .neo-listfilters__reset {
  opacity: 1;
  pointer-events: all;
}
.filters-active .neo-listfilters__reset:hover {
  opacity: 0.8;
}