/*

BLUE

Normal  / Hover   / Down    / CTAButton / CTAButton-hover
#5078b4 / #5b84c1 / #3f6ba6 / #24416a   / #33598b

apply = will apply to sublevels too (instead of very shallow)

*/


  .sitetheme--blue
, .theme--blue
{
  --themecolor:       #5078b4;
  --themecolor-dark:  #24416A;
  --themecolor-light: #88BCE4; /* #{$themecolor-blue-light}; */
  /*--themecolor-transparent: rgba(63, 107, 166, 0.4);*/
}

.sitetheme--blue
{
  --sitethemecolor:       #5078b4;
  --sitethemecolor-dark:  #24416A;
  --sitethemecolor-light: #88BCE4; /* #{$themecolor-blue-light}; */
  /*--themecolor-transparent: rgba(63, 107, 166, 0.4);*/
}



/* we very specifically target <a> because otherwise we
   would also target <a>'s within embeddedobjects (which might have another colorschema).
   We need color inheritance, but CSS (except for CSS variables) works by targetting on rule.
   (we can inherit color, but not have a second seperate color for anchors... unless we could use CSS variables)
*/
  .rtdcontent.tc-blue > p a
, .rtdcontent.tc-blue > ul a
, .rtdcontent.tc-blue > ol a
, .rtdcontent.tc-blue > h1
, .rtdcontent.tc-blue > h2
, .rtdcontent.tc-blue > h3
, .rtdcontent.tc-blue > h4

, .tc-blue .wh-form__richtext > p a
, .tc-blue .wh-form__richtext > ul a
, .tc-blue .wh-form__richtext > ol a
, .tc-blue h1
, .tc-blue h2
, .tc-blue h3
, .tc-blue h4

, .wh-rtd-editor.tc-blue > p a
, .wh-rtd-editor.tc-blue > ul a
, .wh-rtd-editor.tc-blue > ol a
, .wh-rtd-editor.tc-blue > h1
, .wh-rtd-editor.tc-blue > h2
, .wh-rtd-editor.tc-blue > h3
, .wh-rtd-editor.tc-blue > h4

, .tc-blue-fg-dark.tc-blue-fg-dark
, .tc-blue-fg-dark-ahover a:hover

/* FIXME: replace .rtdcontent and .wh-rtd-editor with a single class */
, .themeclass.tc-blue-apply a

, html.tc-blue .wh-form__grouptitle
, html.tc-blue /*.used_on_dark_background*/ .widgetdl__item__icon
, html.tc-blue /*.used_on_dark_background*/ .widgetdl__item__title
, html.tc-blue /*.used_on_dark_background*/ .widgetdl__item__filesize

, .widget-blocks.theme--blue .blockswidget__item__description a
{
 color: #5078b4;
}


/* Theme color used on lightgrey background */
  .tc-lightgrey-bg-dark.theme--blue .widget__title
, .tc-lightgrey-bg-dark.theme--blue .blockswidget__filterstrip__tagfilterlabel
{
  color: #5078b4;
}
/*
, .widget-blocks.theme--blue .blockswidget__item__button
{
  color: #5078b4;
}
*/




  .tc-blue-bg-dark
, .wh-rtd-editor.tc-blue-bg-dark
{
  background-color: #5078b4;
  color: #FFFFFF;
}

.tc-blue-bc-dark
{
  border-color: #5078b4;
}

/* allow the dark anchor to work on itself and two levels deeper */
  .tc-blue-ac-dark /* no inheritance for specific items on which it's used (meant for buttons) */
,  a.tc-blue-ac-dark
, .tc-blue-ac-dark > a
, .tc-blue-ac-dark > * > a
{
  color: #5078b4;
}
  .tc-blue-ac-dark:hover
, a.tc-blue-ac-dark:hover
, .tc-blue-ac-dark > a:hover
, .tc-blue-ac-dark > * > a:hover
{
  color: #5b84c1;
}
  .tc-blue-ac-dark:active
, a.tc-blue-ac-dark:active
, .tc-blue-ac-dark > a:active
, .tc-blue-ac-dark > * > a:active
{
  color: #3f6ba6;
}

/* buttons (on white) */
  .tc-blue-darkhover
, /*.document-toplevel*/.tc-blue > .embeddedobject-inflow .tc--darkhover /* use 'pagetheme' on buttons at toplevel */
, .blockswidget-item-meta .blockswidget-item-link.tc-blue-darkhover
{
  background-color: #5078b4;
  color: #FFFFFF;
  transition: background-color 0.15s;
}
  .tc-blue-darkhover:hover
, .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-blue-darkhover
{
  background-color: #5b84c1;
}
.tc-blue-darkhover:active
{
  background-color: #3f6ba6;
}
.tc-blue-darkhover:focus
{
  outline: 0;
  box-shadow: 1.5px 1.5px 3px rgba(0,0,0,0.4);
}

/* buttons widget (on a dark background) */
  .used_on_dark_background .buttonwidget .tc-blue-darkhover
/*, .tc-blue-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover*/
, .used_on_dark_background .blockswidget-metaunder .blockswidget-item-link.tc-blue-darkhover
{
  background-color: #24416a;
  color: #FFFFFF;
}
  .used_on_dark_background .buttonwidget .tc-blue-darkhover:hover
/*, .tc-blue-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover:hover*/
, .used_on_dark_background .blockswidget-metaunder .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-blue-darkhover
{
  background-color: #33598b;
}
/* hover and focus the same as on white bg ?? */



/* "" (default) or "solid"

  --themecolor:       #5078b4;
  --themecolor-dark:  #24416A;
  */

/* Solid button styles */
  .ctabutton--autostyle.tc-blue                 /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-blue                     /* explicitely solid */
, html.tc-blue .ctabutton--autostyle.tc-auto    /* widget inheriting the color from the page themecolor */
, html.tc-blue .ctabutton--solid.tc-auto        /* widget inheriting the color from the page themecolor */
, html.tc-blue .wh-form__button                 /* Form webtool */
, .widget-blocks.theme--blue .blockswidget__item__button
{
  background-color: #5078b4;
  /*background-color: var(--themecolor);*/
  color: #FFFFFF;
}

  .ctabutton--autostyle.tc-blue:hover /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-blue:hover /* at top level (not in color background) default to solid */
, html.tc-blue .ctabutton--autostyle.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, html.tc-blue .ctabutton--solid.tc-auto:hover /* widget inheriting the color from the page themecolor */
, html.tc-blue .wh-form__button:hover              /* Form webtool */
, .widget-blocks.theme--blue .blockswidget__item:hover .blockswidget__item__button
{
  background-color: #24416A;
  /*background-color: var(--themecolor-dark);*/
}


/* Colored outline button styles */
  .ctabutton--outline.tc-blue
, html.tc-blue .ctabutton--outline.tc-auto  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-blue /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-blue /* without a contentblock with backgroundcolor and explicit color selection */
{
  background-color: #FFFFFF;
  color: #5078b4;
  /*color: var(--themecolor);*/

  border: 2px solid #5078b4;
  /*border: 2px solid var(--themecolor);*/
}

  .ctabutton--outline.tc-blue:hover
, html.tc-blue .ctabutton--outline.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-blue:hover /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-blue:hover /* without a contentblock with backgroundcolor and explicit color selection */
{
  /*background-color: var(--themecolor);*/
  background-color: #5078b4;
  color: #FFFFFF;
}
