.blockswidget__bullets,
.widget-ctablocks__bullets {
  display: flex;
  justify-content: center;
}

.blockswidget__bullet,
.widget-ctablocks__bullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.36;
  -webkit-tap-highlight-color: transparent;
}

.blockswidget__bullet:not(.blockswidget__bullet--selected),
.widget-ctablocks__bullet:not(.widget-ctablocks__bullet--selected) {
  cursor: pointer;
}

.blockswidget__bullet + .blockswidget__bullet,
.widget-ctablocks__bullet + .widget-ctablocks__bullet {
  margin-left: 8px;
}

.blockswidget__bullet--selected,
.widget-ctablocks__bullet--selected {
  opacity: 1;
}