#association-suggest-container {
  position: absolute;
  /*
    margin: 0;
    left: 0;
    top: 100%;
  */
}
#association-suggest-container .ui-autocomplete {
  background-color: #F0F0F0;
  border: 1px solid #DDDDDD;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#association-suggest-container .ui-autocomplete > li {
  display: block;
  font: 500 18px/20px Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; /* "Univers Next W02";*/
  padding: 10px 10px;
  color: #000000;
  cursor: pointer;
  transition: color 0.3s;
}
#association-suggest-container .ui-autocomplete > li:hover {
  background-color: #E0E0E0;
  color: #000000;
}

.ui-helper-hidden-accessible {
  display: none;
}

.buddyrequest__form .wh-form__questiongroup > .wh-form__fieldgroup {
  min-height: 36px; /* 8 + 20 + 8 */
}