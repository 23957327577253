/*

Yellow

Normal  / Hover   / Down    / CTAButton / CTAButton-hover
#fac805 / #ffd828 / #e7b700 / #8b711e   / #bc981b

*/

/* FIXME: yellow pallette has changed!!*/

  .sitetheme--yellow
, .theme--yellow
{
  --themecolor:       #F7B500;
  --themecolor-dark:  #956D00; /* also CTAButton color */
  --themecolor-light: #FFFF00; /* hover */
  /* gba(231, 183, 0, 0.4); */
}

.sitetheme--yellow
{
  --sitethemecolor:       #F7B500;
  --sitethemecolor-dark:  #956D00; /* also CTAButton color */
  --sitethemecolor-light: #FFFF00; /* hover */
  /* gba(231, 183, 0, 0.4); */
}


  .rtdcontent.tc-yellow > p a
, .rtdcontent.tc-yellow > p ul
, .rtdcontent.tc-yellow > p ol
, .rtdcontent.tc-yellow > h1
, .rtdcontent.tc-yellow > h2
, .rtdcontent.tc-yellow > h3
, .rtdcontent.tc-yellow > h4

, .tc-yellow .wh-form__richtext > p a
, .tc-yellow .wh-form__richtext > ul a
, .tc-yellow .wh-form__richtext > ol a
, .tc-yellow h1
, .tc-yellow h2
, .tc-yellow h3
, .tc-yellow h4

, .wh-rtd-editor.tc-yellow > p a
, .wh-rtd-editor.tc-yellow > p ul
, .wh-rtd-editor.tc-yellow > p ol
, .wh-rtd-editor.tc-yellow > h1
, .wh-rtd-editor.tc-yellow > h2
, .wh-rtd-editor.tc-yellow > h3
, .wh-rtd-editor.tc-yellow > h4

, .tc-yellow-fg-dark.tc-yellow-fg-dark
, .tc-yellow-fg-dark-ahover a:hover
, .themeclass.tc-yellow-apply a

, html.tc-yellow .wh-form__grouptitle
{
  color: #fac805;
}


/* Color to use when over a yellow background */
  .tc-yellow-bg-dark .widget__title
, .tc-yellow-bg-dark.used_on_dark_background a
, .theme--yellow .blockswidget__filterstrip__tagfilterlabel
, .widget-blocks.theme--yellow .blockswidget__item__description a
, .widget-blocks.theme--yellow .blockswidget__item__button

, .used_on_dark_background.tc-yellow-bg-dark .widget-ctablocks__item__title
, .used_on_dark_background.tc-yellow-bg-dark .widget-ctablocks__item__subtitle
{
  color: #24416A;
}




  .tc-yellow-bg-dark
, .wh-rtd-editor.tc-yellow-bg-dark
{
  background-color: #fac805;
  color: #000000;
}

.tc-yellow-bc-dark
{
  border-color: #fac805;
}

/* allow the dark anchor to work on itself and two levels deeper */
  .tc-yellow-ac-dark
, a.tc-yellow-ac-dark
, .tc-yellow-ac-dark > a
, .tc-yellow-ac-dark > * > a
{
  color: #fac805;
}
  .tc-yellow-ac-dark:hover
, a.tc-yellow-ac-dark:hover
, .tc-yellow-ac-dark > a:hover
, .tc-yellow-ac-dark > * > a:hover
{
  color: #ffd828;
}
  .tc-yellow-ac-dark:active
, a.tc-yellow-ac-dark:active
, .tc-yellow-ac-dark > a:active
, .tc-yellow-ac-dark > * > a:active
{
  color: #e7b700;
}

/* buttons (on white) */
  .tc-yellow-darkhover
, /*.document-toplevel*/.tc-yellow .embeddedobject-inflow .tc--darkhover /* use 'pagetheme' on buttons at toplevel */
, .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-yellow-darkhover
{
  background-color: #fac805;
  color: #000000;
  transition: background-color 0.15s;
}
  .tc-yellow-darkhover:hover
, .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-yellow-darkhover
{
  background-color: #ffd828;
}
.tc-yellow-darkhover:active
{
  background-color: #e7b700;
}
.tc-yellow-darkhover:focus
{
  outline: 0;
  box-shadow: 1.5px 1.5px 3px rgba(0,0,0,0.4);
}



/* buttons widget (on a dark background) */
  .used_on_dark_background .buttonwidget .tc-yellow-darkhover
, .tc-yellow-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover
, .used_on_dark_background .blockswidget-metaunder .blockswidget-item-link.tc-yellow-darkhover
{
  background-color: #8b711e;
  color: #FFFFFF;
}
  .used_on_dark_background .buttonwidget .tc-yellow-darkhover:hover
, .tc-yellow-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover:hover
, .used_on_dark_background .blockswidget-metaunder .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-yellow-darkhover
{
  background-color: #bc981b;
}



/*
  --themecolor:       #F7B500;
  --themecolor-dark:  #956D00; / * also CTAButton color * /
  --themecolor-light: #FFFF00; / * hover * /
*/





/* Solid button styles */
  .ctabutton--autostyle.tc-yellow               /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-yellow                   /* explicitely solid */
, html.tc-yellow .ctabutton--autostyle.tc-auto  /* widget inheriting the color from the page themecolor */
, html.tc-yellow .ctabutton--solid.tc-auto      /* widget inheriting the color from the page themecolor */
, html.tc-yellow .wh-form__button               /* Form webtool */
, .widget-blocks.theme--yellow .blockswidget__item__button
{
  background-color: #F7B500;
  /*background-color: var(--themecolor);*/
  color: #24416A;
}

  .ctabutton--autostyle.tc-yellow:hover /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-yellow:hover /* at top level (not in color background) default to solid */
, html.tc-yellow .ctabutton--autostyle.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, html.tc-yellow .ctabutton--solid.tc-auto:hover /* widget inheriting the color from the page themecolor */
, html.tc-yellow .wh-form__button:hover
, .widget-blocks.theme--yellow .blockswidget__item:hover .blockswidget__item__button
{
  background-color: #956D00;
  color: #FFFFFF;
  /*background-color: var(--themecolor-dark);*/
}


/* Colored outline button styles */
  .ctabutton--outline.tc-yellow
, html.tc-yellow .ctabutton--outline.tc-auto  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-yellow /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-yellow /* without a contentblock with backgroundcolor and explicit color selection */
{
  background-color: #FFFFFF;
  color: #F7B500;
  /*color: var(--themecolor);*/

  border: 2px solid #F7B500;
  /*border: 2px solid var(--themecolor);*/
}

  .ctabutton--outline.tc-yellow:hover
, html.tc-yellow .ctabutton--outline.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-yellow:hover /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-yellow:hover /* without a contentblock with backgroundcolor and explicit color selection */
{
  /*background-color: var(--themecolor);*/
  background-color: #F7B500;
  color: #FFFFFF;
}


  .used_on_dark_background.tc-yellow-bg-dark .ctabutton--outline.tc-auto /* EXTRA */
, .used_on_dark_background.tc-yellow-bg-dark .ctabutton--outline.tc-auto:hover
{
  color: #24416A;
  border-color: #24416A;
}


