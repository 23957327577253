
/*

image 119 x 91

-------
4*5 * 8px macroblocks = 20 * 8 = 160
3*5 * 8px macroblocks = 15 * 8 = 120

Optimal size - 160 x 120
(for compression & not such a silly tiny thumbnail)

160 x 136 ... not quite 4:3, but nice;


*/


#search__results
{
  /* vertical */
  display: flex;
  flex-direction: column;
}



#search__togglefilters
{
  height: 70px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  background-color: #DFDFDF;
  cursor: pointer;

  /* vertical align content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /**************************/

  -webkit-tap-highlight-color: transparent; /* iOS Safari */

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
#search__togglefilters:active
{
  background-color: #BBBBBB;
}
#ks__searchfilters > .ks__searchfilters-group:first-child > .fs_h3:first-child
{
  padding-top: 0;
  margin-top: 0;
}
#search__describecurrentfilter
{
  text-transform: none;
  font-size: 14px;
  line-height: 14px;
}
#search__describecurrentfilter:empty
{
  display: none;
}



#ks__searchfilters .item
{
  margin-top: 40px;
}

.ks__searchfilters-group + .ks__searchfilters-group
{
  margin-top: 40px;
}

.ks__searchfilters__item--sidebyside
{
  display: flex;
  align-items: center;
}
.ks__searchfilters__item--sidebyside > *:first-child
{
  flex: 1;
}



.ks__searchfilters-group-title
{
  text-transform: none;
  padding: 0;
}



#ks__searchfilters
{
  font: normal 18px "Univers Next W02";
}
#ks__searchfilters .item > label
{
  display: inline-block;
  font: bold 18px "Univers Next W02";
  color: #000000;
  text-transform: uppercase;

  margin-bottom: 6px;
}



#ks__searchfilters .ui-slider
{
  margin-top: 5px;
}



.slider-label
{
  position: absolute;
  left: 0;

  margin-top: 18px;
  font: 14px Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

  color: #999999;
}
@media only screen and (max-width: 939px)
{
  /* On mobile we assume a touch-device.
     We show the number at the right here so the value indicator
     isn't hidden under the user's finger.
  */
  .slider-label
  {
    left: auto !important;
    right: 0 !important;
  }
}

#search-locations .wh-form__fieldline
{
  flex-wrap: nowrap;
}

#search-locations label
{
  display: inline-block;
  margin-bottom: 5px;
}

#ks__searchfilters__confirm
{
  text-align: right;
}
#ks__searchfilters__confirm button
{
  width: 50%;
}



#search__results
{
  margin-top: 20px;
}

.search__resultinfo .search__resultscount
{
  text-transform: none;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}
.search__resultinfo .search__describecurrentfilter
{

}

#search__results__items
{
}

#search__results
{
  /* vertical */
  display: flex;
  flex-direction: column;
}

.room
{
  /* horizontal */
  display: flex;

  padding: 26px 0;
  border-bottom: 1px solid #e2e3e6;
}
.room.hidden
{
  display: none;
}


a.room__image
{
  display: block;

  /* a very long (non-breakable) line may not shrink this element */
  flex-shrink: 0;

  text-decoration: none;
  width: 160px;
  min-height: 106px;
  background-size: cover;

  background-color: #979ca2; /* placeholder color */
}
/*
a.room__image > img
{
  width: 100%;
}
*/

.room__meta
{
  flex: 1;

  /* vertical */
  display: flex;
  flex-direction: column;

  overflow: hidden; /* allow ellipsis to work on .room_title */
}

a.room__title
{
  font: bold 24px "Univers Next W02";
  color: #333333;
  text-decoration: none;
  text-transform: uppercase;

  text-overflow: ellipsis; /* in case of non-breakable very line titles */
}

.room__location
{
  font: 14px Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

  flex: 1; /* grow to push the .room__specs to the bottom of .room */
}

.room__specs
{
  display: flex;

  color: #000000;
  font: normal 16px "Univers Next W02";

  margin-top: 20px;
}
.room__specs > * + *
{
  margin-left: 20px;
}
.room__specs > * > .label
{
  font: normal 14px Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  color: #c6cbd1;
}

.room__size
{

}
.room__rent
{

}
.room__availablefrom
{

}


/* DESKTOP/TABLET */
@media only screen and (min-width: 749px)
{
  .room__meta
  {
    margin-left: 20px;
  }

  .ks__column1 .pad_h.pad_h /* filters */
  {
    padding-left: 15px;
    padding-right: 15px;
  }
/*
  .ks__column2 .pad_h.pad_h
  {
    padding-left: 20px;
    padding-right: 20px;
  }
*/
}

/* MOBILE */
@media only screen and (max-width: 939px)
{
  html.kamersite--searchpage .ks__column1
  {
    order: 2;
  }
  html.kamersite--searchpage .ks__column2
  {
    order: 1;
  }

  #ks__searchfilters__sortby .ui-selectmenu-button
  {
    width: 100% !important;
  }

  html.kamersite--searchpage #search__results .ks__column1 .pad_h.pad_h
  {
    padding-left: 0;
    padding-right: 20px;
  }

  html.kamersite--searchpage .ks__column2 .pad_h.pad_h /* filters */
  {
    padding-left: 20px;
    padding-right: 20px;
  }
  html.kamersite--searchpage .ks__column2 .pad_v.pad_v /* filters */
  {
    padding-top: 20px;
    padding-bottom: 20px;
  }


  html.kamersite--searchpage--filtersactive #search__results
  {
    display: none;
  }


  #ks__searchfilters
  {
    /* vertical */
    display: flex;
    flex-direction: column;

    /* we need extra padding, because on iOS (and maybe other oses?)
       a swipe at the edge of the screen can mean to go back or to another tab.
    */
    padding-left: 50px;
    padding-right: 50px;
  }
  #ks__searchfilters__filterby
  {
    margin-top: 0;
  }
  #ks__searchfilters__filterby .ks__searchfilters-group-title
  {
    display: none;
  }
  #ks__searchfilters__filterby .ks__searchfilters-group-title + .item
  {
    margin-top: 0;
  }
  #ks__searchfilters__sortby
  {
    order: 2;

    margin-top: 20px;
  }
  #ks__searchfilters__confirm
  {
    order: 3;

    margin-top: 20px;
  }





  html.kamersite--searchpage .room__title
  {
    padding-right: 40px;
  }

  a.room__image
  {
    width: 120px;
  }

  .room__meta
  {
    margin-left: 15px;
  }

  a.room__title
  {
    font: 500 20px "Univers Next W02";
  }

  .room__specs
  {
    font: 500 14px "Univers Next W02";
    white-space: nowrap;
  }
}




html.kamersite--roompage #room__page h2
{
  color: #000000;
}

