/*
@import "./config.scss";
@import "../shared/config.scss";
//@import "../shared/forms/mixins.scss";
@import "../../shared/mixins-forms.scss";
@import "../web/fonts/fontawesome/mixins.scss";
*/
.sidebar__search {
  position: relative;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
}

.sidebar__search + * {
  margin-top: 20px;
}

.sidebar__search__input.sidebar__search__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 20px;
  padding-right: 45px;
  border: 1px solid #979797;
  padding-top: 2px;
  padding-bottom: 1px;
  font: 16px/20px Arial;
  height: 42px;
  width: 100%;
  border-radius: 21px;
  background-color: #FFFFFF;
  color: #000000;
}
.sidebar__search__input.sidebar__search__input::placeholder {
  color: #797979;
  opacity: 1;
}

.sidebar__search__input.sidebar__search__input::selection {
  color: #FFFFFF;
  background-color: var(--themecolor);
}

.sidebar__search__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 8px;
  top: 0;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar__search__submit::before {
  font: var(--fontawesome-regular);
  content: var(--fa-search);
  font-size: 18px;
  color: var(--color-theme);
}

.sidebar__search__submit:hover::before {
  color: #000000;
}