.neo-tags {
  list-style-type: none;
  margin: 20px 0 10px -15px;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.neo-tags li {
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 10px;
}
.neo-tags a.neo-tag {
  display: inline-flex;
  align-items: center;
  height: 31px;
  padding: 3px 15px;
  color: var(--themecolor);
  border: 1px solid var(--themecolor);
  border-radius: 41px;
  font: 700 14px "Univers Next W02";
  text-decoration: none;
  transition: opacity 0.2s;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.neo-tags a.neo-tag:hover {
  color: #FFFFFF;
  background-color: var(--themecolor);
}