/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/*
$pageheader-flat-preferredvwheight:     25vw; // Use % or vw unit;
$pageheader-flat-minheight:            200px; //
$pageheader-flat-maxheight:             35vmin;

$pageheader-default-preferredvwheight: 37.5vw; // Use % or vw unit;
$pageheader-default-minheight:          200px; //
$pageheader-default-maxheight:         35vmin;
*/
/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
.page-header__slideshow {
  position: relative;
}

.page-header__slideshow .carrousel__cell,
.page-header__slideshow::before {
  grid-column: 1;
  grid-row: 1;
  background-size: cover;
}

.page-header__slideshow {
  display: grid;
  z-index: 0;
}
html.pageheader--flatten .page-header__slideshow::before {
  display: none;
}

.page-header__overlay {
  z-index: 100;
  position: relative; /* For IE & Edge */
  grid-column: 1;
  grid-row: 1;
}

.page-header__slideshow-jumpbuttons {
  grid-column: 1;
  grid-row: 1;
  align-self: end;
  justify-self: end;
  display: flex;
  z-index: 5;
  position: relative; /* For IE & Edge */
}

.page-header__slideshow-jumpbutton {
  padding: 6px;
  opacity: 0.5;
  cursor: pointer;
}

.page-header__slideshow-jumpbutton::before {
  content: "";
  display: block;
  background-color: rgba(255, 255, 255, 0.5); /* Fallback for IE and Edge */
  background-color: var(--themecolor);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.page-header__slideshow-jumpbutton:hover {
  opacity: 0.8;
}

.page-header__slideshow-jumpbutton.active {
  opacity: 1;
}

html.pageheader--size-flat .page-header__slideshow::before {
  content: "";
  height: 25vw;
  max-height: 45vmin;
  min-height: 200px;
}

html.pageheader--size-default .page-header__slideshow::before {
  content: "";
  height: 35vw;
  max-height: 45vmin;
  min-height: 200px;
}

@media (max-width: 1023px) {
  html.pageheader--align-default .page-header__slide__content {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) {
  html.pageheader--align-default .page-header__slide__content {
    grid-column: 7/span 19;
  }
}

html.pageheader--align-wide .page-header__slide__content {
  grid-column: 3/30;
}

/*
Support for "fithighestimage" header size method ---------------------------

html:not(.pageheader--size-fithighestimage) .page-header__slide__aspectratio
{
  display: none;
}

html.pageheader--size-fithighestimage .page-header__slideshow::before
{
}

.page-header__slide__aspectratio
{
  width: 0px;
  // background-color: #F00;
  // z-index: 15;
}

----------------------------------------------------------------------------
*/
.page-header__slide {
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
}

.page-header__slide__content {
  align-self: end;
  z-index: 1;
  position: relative; /* For IE & Edge */
}

@media only screen and (max-width: 767px) {
  .page-header__slide__content {
    padding: 15px 0 15px 0;
  }
  .page-header__slide__button {
    margin-top: 7px;
  }
  .page-header__slideshow-jumpbuttons {
    margin-right: 9px;
    margin-bottom: 9px;
  }
}
@media (min-width: 768px) {
  .page-header__slide__content {
    padding: 30px 0 30px 0;
  }
  .page-header__slide__button {
    margin-top: 25px;
  }
  .page-header__slideshow-jumpbuttons {
    margin-right: 24px;
    margin-bottom: 24px;
  }
}
/*
.page-header__slidescontent
{
  @include layout--verywide;

  padding-top: 25px;
  padding-bottom: 25px;

  @media (max-width: $headerslideshow-minwidth-grid - 1px)
  {
    padding-top: 75px;
  }

  display: grid;

  align-items: end;
}

.page-header__slidecontent
{
  grid-column: 1;
  grid-row: 1;

  color: #FFFFFF;
}
.page-header__slidecontent__inner
{
  max-width: 855px;
}

.page-header__slidecontent .wh-form__button
{
  margin-top: 30px;
}
*/
.page-header__slide {
  opacity: 0;
  transition: opacity 0.25s;
  pointer-events: none;
}

.page-header__slide.activeslide {
  opacity: 1;
  pointer-events: auto;
}

.page-header__slideshow::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /*
  height: 67px;
  opacity: 0.33;
  background: linear-gradient(180deg, rgba(84,83,80,0) 0%, #000000 100%);
  */
  height: 250px;
  background: linear-gradient(180deg, rgba(84, 83, 80, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  pointer-events: none;
}

.page-header__slideshow__navigation {
  grid-column: 3/30;
  grid-row: 3;
  align-self: end;
  justify-self: end;
  position: relative; /* For IE & Edge */
  z-index: 2;
  padding-bottom: 25px;
  display: flex;
}

/*
  .page-header__slideshow__prevbutton
, .page-header__slideshow__nextbutton
{
  color: #FFFFFF;
  cursor: pointer;
  border: 2px solid #FFF;

  width: 36px;
  height: 36px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.page-header__slideshow__nextbutton
{
  margin-left: 18px;
}
  .page-header__slideshow__prevbutton:hover
, .page-header__slideshow__nextbutton:hover
{
  background-color: #FFFFFF;
  color: #000000;
}

.page-header__slideshow__prevbutton::before
{
  / *
  @include fontawesome-light;
  content: $fa-arrow-circle-left;
  font-size: 36px;
  * /
  @include fontawesome-regular;
  content: $fa-arrow-left;
  font-size: 20px;
}
.page-header__slideshow__nextbutton::before
{
  / *
  @include fontawesome-light;
  content: $fa-arrow-circle-right;
  font-size: 36px;
  * /
  @include fontawesome-regular;
  content: $fa-arrow-right; //$fa-arrow-circle-right;
  font-size: 20px;
}
*/
.page-header__slide__title,
.page-header__slide__description > * {
  color: #FFFFFF;
}

.page-header__slide__description > * > a {
  color: inherit;
  text-decoration: underline;
}

/*
See shared/rtd/rtd.css

.page-header__slide__title
{
  font: 700 64px/66px "Univers Next W02";
  text-transform: uppercase;
}
*/
/*
.page-header__slidetitle
{
  font: bold 44px/46px $font-heading;
}
*/
@media (max-width: 767px) {
  /*
    .page-header__slidetitle
    {
      font: bold 24px/28px $font-heading;
    }
  */
  .page-header__slideshow__navigation {
    padding-top: 25px;
    align-self: start;
  }
  .page-header__slideshow__prevbutton,
  .page-header__slideshow__nextbutton {
    width: 24px;
    height: 24px;
  }
  .page-header__slideshow__prevbutton::before,
  .page-header__slideshow__nextbutton::before {
    font-size: 14px;
  }
  .page-header__slideshow__nextbutton {
    margin-left: 12px;
  }
}
html.pageheader--block {
  /*
  &.page--withfilterpanel .page-header__meta
  {
    @include layout--verywide;
  }
  */
}
@media (max-width: 1023px) {
  html.pageheader--block .page-header__meta {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) and (max-width: 1023px) {
  html.pageheader--block .page-header__meta {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  html.pageheader--block .page-header__meta {
    grid-column: 7/span 19;
  }
}
html.pageheader--block h1.page-header__title {
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
html.pageheader--block .page-header__content {
  color: #FFFFFF;
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
  align-items: end;
}
html.pageheader--block .page-header__meta {
  padding: 25px 0;
}
html.pageheader--block.pageheader--flatten .page-header__meta {
  padding-top: 35px;
  padding-bottom: 15px;
}

html.pageheader--inflow .page-header__content {
  display: grid;
  grid-template-columns: calc((100% - 1360px) / 2) 30px repeat(13, minmax(0, 1fr) 30px) minmax(0, 1fr) 30px calc((100% - 1360px) / 2);
  grid-template-columns: calc((100% - 1360px) / 2) var(--pagegrid-gutteroutside) repeat(13, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) var(--pagegrid-gutteroutside) calc((100% - 1360px) / 2);
}
html.pageheader--inflow .page-header__date {
  font-size: 14px;
  margin-bottom: 4px;
}
/*
.page-header__title
{
  font: bold 50px/62px $font-heading;
  font-size: unquote("max(22px, min(6.5vw, 50px))"); // Chrome 79, Safari 11.1+, iOS 11.3+
  font-size: unquote("clamp(22px, 6.5vw, 50px)");    // Chrome 79 - https://caniuse.com/#feat=css-math-functions
}
*/
.page-header__date {
  font: 14px "Univers Next W02";
  color: #767676;
  text-transform: uppercase;
}

.page-header__slide.active .page-header__slide__content {
  opacity: 0;
  pointer-events: none;
}
.page-header__slide.active::after {
  display: none;
}
.page-header__slide.active .playvideobutton {
  opacity: 0;
}

.page-header__slide .playvideobutton {
  grid-row: 1;
  align-self: end;
  justify-self: end;
  z-index: 1;
  position: relative; /* For IE & Edge */
  grid-column-start: 25;
  grid-column-end: 30;
  text-decoration: none;
}

.page-header__slide .videocontainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  /* make a kind of theater (a place to focus on the video,
     rather than having the video sticking/blending to the page
  */
  padding: 10px 80px;
  background-color: #000000;
}

.page-header__slide .video-close {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 18px;
  height: 18px;
  background-image: url("../web/img/close-white.svg");
  background-size: cover;
  cursor: pointer;
}

.page-header__slide .videocontainer,
.page-header__slide .video-close {
  visibility: hidden;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.page-header__slide.is-activated .videocontainer,
.page-header__slide.is-activated:hover .video-close {
  transition: opacity 1.5s;
  visibility: visible;
  z-index: 0;
  pointer-events: auto;
  opacity: 1;
}