/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/*******************************************************************************
 * CONFIGURATION
 */
/*
Most breakpoints in the Student Union design are based on whether
the content can still be shown
(as opposed to the elements being design for specific design sizes as in ws2016)

NOTES:

- Chrome counts the part in used by scrollbars to be part of the viewport width.
  Therefore 940px might not fully be 940px... Any content positioned absolute based on the
  assumption it's at least 940 might not be positioned correctly.


*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* The normal content area extends up to 940px */
/* NOTE: Grid cannot get smaller than $pagegrid-gutter * ($pagegrid-columns - 1).
         In this minimum size the columns are 0 wide and whe'll only have gutter space left.

         By switching to a smaller gutter we can keep content using the grid
         even on small viewports. (even though it may be better to not use grid
         styling on these small viewports or only use fullwidth and verywide mixins)
*/
/*
@mixin layout--continue-grid-within
{
  @include layout--fullwidth;
  @include spc-contentgrid;
}
*/
.neoitem-header__image {
  background-size: auto 100%;
}

@media (max-width: 1023px) {
  .neoitem-header__meta {
    grid-column: 3/30;
  }
}
@media (min-width: 1024px) {
  .neoitem-header__meta {
    grid-column: 7/span 19;
  }
}

html.neopage .nowrap {
  white-space: nowrap;
}
html.neopage .ui-datepicker {
  border: 1px solid #ccc;
}
html.neopage .ui-datepicker .ui-datepicker-header {
  color: #fff;
  background-image: none;
}
html.neopage .ui-datepicker .ui-datepicker-header .ui-state-hover {
  background: transparent;
}
html.neopage .ui-datepicker td .ui-state-active,
html.neopage .ui-datepicker td .ui-state-hover {
  text-shadow: none;
}
html.neopage--tc-blue .ui-datepicker .ui-datepicker-header {
  background-color: #5078b4;
}
html.neopage--tc-blue .ui-datepicker td .ui-state-active,
html.neopage--tc-blue .ui-datepicker td .ui-state-hover {
  background: #5078b4;
  border-color: #5078b4;
}
html.neopage--tc-blue .fg-theme, html.neopage--tc-blue input[type=checkbox] + label:after,
html.neopage--tc-blue .heading1, html.neopage--tc-blue .heading2, html.neopage--tc-blue .heading3, html.neopage--tc-blue .heading4, html.neopage--tc-blue p a {
  color: #5078b4;
}
html.neopage--tc-blue svg.fg-theme path {
  fill: #5078b4;
}
html.neopage--tc-blue .bg-theme {
  background-color: #5078b4;
}
html.neopage--tc-blue .bg-theme--light {
  background-color: #88bce4;
}
html.neopage--tc-gray .ui-datepicker .ui-datepicker-header {
  background-color: #3c3f4c;
}
html.neopage--tc-gray .ui-datepicker td .ui-state-active,
html.neopage--tc-gray .ui-datepicker td .ui-state-hover {
  background: #3c3f4c;
  border-color: #3c3f4c;
}
html.neopage--tc-gray .fg-theme, html.neopage--tc-gray input[type=checkbox] + label:after,
html.neopage--tc-gray .heading1, html.neopage--tc-gray .heading2, html.neopage--tc-gray .heading3, html.neopage--tc-gray .heading4, html.neopage--tc-gray p a {
  color: #3c3f4c;
}
html.neopage--tc-gray svg.fg-theme path {
  fill: #3c3f4c;
}
html.neopage--tc-gray .bg-theme {
  background-color: #3c3f4c;
}
html.neopage--tc-green .ui-datepicker .ui-datepicker-header {
  background-color: #2ab479;
}
html.neopage--tc-green .ui-datepicker td .ui-state-active,
html.neopage--tc-green .ui-datepicker td .ui-state-hover {
  background: #2ab479;
  border-color: #2ab479;
}
html.neopage--tc-green .fg-theme, html.neopage--tc-green input[type=checkbox] + label:after,
html.neopage--tc-green .heading1, html.neopage--tc-green .heading2, html.neopage--tc-green .heading3, html.neopage--tc-green .heading4, html.neopage--tc-green p a {
  color: #2ab479;
}
html.neopage--tc-green svg.fg-theme path {
  fill: #2ab479;
}
html.neopage--tc-green .bg-theme {
  background-color: #2ab479;
}
html.neopage--tc-orange .ui-datepicker .ui-datepicker-header {
  background-color: #fd7723;
}
html.neopage--tc-orange .ui-datepicker td .ui-state-active,
html.neopage--tc-orange .ui-datepicker td .ui-state-hover {
  background: #fd7723;
  border-color: #fd7723;
}
html.neopage--tc-orange .fg-theme, html.neopage--tc-orange input[type=checkbox] + label:after,
html.neopage--tc-orange .heading1, html.neopage--tc-orange .heading2, html.neopage--tc-orange .heading3, html.neopage--tc-orange .heading4, html.neopage--tc-orange p a {
  color: #fd7723;
}
html.neopage--tc-orange svg.fg-theme path {
  fill: #fd7723;
}
html.neopage--tc-orange .bg-theme {
  background-color: #fd7723;
}
html.neopage--tc-purple .ui-datepicker .ui-datepicker-header {
  background-color: #824b96;
}
html.neopage--tc-purple .ui-datepicker td .ui-state-active,
html.neopage--tc-purple .ui-datepicker td .ui-state-hover {
  background: #824b96;
  border-color: #824b96;
}
html.neopage--tc-purple .fg-theme, html.neopage--tc-purple input[type=checkbox] + label:after,
html.neopage--tc-purple .heading1, html.neopage--tc-purple .heading2, html.neopage--tc-purple .heading3, html.neopage--tc-purple .heading4, html.neopage--tc-purple p a {
  color: #824b96;
}
html.neopage--tc-purple svg.fg-theme path {
  fill: #824b96;
}
html.neopage--tc-purple .bg-theme {
  background-color: #824b96;
}
html.neopage--tc-red .ui-datepicker .ui-datepicker-header {
  background-color: #f04664;
}
html.neopage--tc-red .ui-datepicker td .ui-state-active,
html.neopage--tc-red .ui-datepicker td .ui-state-hover {
  background: #f04664;
  border-color: #f04664;
}
html.neopage--tc-red .fg-theme, html.neopage--tc-red input[type=checkbox] + label:after,
html.neopage--tc-red .heading1, html.neopage--tc-red .heading2, html.neopage--tc-red .heading3, html.neopage--tc-red .heading4, html.neopage--tc-red p a {
  color: #f04664;
}
html.neopage--tc-red svg.fg-theme path {
  fill: #f04664;
}
html.neopage--tc-red .bg-theme {
  background-color: #f04664;
}
html.neopage--tc-yellow .ui-datepicker .ui-datepicker-header {
  background-color: #fac805;
}
html.neopage--tc-yellow .ui-datepicker td .ui-state-active,
html.neopage--tc-yellow .ui-datepicker td .ui-state-hover {
  background: #fac805;
  border-color: #fac805;
}
html.neopage--tc-yellow .fg-theme, html.neopage--tc-yellow input[type=checkbox] + label:after,
html.neopage--tc-yellow .heading1, html.neopage--tc-yellow .heading2, html.neopage--tc-yellow .heading3, html.neopage--tc-yellow .heading4, html.neopage--tc-yellow p a {
  color: #fac805;
}
html.neopage--tc-yellow svg.fg-theme path {
  fill: #fac805;
}
html.neopage--tc-yellow .bg-theme {
  background-color: #fac805;
}

.neo-moreresults {
  color: #fff;
  text-align: center;
  font: 500 22px/26px "Univers Next W02", Arial;
  padding: 10px;
}
.neo-moreresults > .more {
  cursor: pointer;
  display: block;
  transition: opacity 0.2s;
}
.neo-moreresults > .more:hover {
  opacity: 0.8;
}
.neo-moreresults > .busy {
  display: none;
  cursor: wait;
}
html.neo-loading .neo-moreresults > .busy {
  display: block;
}
html.neo-loading .neo-moreresults > .more {
  display: none;
}

.neo-events-list {
  padding: 0 15px;
}
.neo-events-list .button-addevent {
  color: #fff;
  display: block;
  text-decoration: none;
  padding: 16px 20px;
  text-align: center;
  text-transform: uppercase;
  font: 500 22px/26px "Univers Next W02", Arial;
  transition: opacity 0.2s;
}
.neo-events-list .button-addevent:hover {
  opacity: 0.8;
}
.neo-events-list .introblock {
  padding: 40px 64px;
  position: relative;
}
.neo-events-list .introblock .loading {
  position: absolute;
  top: 100%;
  left: 64px;
  margin-top: 10px;
  display: none;
}
html.neo-loading .neo-events-list .introblock .loading {
  display: block;
}
@media (max-width: 940px) {
  .neo-events-list .introblock {
    padding: 20px 20px 40px;
  }
  .neo-events-list .introblock .loading {
    left: 20px;
  }
}
.neo-events-list a {
  color: #4A4A4A;
}