/*

Green

Normal  / Hover   / Down    / CTAButton / CTAButton-hover
#2ab479 / #38be83 / #08a56d / #006843   / #008a5b

*/

  .sitetheme--green
, .theme--green
{
  --themecolor:       #2AB479;
  --themecolor-dark:  #006843; /* also CTAButton color */
  --themecolor-light: #38be83;  /* also hover */
  /* rgba(8, 165, 109, 0.4); */
}

.sitetheme--green
{
  --sitethemecolor:       #2AB479;
  --sitethemecolor-dark:  #006843; /* also CTAButton color */
  --sitethemecolor-light: #38be83;  /* also hover */
  /* rgba(8, 165, 109, 0.4); */
}


  .rtdcontent.tc-green > p a
, .rtdcontent.tc-green > ul a
, .rtdcontent.tc-green > ol a
, .rtdcontent.tc-green > h1
, .rtdcontent.tc-green > h2
, .rtdcontent.tc-green > h3
, .rtdcontent.tc-green > h4

, .tc-green .wh-form__richtext > p a
, .tc-green .wh-form__richtext > ul a
, .tc-green .wh-form__richtext > ol a
, .tc-green h1
, .tc-green h2
, .tc-green h3
, .tc-green h4

, .wh-rtd-editor.tc-green > p a
, .wh-rtd-editor.tc-green > ul a
, .wh-rtd-editor.tc-green > ol a
, .wh-rtd-editor.tc-green > h1
, .wh-rtd-editor.tc-green > h2
, .wh-rtd-editor.tc-green > h3
, .wh-rtd-editor.tc-green > h4

, .tc-green-fg-dark.tc-green-fg-dark
, .tc-green-fg-dark-ahover a:hover
, .themeclass.tc-green-apply a

, html.tc-green .wh-form__grouptitle
{
  color: #2ab479;
}



  .tc-green-bg-dark
, .wh-rtd-editor.tc-green-bg-dark
{
  background-color: #2ab479;
  color: #FFFFFF;
}

.tc-green-bc-dark
{
  border-color: #2ab479;
}

/* allow the dark anchor to work on itself and two levels deeper */
  .tc-green-ac-dark
, a.tc-green-ac-dark
, .tc-green-ac-dark > a
, .tc-green-ac-dark > * > a
{
  color: #2ab479;
}
  .tc-green-ac-dark:hover
, a.tc-green-ac-dark:hover
, .tc-green-ac-dark > a:hover
, .tc-green-ac-dark > * > a:hover
{
  color: #38be83;
}
  .tc-green-ac-dark:active
, a.tc-green-ac-dark:active
, .tc-green-ac-dark > a:active
, .tc-green-ac-dark > * > a:active
{
  color: #08a56d;
}

/* buttons (on white) */
  .tc-green-darkhover
, /*.document-toplevel*/.tc-green > .embeddedobject-inflow .tc--darkhover /* use 'pagetheme' on buttons at toplevel */
, .blockswidget-item-meta .blockswidget-item-link.tc-green-darkhover
{
  background-color: #2ab479;
  color: #FFFFFF;
  transition: background-color 0.15s;
}
  .tc-green-darkhover:hover
, .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-green-darkhover
{
  background-color: #38be83;
}
.tc-green-darkhover:active
{
  background-color: #08a56d;
}
.tc-green-darkhover:focus
{
  outline: 0;
  box-shadow: 1.5px 1.5px 3px rgba(0,0,0,0.4);
}

/* buttons widget (on a dark background) */
  .used_on_dark_background .buttonwidget .tc-green-darkhover
, .tc-green-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover
, .used_on_dark_background .blockswidget-metaunder .blockswidget-item-link.tc-green-darkhover
{
  background-color: #006843;
  color: #FFFFFF;
}
  .used_on_dark_background .buttonwidget .tc-green-darkhover:hover
, .tc-green-bg-dark .used_on_dark_background .buttonwidget .tc--darkhover:hover
, .used_on_dark_background .blockswidget-metaunder .blockswidget__activationlayer:hover + .blockswidget-item-meta .blockswidget-item-link.tc-green-darkhover
{
  background-color: #008a5b;
}




/*
  --themecolor:       #2AB479;
  --themecolor-dark:  #006843; / * also CTAButton color * /
  --themecolor-light: #38be83; / * also hover * /
*/

/* Solid button styles */
  .ctabutton--autostyle.tc-green                /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-green                    /* explicitely solid */
, html.tc-green .ctabutton--autostyle.tc-auto   /* widget inheriting the color from the page themecolor */
, html.tc-green .ctabutton--solid.tc-auto       /* widget inheriting the color from the page themecolor */
, html.tc-green .wh-form__button                /* Form webtool */
, .widget-blocks.theme--green .blockswidget__item__button
{
  background-color: #2AB479;
  /*background-color: var(--themecolor);*/
  color: #FFFFFF;
}

  .ctabutton--autostyle.tc-green:hover /* at top level (not in color background) default to solid */
, .ctabutton--solid.tc-green:hover /* at top level (not in color background) default to solid */
, html.tc-green .ctabutton--autostyle.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, html.tc-green .ctabutton--solid.tc-auto:hover /* widget inheriting the color from the page themecolor */

, html.tc-green .wh-form__button:hover          /* Form webtool */
, .widget-blocks.theme--green .blockswidget__item:hover .blockswidget__item__button
{
  background-color: #006843;
  /*background-color: var(--themecolor-dark);*/
}


/* Colored outline button styles */
  .ctabutton--outline.tc-green
, html.tc-green .ctabutton--outline.tc-auto  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-green /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-green /* without a contentblock with backgroundcolor and explicit color selection */

, .used_on_dark_background.tc-green-bg-dark .ctabutton--autostyle /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background.tc-green-bg-dark .ctabutton--outline /* without a contentblock with backgroundcolor and explicit color selection */

{
  background-color: #FFFFFF;
  color: #2AB479;
  /*color: var(--themecolor);*/

  border: 2px solid #2AB479;
  /*border: 2px solid var(--themecolor);*/
}

  .ctabutton--outline.tc-green:hover
, html.tc-green .ctabutton--outline.tc-auto:hover  /* widget inheriting the color from the page themecolor */
, .used_on_dark_background .ctabutton--autostyle.tc-green:hover /* without a contentblock with backgroundcolor and explicit color selection */
, .used_on_dark_background .ctabutton--outline.tc-green:hover /* without a contentblock with backgroundcolor and explicit color selection */
{
  /*background-color: var(--themecolor);*/
  background-color: #2AB479;
  color: #FFFFFF;
}
