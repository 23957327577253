/** The photoalbum widget consists of
    - a Justified Image Grid
    - a fullscreen slideshow

    FIXME: maybe not use fixed, but replace the contents... or place ourselves at the top with height: 100vh; and disable scrolling
*/

.photoalbumwidget__item
{
  cursor: pointer;
}

.photoalbumwidget__item-title
{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 10px;

  background-color: rgba(0, 0, 0, 0.75);
  color: #FFFFFF;

  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.25s, opacity 0.25s;

  cursor: pointer;
}

html.allowhover .photoalbumwidget__item:hover .photoalbumwidget__item-title
{
  opacity: 1;
  transform: translateY(0%);
}





.photoalbumwidget__slideshow
{
  display: none;

  -webkit-tap-highlight-color: transparent; /* iOS Safari */

/*  position: fixed;*/
  position: absolute;
  left: 0;
  right: 0;

  top: 0;
  height: 100vh;

  background-color: #1f2329; /* rgba(0, 0, 0, 0.75); */
  z-index: 1337;

  flex-direction: column;

  justify-content: center;

  padding-top: 60px;
/*
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
*/
}

.photoalbumwidget__slideshow.prepare
{
  display: block;
  visibility: hidden;
}

.photoalbumwidget__slideshow.visible
{
  visibility: visible;
}

.photoalbumwidget__slideshow .carrousel__viewport
{
}





.photoalbumwidget__slideshow .carrousel
{
}

.photoalbumwidget__slideshow .carrousel__cell
{
  background-color: #000000;
/*  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.85);*/
}

/*
.photoalbumwidget__slideshow .carrousel__cell
{
  opacity: 0;
  transition: opacity 0.25s;
}

.photoalbumwidget__slideshow .carrousel__cell.carrousel__cell--aligned
{
  opacity: 1;
}
*/





/* FullScreen Slideshow */
/*
.fsslideshow .carrousel__cell-image
{
  background-image: none; / * !! prevent browsers from directly loading * /
}
*/

/*
.fsslideshow .carrousel__viewport
{
  transform: scale(0);
  transition: transform 10.25s, opacity 10.25s;
}
.fsslideshow.visible .carrousel__viewport
{
  transform: scale(1);
}
*/


/* make the normal content of the page use no space anymore,
   so it appears all content is gone but without killing it's state
   (running plugins, iframe content, eventlisteners etc).
*/

/* IE10 & IE11 will cut off the fullscreen slideshow
   due to our overflow: hidden; on the body.
*/
  html.html--onlyfullscreenelement
, html.html--onlyfullscreenelement body
{
  height: 100vw;
}

  .html--onlyfullscreenelement
, .html--onlyfullscreenelement body
{
  overflow: hidden;

}
.html--onlyfullscreenelement .page
{
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden;
}
  .html--onlyfullscreenelement#site-headers
, .html--onlyfullscreenelement#slidemenu-container
, .html--onlyfullscreenelement#site-headers-placeholder
{
  display: none;
}





.fsslideshow__title
{
  position: absolute;
  bottom: 10px;
  width: 100%;

  color: #FFFFFF;

  font-size: 18px;
  font-weight: normal;

  text-align: center;

  letter-spacing: 0.2px;

  color: #aaacae;
}

  .fsslideshow__topbuttons
, .fsslideshow__navbuttons
{
  display: flex;
}

.fsslideshow__topbuttons
{
  position: absolute;
  top: 0;
  right: 0;
}

.fsslideshow__navbuttons
{
  position: absolute;
  right: 0;
  bottom: 0;
}

.fsslideshow__button
{
  width: 60px;
  height: 60px;

  text-align: center;
  line-height: 60px;

  cursor: pointer;
}

  .fsslideshow__button
, .fsslideshow__title
{
  -webkit-transform: translate3D(0,0,0);
  -webkit-backface-visibility: hidden;
}

  .fsslideshow__button > svg
, .fsslideshow__button > svg
{
  fill: #888888;

  transition: fill 0.25s;

  vertical-align: middle;

  width: 18px;
  height: 18px;

  pointer-events: none; /* prevent the parts where the SVG is drawn from eating events in IE10/11 */
}

  html.allowhover .fsslideshow .fsslideshow__button:hover > svg
, .fsslideshow .fsslideshow__button:active > svg
{
  fill: #FFFFFF;
}

.fsslideshow__download > svg
{
  width: 28px;
  height: 28px;
}
.fsslideshow__close > svg
{
  width: 16px;
  height: 16px;
}
